import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureLocalizationModule = (services: IServices, i18n: any) => {
  const actions = initActions(services.localizationService, i18n, services.storageService);
  const reducer = initReducer(services.userService, services.storageService);

  return { actions, reducer };
};

export type LocalizationReducer = ReturnType<typeof initReducer>
export type LocalizationActions = ReturnType<typeof initActions>

export default configureLocalizationModule;