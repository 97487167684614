import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const MarketingPopupService = (ajax: ajaxType) => {return{
    fetchMarketingPopup: (locale: string) => {
        return ajax.get(`${config.url.API_URL}Dashboard/MarketingPopup/${locale.toLowerCase()}`).pipe(map(res => res.response))
    },
    marketingPopupRead: (locale: string) => {
        return ajax.post(`${config.url.API_URL}Dashboard/MarketingPopup/Read/${locale.toLowerCase()}`).pipe(map(res => res.response))
    },
    marketingPopupReadLater: (locale: string) => {
        return ajax.post(`${config.url.API_URL}Dashboard/MarketingPopup/ReadLater/${locale.toLowerCase()}`).pipe(map(res => res.response))
    }
}}

export type IMarketingPopupService = ReturnType<typeof MarketingPopupService>

export default MarketingPopupService