import { IAction, ActionType } from "../types";
import { GA4 } from "react-ga4/types/ga4";
declare const window: any;
const initReducer = function (ga: GA4) {
    const INITIAL_STATE = {};

    let reducersByActions: any = {}

    let pageviewTimeout: ReturnType<typeof setTimeout>

    reducersByActions[ActionType.GAPageview] = (state: any, actionData: any) => {
        const { history, pathname, search } = actionData
        if (history.action === 'REPLACE' && !!pageviewTimeout) {
            clearTimeout(pageviewTimeout)
        }
        pageviewTimeout = setTimeout(() => {
            ga.send({ hitType: "pageview", page: pathname + search })
        }, 2000)
        if (window?.dataLayer) {
            window.dataLayer.push({
                event: "page_load_time",
            });
        }
        return { ...state }
    }

    reducersByActions[ActionType.AddCartResponse] = (state: any) => {
        ga.event({
            category: 'CartAction',
            action: 'add_to_cart'
        })
        return { ...state }
    }

    reducersByActions[ActionType.ApplyTopUpRequest] = (state: any) => {
        ga.event({
            category: 'TopupAction',
            action: 'apply_topup'
        })
        return { ...state }
    }

    reducersByActions[ActionType.ApplyTopUpResponse] = (state: any, actionData: any) => {
        if (!actionData.ErrorDescription) {
            ga.event({
                category: 'TopupAction',
                action: 'topup_success'
            })
        }
        return { ...state }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };


    return reducer;
};

export default initReducer;