import { IAction, ActionType } from "../types";

const initReducer = function () {
    const INITIAL_STATE = {
      history: (undefined as any),
      fetchInProgress: false,
      printDataFetchInProgress: false,
      lastPrintData: (undefined as any),
      isPrinting: (undefined as any),
      epinsInRecord: ([] as any[]),
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.PrintHistoryRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
        history: undefined
      };
    }
    reducersByActions[ActionType.PrintHistoryResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        history: actionData.data,
      };
    }
    reducersByActions[ActionType.PrintHistoryPrintOutRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        printDataFetchInProgress: true,
        lastPrintData: undefined
      };
    }
    reducersByActions[ActionType.PrintHistoryPrintOutResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        printDataFetchInProgress: false,
        lastPrintData: actionData.data,
        isPrinting: undefined
      };
    }
    reducersByActions[ActionType.CleanPrintHistory] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        history: undefined,
        lastPrintData: undefined
      };
    }
    reducersByActions[ActionType.CleanPrintData] = (state: any, actionData: any) => {
      return {
        ...state,
        lastPrintData: undefined
      };
    }
    reducersByActions[ActionType.PrintHistoryReprintEpinRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        isPrinting: true
      };
    }
    reducersByActions[ActionType.PrintHistoryReprintEpinRequestСompletion] =  (state: any, actionData: any) => {
      return {
        ...state,
        isPrinting: undefined
      };
    }
    reducersByActions[ActionType.AddRecordEpinsPrintHistory] = (state: any, actionData: any) => {
      return {
        ...state,
        epinsInRecord: actionData
      }
    }
    reducersByActions[ActionType.RemoveRecordEpinsPrintHistory] = (state: any, actionData: any) => {
      return {
        ...state,
        epinsInRecord: []
      }
    }
    reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
      return INITIAL_STATE
    }
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;