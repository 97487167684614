import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from "../interceptor"

const DocumentRepositoryService = (ajax: ajaxType) => {
    return {
        fetchDocuments: (doctype?: string) => {
            return ajax.get(`${config.url.API_URL}documentrepository/get?doctype=${doctype || ''}`).pipe(map(res => res.response))
        },
        fetchDocumentTypes: () => {
            return ajax.get(`${config.url.API_URL}documentrepository/documenttype`).pipe(map(res => res.response))
        },
        downloadDocument: (fileName: string) => {
            return ajax.get(
                `${config.url.API_URL}documentrepository/download/${fileName}`,
                { 'Content-Type': 'application/pdf', 'Accept': '*/*' },
                'blob'
            ).pipe(map(res => res.response))
        }
    }
}

export type IDocumentRepositoryService = ReturnType<typeof DocumentRepositoryService>

export default DocumentRepositoryService