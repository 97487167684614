import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureForgottenPasswordModule = (services: IServices) => {
  const actions = initActions(services.forgottenPasswordService);
  const reducer = initReducer(services.userService, services.storageService);

  return { actions, reducer };
};

export type ForgottenPasswordReducer = ReturnType<typeof initReducer>
export type ForgottenPasswordActions = ReturnType<typeof initActions>

export default configureForgottenPasswordModule;