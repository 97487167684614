import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureTopUpHistoryModule = (services: IServices) => {
  const actions = initActions(services.topUpHistoryService);
  const reducer = initReducer(services.topUpHistoryService);

  return { actions, reducer };
};

export type TopUpHistoryReducer = ReturnType<typeof initReducer>
export type TopUpHistoryActions = ReturnType<typeof initActions>

export default configureTopUpHistoryModule;