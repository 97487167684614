import { IStorageService } from "@src/services/StorageService";
import { IUserService } from "@src/services/UserService";
import { IAction, ActionType } from "../types";

const initReducer = function (userService:IUserService, storageService: IStorageService) {

  const INITIAL_STATE = {
    dataRequestInProgress: false,
    data: (undefined as any),
    serverError: false,
    newPasswordError: (undefined as any),
    repeatNewPasswordError: (undefined as any),
    passwordsShouldBeEqualError: (undefined as any),
    passwordFormatError: (undefined as any),
    requestError: (undefined as any),
    dataRequestError: (undefined as any),
    updateRequestInProgress: false,
    updateResult: (undefined as any)
  };

  let reducersByActions: any = {}
  
  reducersByActions[ActionType.ForgottenPasswordDataRequest] =  (state: any, actionData: any) => {
    return {
      ...state,
      dataRequestInProgress: true
    };
  }
  reducersByActions[ActionType.ForgottenPasswordDataResponse] =  (state: any, actionData: any) => {
    return {
      ...state,
      dataRequestInProgress: false,
      data: actionData
    };
  }
  reducersByActions[ActionType.UpdatePasswordRequest] =  (state: any, actionData: any) => {
    return {
      ...state,
      updateRequestInProgress: true
    };
  }
  reducersByActions[ActionType.UpdatePasswordResponse] =  (state: any, actionData: any) => {
    return {
      ...state,
      updateRequestInProgress: false,
      updateResult: actionData
    };
  }
  reducersByActions[ActionType.SetNewPasswordError] =  (state: any, actionData: any) => {
    return {
      ...state,
      newPasswordError: true
    };
  }
  reducersByActions[ActionType.SetRepeatNewPasswordError] =  (state: any, actionData: any) => {
    return {
      ...state,
      repeatNewPasswordError: true
    };
  }
  reducersByActions[ActionType.SetPasswordFormatError] =  (state: any, actionData: any) => {
    return {
      ...state,
      newPasswordError: false,
      repeatNewPasswordError: false,
      passwordsShouldBeEqualError: false,
      passwordFormatError: true
    };
  }
  reducersByActions[ActionType.SetPasswordsShouldBeEqualError] =  (state: any, actionData: any) => {
    return {
      ...state,
      newPasswordError: false,
      repeatNewPasswordError: false,
      passwordsShouldBeEqualError: true
    };
  }
  reducersByActions[ActionType.SetNewPasswordRequestError] =  (state: any, actionData: any) => {
    return {
      ...state,
      updateRequestInProgress: false,
      requestError: actionData
    };
  }
  reducersByActions[ActionType.SetForgottenPasswordDataRequestError] =  (state: any, actionData: any) => {
    return {
      ...state,
      dataRequestInProgress: false,
      dataRequestError: actionData
    };
  }
  reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
    return INITIAL_STATE
  }
  reducersByActions[ActionType.ForgottenCleanError] = (state: any, actionData: any) => {
    return {
      ...state,
      serverError: false,
      newPasswordError: undefined,
      repeatNewPasswordError: undefined,
      passwordsShouldBeEqualError: undefined,
      passwordFormatError: false,
      requestError: undefined,
      dataRequestError: undefined
    }
  }
  const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
    // console.log("state: ", state)
    // console.log("action: ", action)
    return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
  };

  
  return reducer;
};

export default initReducer;