import { interceptorConnectorType } from "@src/services/interceptor";
import { i18n } from "i18next";
import { Actions } from ".";
import { AppDispatch, StoreType } from "./configureStore";
import { History } from 'history';
import { MyOrdersState } from "./myOrders";
import { SimProductType } from "./postPay";

const lngListCompare = (arr1: string[], arr2: string[]): boolean => {

  if (arr1.length !== arr2.length) {
    return false
  }
  const _arr1 = arr1.concat().sort();
  const _arr2 = arr2.concat().sort();

  for (let i = 0; i < _arr1.length; i++) {
    if (_arr1[i] !== _arr2[i]) {
      return false;
    }
  }

  return true;
}
const fetchEmployeesFilterParam = 'active';
const automation = (rawActions: Actions, store: StoreType, i18n: i18n, history: History, interceptorConnector: interceptorConnectorType) => {
  const actionsFactory = (dispatch: AppDispatch) => {
    return {
      buy: {
        getCart: rawActions.buy.getCart(dispatch, store.getState, i18n),
        getOrderComplete: rawActions.buy.getOrderComplete(dispatch, store.getState, i18n),
        // reviewCart: rawActions.buy.reviewCart(dispatch)
      },
      items: {
        fetchItemInfo: rawActions.items.fetchItemInfo(dispatch, store.getState, i18n)
      },
      wallet: {
        fetchWalletInfo: rawActions.wallet.fetchWalletInfo(dispatch),
        fetchCreditTiles: rawActions.wallet.fetchCreditTiles(dispatch),
        getCreditComplete: rawActions.wallet.getCreditComplete(dispatch)
      },
      topUp: {
        updateMsisdnInfo: rawActions.topUp.updateMsisdnInfo(dispatch, store.getState, i18n),
      },
      buyAndPrint: {
        setQuantity: rawActions.buyAndPrint.setQuantity(dispatch),
        restoreBuyTransaction: rawActions.buyAndPrint.restoreBuyTransaction(dispatch, store.getState, i18n),
        restoreBuyVoucherTransaction: rawActions.buyAndPrint.restoreBuyVoucherTransaction(dispatch, store.getState, i18n),
        cleanQuantity: rawActions.buyAndPrint.cleanQuantity(dispatch),
        finishBuyAndPrintTransaction: rawActions.buyAndPrint.finishBuyAndPrintTransaction(dispatch),
        finishBuyAndPrintVoucherTransaction: rawActions.buyAndPrint.finishBuyAndPrintVoucherTransaction(dispatch)
      },
      printHistory: {
        fetchHistoryBy7Days: rawActions.printHistory.fetchHistoryBy7Days(dispatch),
        cleanPrintHistory: rawActions.printHistory.cleanPrintHistory(dispatch)
      },
      topUpHistory: {
        fetchHistory: rawActions.topUpHistory.fetchHistory(dispatch),
        cleanTopUpHistory: rawActions.topUpHistory.cleanTopUpHistory(dispatch, store.getState, i18n)
      },
      assignBundleHistory: {
        fetchHistory: rawActions.assignBundleHistory.fetchHistory(dispatch),
        cleanHistory: rawActions.assignBundleHistory.cleanHistory(dispatch, store.getState, i18n)
      },
      user: {
        getCountries: rawActions.user.getCountries(dispatch),
        countrySelect: rawActions.user.countrySelect(dispatch),
        getUserProfile: rawActions.user.getUserProfile(dispatch),
        logout: rawActions.user.logout(dispatch),
        getPaymentLogo: rawActions.user.paymentLogo(dispatch),
        cleanUserProfie: rawActions.user.cleanUserProfie(dispatch),
      },
      localization: {
        loadLanguage: rawActions.localization.loadLanguage(dispatch, store.getState, i18n),
        setLanguageList: rawActions.localization.setLanguageList(dispatch),
        cleanLanguageList: rawActions.localization.cleanLanguageList(dispatch),
        notifyLngChange: rawActions.localization.notifyLngChange(),
        setLanguage: rawActions.localization.setLanguage(dispatch)
      },
      alerts: {
        fetchAlerts: rawActions.alerts.fetchAlerts(dispatch)
      },
      dashboardTiles: {
        fetchTilesInfo: rawActions.dashboardTiles.fetchTilesInfo(dispatch)
      },
      myOrders: {
        fetchMyOrders: rawActions.myOrders.fetchMyOrders(dispatch, store.getState),
        getProducts: rawActions.myOrders.getProducts(dispatch),
        cleanMyOrdersData: rawActions.myOrders.cleanMyOrdersData(dispatch),
        clearOrderSummary: rawActions.myOrders.clearOrderSummary(dispatch),
        fetchMBEOrders: rawActions.myOrders.fetchMBEOrders(dispatch, store.getState)
      },
      analytics: {
        pageview: rawActions.analytics.pageview(dispatch)
      },
      walletHistory: {
        getWalletSummary: rawActions.walletHistory.getWalletSummary(dispatch, store.getState),
        getWalletHistory: rawActions.walletHistory.getWalletHistory(dispatch, store.getState),
        cleanWalletHistory: rawActions.walletHistory.cleanWalletHistory(dispatch)
      },
      superInvoice: {
        fetchSuperInvoice: rawActions.superInvoice.fetchSuperInvoice(dispatch, store.getState),
        cleanSuperInvoice: rawActions.superInvoice.cleanSuperInvoice(dispatch)
      },
      invoiceHistory: {
        fetchInvoiceHistory: rawActions.invoiceHistory.fetchInvoiceHistory(dispatch, store.getState),
        cleanInvoiceHistory: rawActions.invoiceHistory.cleanInvoiceHistory(dispatch)
      },
      tradeOffers: {
        getTradeOffers: rawActions.tradeOffers.getTradeOffers(dispatch),
        cleanTradeOffers: rawActions.tradeOffers.cleanTradeOffers(dispatch)
      },
      downloadCentre: {
        getDownloadHistory: rawActions.downloadCentre.getDownloadHistory(dispatch),
        cleanDownloadCentre: rawActions.downloadCentre.cleanDownloadCentre(dispatch),
      },
      postPay: {
        getPostPayProducts: rawActions.postPay.getProducts(dispatch, store.getState, i18n),
        getPostPayBoltons: rawActions.postPay.getBoltons(dispatch),
        getPostPayDocuments: rawActions.postPay.getDocuments(dispatch),
        cleanPostPay: rawActions.postPay.cleanPostPay(dispatch),
        updateProductType: rawActions.postPay.updateProductType(dispatch),
        restorePortInRequest: rawActions.postPay.restorePortInRequest(dispatch),
        fetchPostPayConfig: rawActions.postPay.fetchPostPayConfig(dispatch)
      },
      kpiReport: {
        mtdData: rawActions.kpiReport.getMtdData(dispatch),
      },
      contractStatus: {
        getContractStatusList: rawActions.contractStatus.getContractStatusList(dispatch, store.getState),
        cleanContractStatusData: rawActions.contractStatus.cleanContractStatusData(dispatch)
      },
      portInResubmit: {
        getServiceId: rawActions.portInResubmit.getServiceId(dispatch, i18n)
      },
      faq: {
        fetchFaq: rawActions.faq.faqRecordFetch(dispatch)
      },
      prepaidPortal: {
        getServiceId: rawActions.prepaidPortal.getServiceId(dispatch)
      },
      employees: {
        fetchEmployees: rawActions.employees.getEmployees(dispatch)
      },
    }
  }

  type ActionsType = ReturnType<typeof actionsFactory>

  let currentUserToken: string | undefined = undefined
  let currentLngList: string[] = []
  let currentCountryConfiguration: any = {}
  let currentRoute: string = ''
  let currentTopUpResult: any = {}
  let currentAssignBundleResult: any = {}
  let currentBuyAndPrintResult: any = undefined
  let currentBuyAndPrintVoucherResult: any = undefined
  let currentinStockBuyPrintRequestResult: any = undefined
  let currentinStockBuyPrintVoucherRequestResult: any = undefined
  let currentApplyResult: any = {}
  let currentAddCartProgress: boolean = false
  let currentProceedResult: any = {}
  let currentCompleteOfflineResult: any = {}
  let currentCreditDetails: any = {}
  let currentTermsAndConditions: any = {}
  let currentMarketingTopup: any = {}

  let actions: ActionsType = actionsFactory(store.dispatch)
  i18n.on('languageChanged', (lng: string) => {
    actions.localization.notifyLngChange(lng)
    actions.localization.loadLanguage(i18n.language)
    actions.localization.setLanguage(lng)
    if (store.getState().user.token && lng) {
      actions.wallet.fetchWalletInfo()
      actions.items.fetchItemInfo(true)
      actions.alerts.fetchAlerts()
      actions.dashboardTiles.fetchTilesInfo()
      if(window.location.pathname.includes('faq')){
        // setting a delay so that language change reflects on backend
        setTimeout(() => {
          actions.faq.fetchFaq()
        },800)
      }
    }
  })

  interceptorConnector.setOnErrorListener(401, () => {
    if (store.getState().user.token) {
      actions.user.logout()
    }
  })

  function onUserTokenChange(currentUserToken: string) {
    interceptorConnector.setToken(currentUserToken)
    if (currentUserToken) {
      window.localStorage.setItem('userToken', currentUserToken);
      window.localStorage.setItem('userData', JSON.stringify(store.getState().user.userData));
      window.localStorage.setItem('countryConfiguration', JSON.stringify(store.getState().user.countryConfiguration));
      window.localStorage.setItem('selectedCountry', store.getState().user.selectedCountry as string)
      actions.buy.getCart()
      actions.alerts.fetchAlerts()
      actions.dashboardTiles.fetchTilesInfo()
      if (!store.getState().user.paymentLogos && store.getState().user.userData.Level !== 1 && store.getState().user.userData.Level !== 10)
        actions.user.getPaymentLogo() 
      actions.wallet.fetchWalletInfo()
    } else {
      var botLng = localStorage.getItem("botLang") ?? undefined;
      window.localStorage.clear()
      if (botLng && currentUserToken)
        window.localStorage.setItem('botLang', botLng);
      actions.user.getCountries()
    }
  }

  function onTermsAndConditionsChanged(currentTermsAndConditions: any) {
    if (currentTermsAndConditions) {
      window.localStorage.setItem('termsAndConditions', JSON.stringify(currentTermsAndConditions));
    }
  }

  function onMarketingTopupChanged(currentMarketingTopup: any) {
    if (currentMarketingTopup) {
      window.localStorage.setItem('marketingPopup', JSON.stringify(currentMarketingTopup));
    }
  }

  function onLngListChange(currentLngList: any) {
    const plainLngList = Object.keys(currentLngList)
    if (plainLngList.indexOf(i18n.language) === -1) {
      const searchResult = Object.entries<any>(currentLngList).find(([_, value]) => value.isDefault)
      setTimeout(() => i18n.changeLanguage(searchResult ? searchResult[0] : plainLngList[0]), 1)
      actions.localization.loadLanguage(searchResult ? searchResult[0] : plainLngList[0])
    }
    else {
      actions.localization.loadLanguage(i18n.language)
    }
  }

  function onCountryConfigurationChange(countryConfiguration: any) {
    if (countryConfiguration) {
      let res: any = {}
      countryConfiguration.AllowedLanguages.forEach((item: any) => {
        let locale = item.Locale
        locale = locale.length === 5 ? locale.substring(0, 3).concat(locale.substring(3).toUpperCase()) : locale
        res[locale] = { name: item.Name, abbreviation: item.Abbreviation, isDefault: item.IsDefault }
      });
      actions.localization.setLanguageList(res)
    } else {
      actions.localization.cleanLanguageList()
      actions.postPay.updateProductType(undefined)
    }
  }

  function onTopUpResultChange(topUpResult: any, topUpType: string) {
    if (topUpResult) {
      actions.wallet.fetchWalletInfo()
      topUpType === 'MSISDN' && actions.topUp.updateMsisdnInfo()
      actions.dashboardTiles.fetchTilesInfo()
    }
  }

  function onBuyAndPrintResultChange(buyAndPrintResult: any) {
    if (buyAndPrintResult) {
      actions.wallet.fetchWalletInfo()
      actions.dashboardTiles.fetchTilesInfo()
    }
  }

  function onAssignBundleResultChange(assignBundleResult: any) {
    if (assignBundleResult) {
      actions.wallet.fetchWalletInfo()
      actions.topUp.updateMsisdnInfo()
      actions.items.fetchItemInfo(true)
      actions.dashboardTiles.fetchTilesInfo()
    }
  }


  function onInStockBuyAndPrintResultChange(buyAndPrintResult: any) {
    if (buyAndPrintResult && buyAndPrintResult.RePrintEpins && buyAndPrintResult.RePrintEpins.length && !buyAndPrintResult.RePrintEpins[0].PrintedDate) {
      actions.items.fetchItemInfo(true)
    }
  }

  function onAddToCartChange(addInProgress: boolean, itemId: string) {
    if (!addInProgress) {
      actions.buyAndPrint.setQuantity(itemId, 0)
    }
  }

  function onVoucherApplyResultChange(applyResult: any) {
    if (applyResult) {
      actions.items.fetchItemInfo(true)
    }
  }

  function onPathChange(newPath: string, previousPath: string, pathQuery: any, topUpHistory: any, assignBundleHistory: any, printHistory: any, buy: any, myOrders: MyOrdersState, user: any) {
    let pathListeners: any = {}

    // changing path to lowercase to handle capatialize url from old UI
    newPath = newPath.toLocaleLowerCase();

    previousPath = previousPath.toLocaleLowerCase();
    pathListeners["/transactionhistory"] = () => {
      if (!topUpHistory.history) {
        actions.topUpHistory.fetchHistory(topUpHistory.filters)
      }
      if (!assignBundleHistory.history) {
        actions.assignBundleHistory.fetchHistory(topUpHistory.filters)
      }
    }
    pathListeners["/printepinhistory"] = () => {
      if (!printHistory.history) {
        actions.printHistory.fetchHistoryBy7Days()
      }
    }
    pathListeners["/dashboard"] = () => {
      actions.buyAndPrint.cleanQuantity()
      actions.buyAndPrint.finishBuyAndPrintTransaction()
      actions.buyAndPrint.finishBuyAndPrintVoucherTransaction()
      if (store.getState().user.token) {
        actions.items.fetchItemInfo()
      }
    }

    pathListeners["/advancedpayment"] = () => {
      actions.wallet.fetchCreditTiles()
    }

    pathListeners["/order/myorders"] = () => {
      !myOrders.products.length && actions.myOrders.getProducts()
      !myOrders.myOrders && actions.myOrders.fetchMyOrders()
    }

    pathListeners["/order/mbeorders"] = () => {
      !myOrders.myOrders && actions.myOrders.fetchMBEOrders()
    }

    pathListeners["/cart"] = () => {
      actions.wallet.fetchWalletInfo()
      // actions.buy.reviewCart()
    }

    pathListeners["/wallet"] = () => {
      actions.walletHistory.getWalletSummary()
      actions.walletHistory.getWalletHistory()
    }
    pathListeners["/userprofile"] = () => {
      actions.user.getUserProfile()
    }
    pathListeners["/superinvoice"] = () => {
      actions.superInvoice.fetchSuperInvoice()
    }

    pathListeners["/offers"] = () => {
      actions.tradeOffers.getTradeOffers()
    }

    // while routing from old UI to new UI /Offers is handled with this configuration
    pathListeners["/Offers"] = () => {
      actions.tradeOffers.getTradeOffers()
    }

    pathListeners["/order/invoicehistory"] = () => {
      actions.invoiceHistory.fetchInvoiceHistory()
    }

    pathListeners["/downloadcentre"] = () => {
      actions.downloadCentre.getDownloadHistory()
    }

    pathListeners["/postpayportin"] = () => {
      if (user.countryConfiguration?.LMVNOEnabled) {
        actions.postPay.updateProductType(SimProductType.lmvno)
      } else if (user.userData?.permissions.includes('fmvno')) {
        actions.postPay.updateProductType(SimProductType.fmvno)
      }
      actions.postPay.getPostPayProducts()
      actions.postPay.getPostPayBoltons()
      actions.postPay.getPostPayDocuments()
      actions.postPay.fetchPostPayConfig()
      if (!!pathQuery && !!pathQuery.event) {
        actions.postPay.restorePortInRequest()
      }
    }

    pathListeners["/postpayportin/contractstatus"] = () => {
      actions.contractStatus.getContractStatusList()
    }
    pathListeners["/performancereports"] = () => {
      actions.kpiReport.mtdData()
    }

    pathListeners["/postpayportin/resubmission"] = () => {
      actions.portInResubmit.getServiceId()
    }

    pathListeners["/faq"] = () => {
      actions.faq.fetchFaq()
    }
    pathListeners["/prepaidportal"] = () => {
      actions.prepaidPortal.getServiceId()
    }
    pathListeners['/employee/viewprofiles'] = () => {
      actions.employees.fetchEmployees(fetchEmployeesFilterParam)
    }
    // const matchCreditPayment = /\/creditcomplete\/([A-Za-z0-9]{0,})\/online\/([a-z]{0,})/.exec(currentRoute)
    // if (matchCreditPayment && matchCreditPayment[1] && matchCreditPayment[2] === 'success') {
    //   actions.wallet.getCreditComplete(matchCreditPayment[1])
    // }


    // const matchOrder = /\/ordercomplete\/([A-Za-z0-9]{0,})\/([A-Za-z]{0,})/.exec(currentRoute)
    // if (matchOrder&&matchOrder[1]) {
    //   actions.buy.getOrderComplete(matchOrder[1], matchOrder[2])
    //   actions.wallet.fetchWalletInfo()
    //   actions.dashboardTiles.fetchTilesInfo()
    //   actions.items.fetchItemInfo(true)
    // }

    if (typeof pathListeners[newPath] === "function") {
      pathListeners[newPath]()
    }

    let previousPathListeners: any = {}

    previousPathListeners["/transactionhistory"] = () => {
      actions.topUpHistory.cleanTopUpHistory()
      actions.assignBundleHistory.cleanHistory()
    }

    previousPathListeners["/printepinhistory"] = () => {
      actions.printHistory.cleanPrintHistory()
    }

    previousPathListeners["/paymentmethod"] = () => {
      actions.buy.getCart()
    }

    previousPathListeners["/order/myorders"] = () => {
      actions.myOrders.cleanMyOrdersData()
    }

    previousPathListeners["/wallet"] = () => {
      actions.walletHistory.cleanWalletHistory()
    }

    previousPathListeners["/userprofile"] = () => {
      actions.user.cleanUserProfie()
    }

    previousPathListeners["/superinvoice"] = () => {
      actions.superInvoice.cleanSuperInvoice()
    }

    previousPathListeners["/order/invoiceHistory"] = () => {
      actions.invoiceHistory.cleanInvoiceHistory()
    }

    previousPathListeners["/offers"] = () => {
      actions.tradeOffers.cleanTradeOffers()
    }

    previousPathListeners["/downloadcentre"] = () => {
      actions.downloadCentre.cleanDownloadCentre()
    }

    previousPathListeners["/postpayportin"] = () => {
      actions.postPay.cleanPostPay()
    }

    previousPathListeners["/postpayportin/contractstatus"] = () => {
      actions.contractStatus.cleanContractStatusData()
    }

    if (/\/order\/ordersummary\/*/.test(previousPath)) {
      previousPathListeners[previousPath] = () => {
        actions.myOrders.clearOrderSummary()
      }
    }

    if (typeof previousPathListeners[previousPath] === "function") {
      previousPathListeners[previousPath]()
    }

    const match = /\/login(\/([A-Za-z]{2}))?/.exec(currentRoute)

    if (match && match[2]) {
      actions.user.countrySelect(match[2])
    } else if (match && !match[2] && !!user.countryConfiguration) {      
      actions.user.logout()
    }

    const paymentGateMatch = /\/printcomplete\/([^/]*)\/online\/success$/.exec(currentRoute)

    if (paymentGateMatch && paymentGateMatch[1]) {
      const restoreData = window.localStorage.getItem('buyAndPrintTransaction')
      const restoreVoucherData = window.localStorage.getItem('buyAndPrintVoucherTransaction')

      if (restoreData) {
        window.localStorage.removeItem('buyAndPrintTransaction')
        actions.buyAndPrint.restoreBuyTransaction(restoreData ? JSON.parse(restoreData) : undefined)
      }

      if (restoreVoucherData) {
        window.localStorage.removeItem('buyAndPrintVoucherTransaction')
        actions.buyAndPrint.restoreBuyVoucherTransaction(restoreVoucherData ? JSON.parse(restoreVoucherData) : undefined)
      }
    }

    actions.analytics.pageview(window.location.pathname, window.location.search)
  }

  function onProceedSuccessful(currentProceedPaymentResult: any, paymentMethod: string) {
    if (currentProceedResult) {
      if (paymentMethod === 'commission' || paymentMethod === 'zero' || paymentMethod === 'MBE') {
        history.push(`/ordercomplete/${currentProceedPaymentResult.OrderNumber}/${paymentMethod}${paymentMethod === 'MBE' ? '' : '/success'}`);
      } else {
        history.push(`/paymentmethod`);
      }
      actions.buy.getCart();
    }
  }

  function onOrderComplete(currentProceedPaymentResult: any, paymentMethod: string) {
    if (currentProceedResult) {
      history.push(`/ordercomplete/${currentProceedPaymentResult.OrderNumber}/${paymentMethod}${paymentMethod === 'MBE' ? '' : '/success'}`);
      actions.buy.getCart();
    }
  }

  function onCreditDetailsChnage(creditDetails: any) {
    if (creditDetails && creditDetails.Status) {
      history.push(`/walletpayment`);
    }
  }

  function handleChange() {
    actions = actionsFactory(store.dispatch)
    let previousToken = currentUserToken
    currentUserToken = store.getState().user.token
    if (previousToken !== currentUserToken) {
      onUserTokenChange(currentUserToken)
    }
    if (i18n.language === 'en-US') {
      i18n.changeLanguage('en-GB')
    }
    let previousLngList = currentLngList
    currentLngList = store.getState().localization.lngList
    if (!lngListCompare(Object.keys(currentLngList), Object.keys(previousLngList))) {
      onLngListChange(currentLngList)
    }

    let previousСountryConfiguration = currentCountryConfiguration
    currentCountryConfiguration = store.getState().user.countryConfiguration
    if (previousСountryConfiguration !== currentCountryConfiguration) {
      onCountryConfigurationChange(currentCountryConfiguration)
    }

    let previousTopUpResult = currentTopUpResult
    currentTopUpResult = store.getState().topUp.topUpResult
    if (previousTopUpResult !== currentTopUpResult) {
      onTopUpResultChange(currentTopUpResult, store.getState().topUp.topUpType)
    }

    let previousBuyAndPrintResult = currentBuyAndPrintResult
    currentBuyAndPrintResult = store.getState().buyAndPrint.buyPrintRequestResult
    if (previousBuyAndPrintResult !== currentBuyAndPrintResult) {
      onBuyAndPrintResultChange(currentBuyAndPrintResult)
    }

    let previousBuyAndPrintVoucherResult = currentBuyAndPrintVoucherResult
    currentBuyAndPrintVoucherResult = store.getState().buyAndPrint.buyPrintVoucherRequestResult
    if (previousBuyAndPrintVoucherResult !== currentBuyAndPrintVoucherResult) {
      onBuyAndPrintResultChange(currentBuyAndPrintVoucherResult)
    }

    let previousAssignBundleResult = currentAssignBundleResult
    currentAssignBundleResult = store.getState().topUp.assignBundleResult
    if (previousAssignBundleResult !== currentAssignBundleResult) {
      onAssignBundleResultChange(currentAssignBundleResult)
    }

    let previousinStockBuyPrintRequestResult = currentinStockBuyPrintRequestResult
    currentinStockBuyPrintRequestResult = store.getState().buyAndPrint.inStockBuyPrintRequestResult
    if (previousinStockBuyPrintRequestResult !== currentinStockBuyPrintRequestResult) {
      onInStockBuyAndPrintResultChange(currentinStockBuyPrintRequestResult)
    }

    let previousinStockBuyPrintVoucherRequestResult = currentinStockBuyPrintVoucherRequestResult
    currentinStockBuyPrintVoucherRequestResult = store.getState().buyAndPrint.inStockBuyPrintVoucherRequestResult
    if (previousinStockBuyPrintVoucherRequestResult !== currentinStockBuyPrintVoucherRequestResult) {
      onInStockBuyAndPrintResultChange(currentinStockBuyPrintVoucherRequestResult)
    }

    let previousApplyResult = currentApplyResult
    currentApplyResult = store.getState().topUp.applyVoucherResult
    if (previousApplyResult !== currentApplyResult) {
      onVoucherApplyResultChange(currentApplyResult)
    }

    let previousAddCartProgress = currentAddCartProgress
    currentAddCartProgress = store.getState().buy.addCartInProgress
    if (previousAddCartProgress !== currentAddCartProgress) {
      onAddToCartChange(currentAddCartProgress, store.getState().buy.addingItemId)
    }

    let previousRoute = currentRoute
    currentRoute = store.getState().router.location.pathname
    if (previousRoute !== currentRoute) {
      const { topUpHistory, assignBundleHistory, printHistory, buy, myOrders, user } = store.getState()
      onPathChange(
        currentRoute, previousRoute, store.getState().router.location.query, topUpHistory, assignBundleHistory, printHistory, buy, myOrders, user
      )
    }

    let previousProceedResult = currentProceedResult
    let proceedError = store.getState().buy.proceedError
    currentProceedResult = store.getState().buy.proceedResult
    if (currentProceedResult !== previousProceedResult && !proceedError) {
      onProceedSuccessful(currentProceedResult, store.getState().buy.paymentMethod)
    }

    let previousCompleteOfflineResult = currentCompleteOfflineResult
    let completeOfflineError = store.getState().buy.completeOfflineError
    currentCompleteOfflineResult = store.getState().buy.completeOfflineResult
    if (currentCompleteOfflineResult !== previousCompleteOfflineResult && !completeOfflineError) {
      onOrderComplete(currentProceedResult, store.getState().buy.paymentMethod)
    }

    let previousCreditDetails = currentCreditDetails
    // let completeOfflineError = store.getState().buy.completeOfflineError
    currentCreditDetails = store.getState().wallet.creditDetails
    if (previousCreditDetails !== currentCreditDetails) {
      onCreditDetailsChnage(currentCreditDetails)
    }

    let previousTermsAndConditions = currentTermsAndConditions
    currentTermsAndConditions = store.getState().user.termsAndConditionsData
    if (currentTermsAndConditions && previousTermsAndConditions !== currentTermsAndConditions) {
      onTermsAndConditionsChanged(currentTermsAndConditions)
    }

    let previousMarketingTopup = currentMarketingTopup
    currentMarketingTopup = store.getState().user.marketingPopupData
    if (currentMarketingTopup && previousMarketingTopup !== currentMarketingTopup) {
      onMarketingTopupChanged(currentMarketingTopup)
    }
  }

  return handleChange
}

export default automation;