import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';


export interface DownloadCentreState {
    fetchInProgress: boolean
    downloadHistory: any
}

const configureDownloadCentreModule = (services: IServices) => {
  const actions = initActions(services.downloadCentreService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type DownloadCentreReducer = ReturnType<typeof initReducer>
export type DownloadCentreActions = ReturnType<typeof initActions>

export default configureDownloadCentreModule;