import { IServices } from "@src/services";
import initActions from "./actions";
import initReducer from "./reducer";
import { Action } from "rxjs/internal/scheduler/Action";

const configureMigrationModule = (services: IServices) => {
    const actions = initActions(services.migrationService)
    const reducer = initReducer()

    return { actions, reducer }
}

export type MigrationReducer = ReturnType<typeof initReducer>
export type MigrationAction = ReturnType<typeof initActions>

export default configureMigrationModule