import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const PostPayService = (ajax: ajaxType) => {
    return {
        fetchBoltons: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/Boltons`)
                .pipe(map(res => res.response));
        },
        fetchProducts: (type: string, locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/Product/${type}/${locale}`)
                .pipe(map(res => res.response));
        },
        fetchDocuments: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/Documents`)
                .pipe(map(res => res.response));
        },
        getSessionId: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/GetSessionId`, {
                'Content-Type': 'text/plain; charset=utf-8',
            }, 'text')
                .pipe(map(res => res.response));
        },
        getCartTotal: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/CartTotal`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        customerProfileCheck: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/CustomerProfileCheck`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        validateCustomer: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ValidateCustomer`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        validateSubscription: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ValidateSubscription`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        validateSubscriptionV2: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ValidateSubscriptionV2`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        createConsent: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/CreateConsent`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        getFreeMSISDNFromPoolManager: (sessionId: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/FreeMSISDNFromPoolManager/${sessionId}`)
                .pipe(map(res => res.response));
        },
        digitalSigning: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/DigitalSigning`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        getEnvelopeStatus: (envelopeId: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/EnvelopeStatus/${envelopeId}`)
                .pipe(map(res => res.response));
        },
        fetchOperators: (sessionId: string, locale: string) => {
            console.log('locale',locale)
            return ajax.get(`${config.url.API_URL}PostPayPortIn/NetworkOperators/${sessionId}/${locale}`)
                .pipe(map(res => res.response));
        },
        fetchNonPortInDates: (sessionId: string, locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/NonPortInDates/${sessionId}/${locale}`)
                .pipe(map(res => res.response));
        },
        validateMsisdn: (sessionId: string, msisdn: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ValidateMsisdn/${msisdn}/${sessionId}`)
                .pipe(map(res => res.response));
        },
        generateOTAG: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/GenerateOTAG`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        validateOTAG: (sessionId: string, msisdn: string, otp: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ValidateOTAG/${sessionId}/${msisdn}/${otp}`)
                .pipe(map(res => res.response));
        },
        validateEligibility: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ValidateEligibility`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        fetchContractStatusList: (fromDate: string, toDate: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ContractStatusSummary/${fromDate}/${toDate}`)
                .pipe(map(res => res.response));
        },
        smsContractLink: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/SMSContractLink`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        emailContractSummary: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/EmailContractSummary`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        resendEmail: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ResendEmail`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        resignOnscreen: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ResignOnScreen`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        retryContract: (envelopId: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/Retry/${envelopId}`)
                .pipe(map(res => res.response));
        },
        postpayHistory: (locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/PostPayHistory/${locale}`)
                .pipe(map(res => res.response));
        },
        customerVerification: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/CustomerVerification`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        downloadContractSummary: (sessionId: string, msisdn: string) => {
            return ajax.get(
                `${config.url.API_URL}PostPayPortIn/DownloadContractSummary/${sessionId}/${msisdn}`,
                { 'Content-Type': 'application/pdf', 'Accept': '*/*' },
                'blob'
            ).pipe(map(res => res.response))
        },
        fetchPromotions: () => {
            return ajax.get(
                `${config.url.API_URL}PostPayPortIn/Promotions`
            ).pipe(map(res => res.response))
        },
        promotionEvaluation: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/PromotionEvaluation`,
            payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response))
        },
        checkRetentionElegibility: (sessionId: string, msisdn: number, locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/RetentionEligibilityCheck/${sessionId}/${msisdn}/${locale}`)
            .pipe(map(res => res.response))
        },
        validateRetention: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ValidateRetention`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        getPostPaidConfig: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/PostPaidConfig`)
                .pipe(map(res => res.response))
        }
    }
}

export type IPostPayService = ReturnType<typeof PostPayService>

export default PostPayService;