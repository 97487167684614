import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const SimRegAssessmentService = (ajax: ajaxType) => {
    return {
        downloadTD: () => {
            return ajax.download(`${config.url.API_URL}SimRegistrationAssessment/download/TD`, "get")
                .pipe(map(res => res.response))
        },
        fetchQuestions: () => {
            return ajax.get(`${config.url.API_URL}SimRegistrationAssessment/Details`)
                .pipe(map(res => res.response))
        },
        getSessionId: () => {
            return ajax.get(`${config.url.API_URL}SimRegistrationAssessment/Sessionkey`, {
                'Content-Type': 'text/plain; charset=utf-8',
            }, 'text')
                .pipe(map(res => res.response))
        },
        logProgress: (payload: string) => {
            return ajax.get(`${config.url.API_URL}SimRegistrationAssessment/Logs/${payload}`)
                .pipe(map(res => res.response))
        },
        getResult: (sessionId: string) => {
            return ajax.get(`${config.url.API_URL}SimRegistrationAssessment/Result/${sessionId}`)
                .pipe(map(res => res.response))
        },
        getOtherFlags: () => {
            return ajax.get(`${config.url.API_URL}SimRegistrationAssessment/IsExist`)
                .pipe(map(res => res.response))
        },
    }
}

export type ISimRegAssessmentService = ReturnType<typeof SimRegAssessmentService>

export default SimRegAssessmentService;