import { of, scheduled } from "rxjs"
import { catchError, map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const StandaloneTopUpService = (ajax: ajaxType) => {return {
    validateMsisdn: (msisdn: string, locale: string) => {
        return ajax.get(`${config.url.API_URL}Utility/MSISDN/validation/${locale}/${msisdn}`)
        .pipe(map(res => res.response))
    },
    applyVoucher: (request: any) => {
        return ajax.post(`${config.url.API_URL}transaction/QRCodeTopup`, request,
            {
                "Content-Type": "application/json"
            }
        ).pipe(
            map(res => res.response),
            catchError(err => of(null))
        )
    }
}}

export type IStandaloneTopUpService = ReturnType<typeof StandaloneTopUpService>

export default StandaloneTopUpService