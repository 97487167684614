import { IServices } from "@src/services";
import initActions from "./actions";


const configurePrepaidPortalModule: any = (services: IServices) => {
    const actions = initActions(services.prepaidPortalService, services.postPayService);

    return { actions };
}

export type PrepaidPortalActions = ReturnType<typeof initActions>;

export default configurePrepaidPortalModule