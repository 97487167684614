import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { i18n } from "i18next";
import { IPrintHistoryService, NumberType } from "src/services/PrintHistoryService";

const initActions = function (printHistoryService: IPrintHistoryService) {
    
    const fetchHistoryBySerial = (dispatch: AppDispatch) => (serial: string) => {
        dispatch({type: ActionType.PrintHistoryRequest})
        printHistoryService.fetchHistoryBySerial(serial)
            .subscribe(data => {
                dispatch({type: ActionType.PrintHistoryResponse, data: {data: data?[{
                    ActivatedDate: data.ActivatedDate,
                    Denomination: data.Denomination,
                    DisplayName: data.DisplayName? data.DisplayName: data.Name,
                    Name: data.Name,
                    PrintedDateTime: data.FirstPrintedDate,
                    RePrintedDateTime: data.PrintedDate,
                    Type: data.Type,
                    PrintBatchId: data.PrintBatchId,
                    SerialNumber: data.SerialNumber,
                    TransactionDoneBy: data.TransactionDoneBy,
                    Quantity: 1,
                    ProductName: data.ProductName,
                    ExpiryDate: data.ExpiryDate
                }]: []}})
            })
    };

    const fetchHistoryBy7Days = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.PrintHistoryRequest})
        printHistoryService.fetchHistoryByDay(7)
            .subscribe(data => {
                dispatch({type: ActionType.PrintHistoryResponse, data: {data}})
            })
    };

    const fetchHistoryByDate = (dispatch: AppDispatch) => (startDate: string, endDate: string) => {
        dispatch({type: ActionType.PrintHistoryRequest})
        printHistoryService.fetchHistoryByDate(startDate, endDate)
            .subscribe(data => {
                dispatch({type: ActionType.PrintHistoryResponse, data: {
                    data: data?data.map((item:any) => {
                        return {
                            Denomination: item.Denomination,
                            DisplayName: item.DisplayName? item.DisplayName: item.Name,
                            Name: item.Name,
                            PrintedDateTime: item.FirstPrintedDate,
                            RePrintedDateTime: item.PrintedDate,
                            Type: item.Type,
                            PrintBatchId: item.PrintBatchId,
                            SerialNumber: item.SerialNumber,
                            TransactionDoneBy: item.TransactionDoneBy,
                            Quantity: 1,
                            ActivatedDate: item.ActivatedDate,
                            ProductName: item.ProductName,
                            ExpiryDate: item.ExpiryDate
                        }
                    }): []
                }})
            })
    };

    const fetchSinglePrintOut = (dispatch: AppDispatch, getState: ()=>RootState, i18n: i18n) => (number: string) => {
        dispatch({type: ActionType.PrintHistoryReprintEpinRequest})
        const requst = {
            SerialNumbers: [number],
            Locale: i18n.language
        }
        printHistoryService.activationStatus(requst).subscribe(data => { 
            if(data) {
                const activeElement = data.find((epin: any) => epin && epin.ActivatedDate && epin.ActivatedDate !== '0001-01-01T00:00:00')
                if (activeElement) {
                    dispatch({type: ActionType.AddRecordEpinsPrintHistory, data})
                    //find active epin
                    const newHistory = getState().printHistory.history;
                    if(newHistory[0].Epins) {
                        const elHistory = newHistory.find((elem: any) => elem.Epins.find((epin: any) => epin.EpinId === activeElement?.EpinId).ActivatedDate = activeElement?.ActivatedDate)
                        elHistory.Epins.find((epin: any) => epin.EpinId === activeElement?.EpinId).ActivatedDate = activeElement.ActivatedDate
                    } else {
                        newHistory.find((elem: any) => elem.SerialNumber === activeElement?.SerialNumber).ActivatedDate = activeElement.ActivatedDate 
                    }
                    dispatch({type: ActionType.PrintHistoryResponse, data: {data: newHistory}})
                    dispatch({type: ActionType.PrintHistoryReprintEpinRequestСompletion})
                } else {
                    fetchPrintOutData(dispatch, NumberType.serialNumber, number, data.filter((epin: any) => !!epin.IsDigitalPin).length > 0)
                }
            }
        })
        
    }

    const fetchMultiplePrintOut = (dispatch: AppDispatch, getState: ()=>RootState, i18n: i18n) => (historyRecord: any) => {
        dispatch({type: ActionType.PrintHistoryReprintEpinRequest})
        const history = getState().printHistory.history;
        let serialNumbers = []
        if(historyRecord.Epins) {
            historyRecord.Epins.forEach((el: any) => {serialNumbers.push(el.SerialNumber)})
        } else {
            serialNumbers.push(historyRecord.SerialNumber)
        }
        const requst = {
            SerialNumbers: serialNumbers,
            Locale: i18n.language,
        }

        printHistoryService.activationStatus(requst).subscribe(data => { 
            if(data) {
                const activeElement = data.find((epin: any) => (epin && epin.ActivatedDate && epin.ActivatedDate !== '0001-01-01T00:00:00'))
                let elHistory: any
                if (activeElement) {
                    dispatch({type: ActionType.AddRecordEpinsPrintHistory, data})
                    
                    if(history[0].Epins){
                        //find active epin
                        elHistory = history.find((elem: any) => elem.Epins.find((epin: any) => epin.EpinId === activeElement?.EpinId))
                        elHistory.Epins = elHistory.Epins.map((epin: any) => {
                            const epinData = data.find((epinStatus: any) => (epin.EpinId === epinStatus.EpinId))
                            epin.ActivatedDate = epinData? epinData.ActivatedDate: epin.ActivatedDate
                            return epin
                        })
                    } else {
                        history.find((elem: any) => elem.SerialNumber === activeElement?.SerialNumber).ActivatedDate = activeElement.ActivatedDate
                    }
                    dispatch({type: ActionType.PrintHistoryResponse, data: {data: history}})
                    if(data.find((epin: any) => (epin&&!epin.ActivatedDate))) {
                        fetchPrintOutData(dispatch, NumberType.batchNumber, historyRecord.PrintBatchId, data.filter((epin: any) => !!epin.IsDigitalPin).length > 0, (records) => {
                            return records.MultipleEPinModelList.filter((record: any) => {return !elHistory.Epins.find((epin: any) => epin.SerialNumber === record.SerialNumber).ActivatedDate})
                        })
                    } else {
                        dispatch({type: ActionType.PrintHistoryReprintEpinRequestСompletion})
                    }
                } else {
                    fetchPrintOutData(dispatch, NumberType.batchNumber, historyRecord.PrintBatchId, !!data.filter((epin: any) => !!epin.IsDigitalPin).length)
                }
            }
        })
    }

    const RemoveRecordEpinsPrintHistory = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.RemoveRecordEpinsPrintHistory})
    }

    const AddRecordEpinsPrintHistory = (dispatch: AppDispatch) => (epins: any) => {
        dispatch({type: ActionType.AddRecordEpinsPrintHistory, data: epins})
    }

    const fetchPrintOutData = (dispatch: AppDispatch, type: NumberType, number: string, isDigitalPin: boolean, filter?: (arg0: any) => any) => {
        dispatch({type: ActionType.PrintHistoryPrintOutRequest})
        printHistoryService.fetchPrintOutInfo(type, number, false)
            .subscribe(data => {
                const filterData = filter? {MultipleEPinModelList: filter(data)}: data
                dispatch({type: ActionType.PrintHistoryPrintOutResponse, data: {data: { ...filterData, isDigitalPin }}})
            })
    };
    const cleanPrintHistory = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.CleanPrintHistory})
    }
    const cleanPrintData = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanPrintData })
    }
    
    return { 
        fetchHistoryBySerial, 
        fetchHistoryBy7Days, 
        fetchHistoryByDate, 
        fetchSinglePrintOut, 
        fetchMultiplePrintOut, 
        cleanPrintData,
        cleanPrintHistory,
        RemoveRecordEpinsPrintHistory,
        AddRecordEpinsPrintHistory
    }; 
  };
  
  export default initActions;