declare const window: any

export const trackWalletDebit = (user: string, country: string) => {
    if (window?.dataLayer) {
        const eventObj = {
            event: 'wallet_used',
            userId: user,
            user_country: country
        }
        window?.dataLayer?.push(eventObj)
    }
}

export const trackBannerClick = (user: string, country: string, banner: string) => {
    if (window?.dataLayer) {
        const eventObj = {
            event: 'banner_click',
            userId: user,
            user_country: country,
            banner
        }
        window?.dataLayer?.push(eventObj)
    }
}

export const trackAssignBundle = (user: string, country: string) => {
    if (window?.dataLayer) {
        const eventObj = {
            event: 'bundle_assign',
            userId: user,
            user_country: country
        }
        window?.dataLayer?.push(eventObj)
    }
}

export const trackReCaptchaPopup = (user: string, country: string, loginScore: number, lowScoreReason: string = '') => {
    if (window?.dataLayer) {
        const eventObj = {
            event: 'recaptcha_popup',
            login_score: loginScore,
            low_score_reason: lowScoreReason,
            userId: user,
            user_country: country
        }
        window?.dataLayer?.push(eventObj)
    }
}

export const trackLoginAttempt = (user: string, country: string) => {
    if (window?.dataLayer) {
        const eventObj = {
            event: 'login_attempt',
            userId: user,
            user_country: country
        }
        window?.dataLayer?.push(eventObj)
    }
}