import { Observable } from "rxjs"
import { ajax, AjaxResponse } from "rxjs/ajax"
import { catchError } from "rxjs/operators"


export type ajaxType = {
    get: (s: string, h?: any, r?: string) => Observable<AjaxResponse>,
    post: typeof ajax.post,
    put: typeof ajax.put,
    download: (s: string, h?: any) => Observable<AjaxResponse>,
    del: (s: string) => Observable<AjaxResponse>
}


export type interceptorConnectorType = {
    setToken: (bearerToken: string) => void,
    setOnErrorListener: (errorCode: number, cb: () => void) => void
}

const Interceptor: () => { ajax: ajaxType, interceptorConnector: interceptorConnectorType } = () => {
    let token: string = ''
    const errorCallbacks = new Map<number, () => void>()

    const setToken = (bearerToken: string) => {
        token = bearerToken
    }

    const setOnErrorListener = (errorCode: number, cb: () => void) => {
        errorCallbacks.set(errorCode, cb)
    }

    const callErrorHandler = (status: number) => {
        const cb = errorCallbacks.get(status)
        if (cb) {
            cb()
        }
    }

    const addAuthHeader = (headers: Object): Object => {
        if (token) {
            return {
                ...headers,
                Authorization: `Bearer ${token}`
            }
        }
        else {
            return headers
        }
    }
    const get = (url: string, headers?: Object, responseType: string = 'json') => {
        return ajax({
                url,
                method: 'GET',
                headers: addAuthHeader(headers ? headers : {}),
                responseType
            })
            .pipe(catchError(err => {
                callErrorHandler(err.status)
                return new Observable<AjaxResponse>(subscriber => subscriber.error({ ...err }))
            }))
    }

    const del = (url: string) => {
        const obs = ajax({
            url: url,
            headers: addAuthHeader({}),
            responseType: 'blob',
            method: 'DELETE'
        }).pipe(catchError(err => {
            callErrorHandler(err.status)
            return new Observable<AjaxResponse>(subscriber => subscriber.error({ ...err }))
        }))
        return obs;
    }

    const download = (url: string, method:string="post") => {
        const obs = ajax({
            url: url,
            headers: addAuthHeader({}),
            responseType: 'blob',
            method: method
        })
            .pipe(catchError(err => {
                callErrorHandler(err.status)
                return new Observable<AjaxResponse>()
            }))
        return obs
    }

    const post = (url: string, body?: any, headers?: Object) => {
        return ajax.post(url, body, addAuthHeader(headers ? headers : {}))
            .pipe(catchError(err => {
                callErrorHandler(err.status)
                return new Observable<AjaxResponse>(subscriber => subscriber.error({ ...err }))
            }))
    }

    const put = (url: string, body?: any, headers?: Object) => {
        return ajax.put(url, body, addAuthHeader(headers ? headers : {}))
            .pipe(catchError(err => {
                callErrorHandler(err.status)
                return new Observable<AjaxResponse>(subscriber => subscriber.error({ ...err }))
            }))
    }

    return {
        ajax: {
            get,
            post,
            put,
            download,
            del
        },
        interceptorConnector: {
            setToken,
            setOnErrorListener
        }
    }

}

export default Interceptor