import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';


export interface SimRegAssessmentState {
  tdFile: any,
  loading: boolean,
  questionLoading: boolean,
  questions: any ,
  sessionId: string,
  result: any,
  logAnswers: any,
  otherFlags: any,
}

const configureSimRegAssessmentModule = (services: IServices) => {
  const actions = initActions(services.simRegAssessmentService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type SimRegAssessmentReducer = ReturnType<typeof initReducer>
export type SimRegAssessmentActions = ReturnType<typeof initActions>

export default configureSimRegAssessmentModule;