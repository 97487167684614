import { ICommissionService } from "@src/services/CommissionService";
import { map } from "rxjs/operators";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { MyCommissionSetFilters } from ".";

let commissionDataFetchInProgress = false

const initActions = function (commissionService: ICommissionService) {


  const fetchCommissionInfo = (dispatch: AppDispatch, getState: () => RootState) => () => {
    if(getState().commission?.fetchInProgress) {
        return
    }
    dispatch({ type: ActionType.CommissionDataRequest })
    const filters = getState().commission.filters
    commissionService.fetchCommissionInfo({
        ...filters,
        dateFrom: filters?.dateFrom?.toLocaleDateString("en-US", {
          month: "2-digit",
          year: "numeric",
        }).replace("/", "-"),
        toDate: filters?.toDate?.toLocaleDateString("en-US", {
          month: "2-digit",
          year: "numeric",
        }).replace("/", "-")
    }).subscribe(res => {
        dispatch({ type: ActionType.CommissionDataResponse, data: res })
    })
}

const fetchCommissionSummary = (dispatch: AppDispatch, getState: () => RootState) => () => {
  dispatch({type: ActionType.CommissionSummaryRequest})
  commissionService.fetchCommissionSummary()
    .pipe(map(data => {
      if (data){
        return {
          TotalUsed: data.TotalUsed,
          TotalEarned: data.TotalEarned,
          ReservedCommission: data.ReservedCommission,
          UsedCommission: data.UsedCommission,
          AvailableCommission: data.AvailableCommission,
          AvailableCommissionForCart: data.AvailableCommissionForCart,
          Balance: data.Balance,
          TotalCount: data.TotalCount,
          Timestamp: data.Timestamp
        }
      } else {
        return undefined
      }
    }))
    .subscribe(data => dispatch({type: ActionType.CommissionSummaryResponse, data: data}));
};

    const setCommissionFilter = (dispatch: AppDispatch) => (filters: MyCommissionSetFilters) => {
      dispatch({ type: ActionType.CommissionSetFilters, data: filters })
  }
     
  return { fetchCommissionInfo,setCommissionFilter,fetchCommissionSummary }; 
};

export default initActions;