import { map } from "rxjs/operators";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { IWalletService } from "@src/services/WalletService";
import { WalletHistoryFilter, WalletHistoryState } from './reducer'

export default function initActions (walletService: IWalletService) {

    const getWalletSummary = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.WalletSummaryRequest })
        const { dateFrom, toDate } = getState().walletHistory.filters
        walletService.fetchWalletSummary(dateFrom, toDate)
            .subscribe(res => {
                dispatch({ type: ActionType.WalletSummaryResponse, data: res })
            })
    }

    const setWalletFilter = (dispatch: AppDispatch, getState: () => RootState) => (filters: WalletHistoryFilter) => {
        dispatch({ type: ActionType.WalletHistoryUpdateFilter, data: filters })
        getWalletSummary(dispatch, getState)()
        getWalletHistory(dispatch, getState)()
    }

    const getWalletHistory = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.WalletHistoryRequest })
        const { dateFrom, toDate } = getState().walletHistory.filters
        walletService.fetchWalletHistory(dateFrom, toDate)
            .subscribe(res => {
                dispatch({ 
                    type: ActionType.WalletHistoryResponse, 
                    data: { 
                        history: res && res.length > 100 ? res.slice(0, 100) : res, 
                        isRecordOverload: res && res.length > 100 
                    } 
                })
            })
    }

    const downloadHistory = (dispatch: AppDispatch, getState: ()=>RootState) => () => {
        dispatch({type: ActionType.WalletHistoryStartDownload})
        const { dateFrom, toDate } = getState().walletHistory.filters
        walletService.downloadTransaction(dateFrom, toDate)
            .pipe(map(data => {
                const typeFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                window.location.href = `data:${typeFile};base64,${data}`;
            }))
            .subscribe({
                next: () => dispatch({type: ActionType.WalletHistoryEndDownload}),
                error: () => dispatch({type: ActionType.WalletHistoryEndDownload})
            })
    };

    const cleanWalletHistory = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanWalletHistory })
    }

    return { getWalletSummary, setWalletFilter, getWalletHistory, cleanWalletHistory, downloadHistory }
}