import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureBuyModule = (services: IServices) => {
  const actions = initActions(services.buyService);
  const reducer = initReducer(services.buyService, services.storageService);

  return { actions, reducer }; 
};

export type BuyReducer = ReturnType<typeof initReducer>
export type BuyActions = ReturnType<typeof initActions>

export default configureBuyModule;