import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";
import { ISimRegAssessmentService } from "src/services/SimRegAssessmentService";


const initActions = (SimRegAssessmentServices: ISimRegAssessmentService) => {

    const getTDfile = (dispatch: AppDispatch) => () => {
        SimRegAssessmentServices.downloadTD().subscribe((res: any) => {
            const file = new Blob([res], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            dispatch({ type: ActionType.SimRegAssessmentTDDownloadResponse, data: fileURL })
        })
    }
    const getQuestions = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimRegAssessmentRequest })
        SimRegAssessmentServices.fetchQuestions().subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentQuestionResponse, data: res })
        })
    }
    const getSessionId = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimRegAssessmentQuestionRequest })
        SimRegAssessmentServices.getSessionId().subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentSessionIdResponse, data: res })
        })
    }

    const logProgress = (dispatch: AppDispatch) => (payload: any) => {
        const { questionId, answers, sessionId } = payload
        const path = `${answers?.join(',')}/${questionId}/${sessionId}`
        SimRegAssessmentServices.logProgress(path).subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentlogResponse, data: { ...res, questionId, answers } })
        })
    }
    const submitTest = (dispatch: AppDispatch) => (sessionId: string) => {
        dispatch({ type: ActionType.SimRegAssessmentRequest })
        SimRegAssessmentServices.getResult(sessionId).subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentResultResponse, data: res })
        })
    }
    const getOtherFlags = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimRegAssessmentRequest })
        SimRegAssessmentServices.getOtherFlags().subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentFlagResponse, data: res })
        })
    }
    const cleanQuestionnaire = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.CleanQuestionnaire})
    }
    return {
        getTDfile,
        getQuestions,
        getSessionId,
        logProgress,
        submitTest,
        getOtherFlags,
        cleanQuestionnaire
    }
}

export default initActions