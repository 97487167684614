import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureAlertsModule = (services: IServices) => {
  const actions = initActions(services.alertsService, services.storageService);
  const reducer = initReducer(services.alertsService);

  return { actions, reducer };
};

export type AlertsReducer = ReturnType<typeof initReducer>
export type AlertsActions = ReturnType<typeof initActions>

export default configureAlertsModule;