import { IBuyService } from "@src/services/BuyService";
import { IStorageService } from "@src/services/StorageService";
import { IAction, ActionType } from "../types";

const initReducer = function (buyService:IBuyService, storageService: IStorageService) {
  const adyenReloadData = storageService.get('adyenReloadData');
  const cartData = adyenReloadData ? JSON.parse(adyenReloadData) : '';

    const INITIAL_STATE = {
      cart: cartData?.isReloadAdyen ? cartData?.adyenValue : (undefined as any),
      allowedPaymentModes: (undefined as any),
      fetchCartInProgress: false,
      addCartInProgress: false,
      isDeliveryLoading: (undefined as any),
      cartErrorValue: (undefined as any),
      isEditingDeliveryAddress: (undefined as any),
      cartErrorMessage: (undefined as any),
      isCartError: (undefined as any),
      addingItemId: (undefined as any),
      proceedInProgress: false,
      proceedResult: (undefined as any),
      proceedError: (undefined as any),
      proceedErrorMessage: (undefined as any),
      productNames: (undefined as any),
      orderConfirmationInProgress: (undefined as any),
      orderConfirmationData: (undefined as any),
      orderConfirmationError: (undefined as any),
      orderStatusInProgress: (undefined as any),
      orderStatusData: (undefined as any),
      orderStatusError: (undefined as any),
      orderCompleteInProgress: (undefined as any),
      paymentMethod: (undefined as any),
      paymentMode: (undefined as any),
      completeInProgress: (undefined as any),
      completeOfflineResult: (undefined as any),
      completeOfflineError: (undefined as any),
      multiPaymentViewModel: (undefined as any),
      cartReviewInProgress: false
    };

    let reducersByActions: any = {}
    
    reducersByActions[ActionType.fetchCartRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        fetchCartInProgress: true
      }
    }
    
    reducersByActions[ActionType.fetchCartResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        cart: actionData.cart,
        allowedPaymentModes: actionData.paymentModes,
        fetchCartInProgress: false
      }
    }

    reducersByActions[ActionType.AddCartRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        addCartInProgress: true,
        addingItemId: actionData
      }
    }
    
    reducersByActions[ActionType.AddCartResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        cart: actionData.cart,
        allowedPaymentModes: actionData.paymentModes,
        addCartInProgress: false
      }
    }

    reducersByActions[ActionType.UpdateDeliveryAddress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        isDeliveryLoading: true
      }
    }

    reducersByActions[ActionType.UpdateDeliveryAddressResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      const cart = {...state.cart};
      cart.ShipingAddress = actionData;
      return {
        ...state,
        cart,
        isDeliveryLoading: (undefined as any),
        isEditingDeliveryAddress: (undefined as any)
      }
    }

    reducersByActions[ActionType.OpenDeliveryAddress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        isEditingDeliveryAddress: true
      }
    }

    reducersByActions[ActionType.CloseDeliveryAddress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        isEditingDeliveryAddress: (undefined as any)
      }
    }
    
    reducersByActions[ActionType.fetchCartError] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        isCartError: true,
        cartErrorMessage: actionData.ErrorMessage?actionData.ErrorMessage:"Cart error",
        fetchCartInProgress: false,
        cartErrorValue: actionData.cartErrorValue
      }
    }

    reducersByActions[ActionType.AddCartError] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        isCartError: true,
        cartErrorMessage: actionData.ErrorMessage?actionData.ErrorMessage:"Cart error",
        cartErrorValue: actionData.cartErrorValue,
        addCartInProgress: false
      }
    }

    reducersByActions[ActionType.ProceedCommissionPaymentRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: true               
      }
    }

    reducersByActions[ActionType.ProceedCommissionPaymentResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: false,
        proceedResult: actionData.result,
        proceedError: !actionData.result.Status,
        proceedErrorMessage: !actionData.result.Status ? actionData.result.ErrorMessage : undefined,        
        paymentMethod: actionData.paymentMethod,
        productNames: actionData.ProductNames
      }
    }

    reducersByActions[ActionType.ProceedZeroPaymentRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: true               
      }
    }

    reducersByActions[ActionType.ProceedZeroPaymentResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: false,
        proceedResult: actionData.result,
        proceedError: !actionData.result.Status,
        proceedErrorMessage: !actionData.result.Status ? actionData.result.ErrorMessage : undefined,
        paymentMethod: actionData.paymentMethod,
        productNames: actionData.ProductNames
      }
    }

    reducersByActions[ActionType.CompleteOfflinePaymentRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        completeInProgress: true               
      }
    }

    reducersByActions[ActionType.CompleteOfflinePaymentResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        completeInProgress: false,
        completeOfflineResult: actionData.result,
        completeOfflineError: !actionData.result.Status,
        paymentMethod: actionData.paymentMethod
      }
    }

    reducersByActions[ActionType.ProceedOnlinePaymentRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: true,
        paymentMethod: 'online'
      }
    }

    reducersByActions[ActionType.ProceedOnlinePaymentResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: false,
        proceedResult: actionData,
        proceedError: !actionData.Status,
        proceedErrorMessage: !actionData.Status ? actionData.ErrorMessage : undefined,
        productNames: actionData.ProductNames
      }
    }

    reducersByActions[ActionType.ProceedMBEPaymentRequest] = (state: typeof INITIAL_STATE) => {
      return {
        ...state,
        proceedInProgress: true
      }
    }

    reducersByActions[ActionType.ProceedMBEPaymentResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedInProgress: false,
        proceedResult: actionData.result,
        proceedError: !actionData.result.Status,
        proceedErrorMessage: !actionData.result.Status ? actionData.result.ErrorMessage : undefined,
        paymentMethod: actionData.paymentMethod,
        productNames: actionData.ProductNames
      }
    }

    reducersByActions[ActionType.ClearProceedCommissionError] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        proceedError: undefined,
        // proceedErrorMessage: undefined,

      }
    }    

    reducersByActions[ActionType.OrderConfirmationRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderConfirmationInProgress: true,
        orderConfirmationData: undefined,
        orderCompleteDataError: undefined
      }
    }

    reducersByActions[ActionType.OrderConfirmationResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderConfirmationInProgress: undefined,
        orderConfirmationData: actionData
      }
    }

    reducersByActions[ActionType.OrderConfirmationError] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderCompleteInProgress: undefined,
        orderCompleteDataError: actionData
      }
    }

    reducersByActions[ActionType.OrderCompleteRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderCompleteInProgress: true,
        orderConfirmationData: undefined
      }
    }

    reducersByActions[ActionType.OrderCompleteResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderConfirmationData: actionData,
        orderCompleteInProgress: undefined
      }
    }

    reducersByActions[ActionType.OrderStatusRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderStatusInProgress: true,
        orderStatusData: undefined,
        orderConfirmationData: undefined,
        paymentMode: actionData
      }
    }

    reducersByActions[ActionType.OrderStatusResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        orderStatusData: actionData,
        orderConfirmationInProgress: undefined,
        orderStatusInProgress: undefined
      }
    }

    reducersByActions[ActionType.fetchCartReviewRequest] = (state: typeof INITIAL_STATE) => {
      return {
        ...state,
        cartReviewInProgress: true
      }
    }

    reducersByActions[ActionType.fetchCartReviewResponse] = (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        cartReviewInProgress: false,
        multiPaymentViewModel: actionData?.MultiPaymentViewModel
      }
    }
    
    reducersByActions[ActionType.cleanCartError] =  (state: typeof INITIAL_STATE) => {
      return {
        ...state,
        isCartError: undefined
      }
    }

    reducersByActions[ActionType.OrderCompleteCleanSummary] =  (state: typeof INITIAL_STATE) => {
      return {
        ...state,
        isCartError: undefined,
        orderStatusData: undefined,
        orderConfirmationData: undefined
      }
    }
    
    reducersByActions[ActionType.Logout] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return INITIAL_STATE
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;