import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureBuyAndPrintModule = (services: IServices) => {
  const actions = initActions(services.buyAndPrintService, services.printHistoryService);
  const reducer = initReducer(services.buyAndPrintService);

  return { actions, reducer }; 
};

export type BuyAndPrintReducer = ReturnType<typeof initReducer>
export type BuyAndPrintActions = ReturnType<typeof initActions>

export default configureBuyAndPrintModule;