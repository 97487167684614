import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureTopUpModule = (services: IServices) => {
  const actions = initActions(services.topUpService, services.standaloneTopUpService);
  const reducer = initReducer(services.topUpService);

  return { actions, reducer };
};

export type TopUpReducer = ReturnType<typeof initReducer>
export type TopUpActions = ReturnType<typeof initActions>

export default configureTopUpModule;