import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const WalletService = (ajax: ajaxType) => {
    return {
        fetchWalletInfo: () => {
            return ajax.get(`${config.url.API_URL}Wallet/GetWallet`).pipe(map(res => res.response))
        },
        fetchCreditTiles: () => {
            return ajax.get(`${config.url.API_URL}Wallet/Credit/Tiles`).pipe(map(res => res.response))
        },
        paymentCreditDetails: (denomination: number, discountValue: number, locale: string) => {
            return ajax.get(`${config.url.API_URL}Payment/Credit/${denomination}/${discountValue}/${locale}`).pipe(map(res => res.response))
        },
        creditComplete: (transactionId: string) => {
            return ajax.get(`${config.url.API_URL}Wallet/Credit/Balance/?transactionId=${transactionId}`).pipe(map(res => res.response))
        },
        fetchWalletHistory: (fromDate: string, toDate: string) =>
            ajax.get(`${config.url.API_URL}Wallet/History/all/${fromDate}/${toDate}`).pipe(map(res => res.response)),
        fetchWalletSummary: (fromDate: string, toDate: string) =>
            ajax.get(`${config.url.API_URL}Wallet/Summary/all/${fromDate}/${toDate}`).pipe(map(res => res.response)),
        downloadTransaction: (fromDate: string, toDate: string) =>
            ajax.get(
                `${config.url.API_URL}Wallet/DownloadTransaction?Type=all&From=${fromDate}&To=${toDate}&IsDateWithDay=false`,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response)),
        employeeWalletLock: (lock: boolean) =>
            ajax.put(`${config.url.API_URL}Wallet/${lock ? 'Lock' : 'Restore'}/employerwallet`, {},
                { "Content-Type": "application/json" }).pipe(map(res => res.response))
    }
}

export type IWalletService = ReturnType<typeof WalletService>

export default WalletService