import { IStorageService } from "@src/services/StorageService";
import { ICommissionService } from "@src/services/CommissionService";
import { IAction, ActionType } from "../types";
import {MyCommissionState} from "./index"
import moment from 'moment';

const initReducer = function (commissionService:ICommissionService, storageService: IStorageService) {

  const adyenReloadData = storageService.get('adyenReloadData');
  const selectedDenominationValue = adyenReloadData ? JSON.parse(adyenReloadData) : '';

    const INITIAL_STATE: MyCommissionState = {
      fetchInProgress: false,
      commissionData: (undefined as any),
      commissionsummaryData: (undefined as any),
      filters:{
        status: 'All',
        dateFrom: moment().subtract(3, 'months').toDate(),
        toDate: moment().toDate(),
      },
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.CommissionDataRequest] =  (state: MyCommissionState, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
      };
    }
    reducersByActions[ActionType.Logout] =  (state: MyCommissionState, actionData: any) => {
      return INITIAL_STATE
    }
    reducersByActions[ActionType.CommissionDataResponse] =  (state: MyCommissionState, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        commissionData: actionData
      };
    }

    reducersByActions[ActionType.CommissionSetFilters] =  (state: MyCommissionState, actionData: any) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...actionData
      }
      };
    }

    reducersByActions[ActionType.CommissionSummaryRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
      };
    }
    reducersByActions[ActionType.CommissionSummaryResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        commissionsummaryData: actionData
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;