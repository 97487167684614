import { map } from "rxjs/operators";
import config from "../../env";
import { ajaxType } from "../interceptor";
const MigrationService = (ajax: ajaxType) => {
  return {
    getSessionId: () => {
      return ajax
        .get(
          `${config.url.API_URL}PostPayPortIn/GetSessionId`,
          {
            "Content-Type": "text/plain; charset=utf-8",
          },
          "text"
        )
        .pipe(
          map((res) => {
            console.log("res", res);
            return res.response;
          })
        );
    },
    validateMsisdn: (sessionId: string, msisdn: string) => {
      return ajax
        .get(
          `${config.url.API_URL}PostPayPortIn/ValidateMsisdn/${msisdn}/${sessionId}`
        )
        .pipe(map((res) => res.response));
    },
    generateOTAG: (payload: any) => {
      return ajax
        .post(`${config.url.API_URL}PostPayPortIn/GenerateOTAG`, payload, {
          "Content-Type": "application/json",
        })
        .pipe(map((res) => res.response));
    },
    validateOTAG: (sessionId: string, msisdn: string, otp: string) => {
      return ajax
        .get(
          `${config.url.API_URL}PostPayPortIn/ValidateOTAG/${sessionId}/${msisdn}/${otp}`
        )
        .pipe(map((res) => res.response));
    },
  };
};

export type IMigrationService = ReturnType<typeof MigrationService>;

export default MigrationService;
