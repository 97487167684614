import { IForgottenPasswordService } from "@src/services/ForgottenPasswordService";
import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";

const initActions = function (userService: IForgottenPasswordService) {
  
  const getPasswordChangeInfo = (dispatch: AppDispatch) => (token: string) => {
    dispatch({
      type: ActionType.ForgottenPasswordDataRequest
    })
    userService.getPasswordChangeInfo(token)
    .subscribe(data => {
      if (data.IsSuccess){
        dispatch({
          type: ActionType.ForgottenPasswordDataResponse, 
          data: data
        })
      } else {
        dispatch({
          type: ActionType.SetForgottenPasswordDataRequestError, 
          data: data.ErrorMessage
        })
      }
    });
  };
  
  const updatePassword = (dispatch: AppDispatch) => ( newPassword: string, repeatNewPassword: string, dataObject: any) => {
    if (!newPassword) {
      dispatch({
        type: ActionType.SetNewPasswordError
      })
    }
    if (!repeatNewPassword) {
      dispatch({
        type: ActionType.SetRepeatNewPasswordError
      })
    }
    if (newPassword && repeatNewPassword && repeatNewPassword !== newPassword) {
      dispatch({
        type: ActionType.SetPasswordsShouldBeEqualError
      })
    }
    if (newPassword && repeatNewPassword && repeatNewPassword === newPassword && 
        (!/^.{11,14}$/.exec(newPassword) ||
        !/[a-z]+/.exec(newPassword) ||
        !/[A-Z]+/.exec(newPassword) ||
        !/[0-9]+/.exec(newPassword))) {
      dispatch({
        type: ActionType.SetPasswordFormatError
      })
    } else if (newPassword && repeatNewPassword && repeatNewPassword === newPassword) {
      dataObject.NewPassword = newPassword
      dataObject.ConfirmPassword = repeatNewPassword
      dispatch({
        type: ActionType.UpdatePasswordRequest
      })
      userService.updatePassword(dataObject)
      .subscribe(data => {
        if (data.IsSucceed) {
          dispatch({
            type: ActionType.UpdatePasswordResponse, 
            data: data
          })
        }
        else {
          dispatch({
            type: ActionType.SetNewPasswordRequestError, 
            data: "Error"
          })
        }
      });
    }
  };

  const cleanError = (dispatch: AppDispatch) => () => {
    dispatch({type: ActionType.ForgottenCleanError, data: undefined})
  };
  
  return { getPasswordChangeInfo, updatePassword, cleanError }; 
};
  
  export default initActions;