import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { IBuyService } from "src/services/BuyService";
import { delay, map, repeat, switchMap, takeWhile, takeUntil } from 'rxjs/operators';
import { of, timer } from "rxjs";
import StorageService from "src/services/StorageService";
import { trackWalletDebit } from "../analyticsTracking";

var products: string[] = [];
const STATUS_REQUEST_DELAY = 2000;
const STATUS_REQUEST_TIMER = 40000;
const CATEGORY_TYPES = ['leftnavigation-simcards', 'leftnavigation-topupvoucher', 'leftnavigation-epins', 'leftnavigation-bundles', 'leftnavigation-play', 'mbe-subcatagory-title']

const initActions = function (buyService: IBuyService) {


    const addToCart = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (productId: string, productTotalQty: number, price: number, productType: string, minQty?: number, minOQ?: number, maxOQ?: number) => {
        dispatch({ type: ActionType.AddCartRequest, data: productId })

        const userData = getState().user.userData
        if (userData.CountryMaxOV && (productTotalQty * price) + getState().buy.cart.Total > userData.CountryMaxOV) {
            dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: 'MaxOV_v2', cartErrorValue: userData.CountryMaxOV } })
            return
        }

        const cartList = getState().buy.cart?.CartList
        const productCategoryNotAllowedToMix = getState().user.countryConfiguration.ProductCategoryNotAllowedToMix
        if (productCategoryNotAllowedToMix && cartList) {
            const cartProductTypes = cartList.map((item: any) => {
                return item.Product.ProductType
            })
            for (const elem of cartProductTypes) {
                if (
                    (!!productCategoryNotAllowedToMix[elem] && productCategoryNotAllowedToMix[elem].includes(productType)) ||
                    (!!productCategoryNotAllowedToMix[productType] && productCategoryNotAllowedToMix[productType].includes(elem))
                ) {
                    dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: 'viewcart-productcategorymixerrormsg_v2', cartErrorValue: [CATEGORY_TYPES[+elem], CATEGORY_TYPES[+productType]] } })
                    return
                }
            }
        }


        const existingQty = getState().buy.cart?.CartList.find((el: any) => el.Product.ProductId === productId)?.Quantity
        const qty = existingQty ? existingQty + productTotalQty : productTotalQty

        if ((minOQ && minOQ > qty) || (maxOQ && maxOQ < qty)) {
            dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: 'range-error-message_v2', cartErrorValue: [minOQ, maxOQ] } })
            return
        }

        if (!minQty || minQty <= qty) {
            let parseReorderData: any;
            buyService.addToCart(productId, (existingQty ? existingQty : 0) + productTotalQty, i18n.language).subscribe((res => {

                const reOrder = StorageService().get('reOrderProduct');
                if (reOrder) {
                    parseReorderData = JSON.parse(reOrder);
                    products.push(parseReorderData.filter((ele: any) => ele.ProductId === productId)[0].DescriptionToDisplay);
                }

                if (parseReorderData && minQty == 0)
                    StorageService().remove('reOrderProduct');

                if (res.Status) {
                    dispatch({ type: ActionType.AddCartResponse, data: { cart: res.CartDetails, paymentModes: getPaymentModes(res.CartDetails?.CartList) } })
                } else if (res.ErrorMessage === 'range-error-message_v2') {
                    dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: 'range-error-message_v2', cartErrorValue: [minOQ, maxOQ] } })
                } else if (res.ErrorMessage === 'product-disabled') {
                    dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: res.ErrorMessage } })
                } else if (res.ErrorMessage === 'product-not-eligible') {
                    dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: res.ErrorMessage, cartErrorValue: [products.join(', ')] } })
                } else if (res.ErrorMessage === 'viewcart-productcategorymixerrormsg_posmaterial') {
                    dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: res.ErrorMessage, cartErrorValue: [] } })
                } else {
                    dispatch({ type: ActionType.AddCartError, data: res })
                }
            }))
        } else {
            dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: 'productcategory-baseqtyerrormsg', cartErrorValue: minQty } })
        }

    }

    const updateCart = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (productId: string, productTotalQty: number) => {
        dispatch({ type: ActionType.fetchCartRequest })

        const minQtys = getState().items.minQtys
        if (minQtys && minQtys[productId] > productTotalQty) {
            dispatch({ type: ActionType.fetchCartError, data: { ErrorMessage: 'productcategory-baseqtyerrormsg', cartErrorValue: minQtys[productId] } })
            return
        }

        let parseReorderData: any;
        buyService.updateCart(productId, productTotalQty, i18n.language).subscribe((res => {

            const reOrder = StorageService().get('reOrderProduct');
            if (reOrder) {
                parseReorderData = JSON.parse(reOrder);
            }

            if (parseReorderData)
                StorageService().remove('reOrderProduct');

            if (res.Status) {
                dispatch({ type: ActionType.fetchCartResponse, data: { cart: res.CartDetails, paymentModes: getPaymentModes(res.CartDetails?.CartList) } })
            } else if (res?.ErrorMessage === 'product-disabled') {
                dispatch({ type: ActionType.fetchCartError, data: { ErrorMessage: res.ErrorMessage } })
            } else if (res.ErrorMessage === 'product-not-eligible') {
                dispatch({ type: ActionType.AddCartError, data: { ErrorMessage: res.ErrorMessage, cartErrorValue: [parseReorderData.filter((ele: any) => ele.ProductId === productId)[0].DescriptionToDisplay] } })
            } else {
                dispatch({ type: ActionType.fetchCartError, data: res })
            }
        }))
    }

    const getCart = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.fetchCartRequest })
        buyService.getCart().subscribe((res => {
            dispatch({ type: ActionType.fetchCartResponse, data: { cart: res, paymentModes: getPaymentModes(res?.CartList) } })
        }))
    }

    const reviewCart = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.fetchCartReviewRequest })
        buyService.cartReview().subscribe((res) => {
            dispatch({ type: ActionType.fetchCartReviewResponse, data: res })
        })
    }

    const getPaymentModes = (cartList: any[] | undefined) => {
        if (cartList === undefined) return []

        let allModes = ["CreditCard", "OfflineBankTransfer", "COD", "Commission"]
        cartList.map((item: any) => {
            allModes = item.Product.PaymentModes.filter((el: any) => allModes.includes(el))
            return null
        })
        return allModes
    }

    const updateDeliveryAddress = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (data: any) => {
        dispatch({ type: ActionType.UpdateDeliveryAddress })
        buyService.updateDelivery(data).subscribe(res => {
            if (res) {
                dispatch({ type: ActionType.UpdateDeliveryAddressResponse, data: JSON.stringify(data) })
            }
        })
    }

    const removeFromCart = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (productId: string) => {
        dispatch({ type: ActionType.fetchCartRequest })
        buyService.removeFromCart(productId, i18n.language).subscribe((res => {
            if (res.Status) {
                dispatch({ type: ActionType.fetchCartResponse, data: { cart: res.CartDetails, paymentModes: getPaymentModes(res.CartDetails?.CartList) } })
            } else {
                dispatch({ type: ActionType.fetchCartError, data: res })
            }
        }))
    }

    const closeDeliveryAddress = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.CloseDeliveryAddress })
    }



    const cleanCartError = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.cleanCartError })
    }

    const openDeliveryAddress = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.OpenDeliveryAddress })
    }

    const proceedPayment = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        StorageService().remove("adyenReloadData");
        dispatch({ type: ActionType.ProceedOnlinePaymentRequest })
        return buyService.paymentDetails(i18n.language).subscribe((res => {
            dispatch({ type: ActionType.ProceedOnlinePaymentResponse, data: res })
        }))
    }

    const proceedCommissionPayment = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderAmount: number) => {
        let isCommissionPayment = true;
        dispatch({ type: ActionType.ProceedCommissionPaymentRequest })
        buyService.orderCheckout(orderAmount, 0).pipe(
            switchMap(createOrderResult => {
                if (createOrderResult.Status && createOrderResult.IsWalletPayment) {
                    return buyService.commisionPayment(createOrderResult.OrderNumber).pipe(map(res => {
                        if (res.Status) {
                            const { selectedCountry, userData } = getState().user
                            trackWalletDebit(userData.AXCode[0], selectedCountry as string)
                            return createOrderResult
                        } else 
                            return res
                    }))
                }
                else if (createOrderResult.Status && !createOrderResult.IsWalletPayment) {
                    isCommissionPayment = false
                    dispatch({ type: ActionType.ProceedOnlinePaymentRequest })
                    return buyService.paymentDetails(i18n.language)
                }
                else {
                    return of({ ...createOrderResult })
                }
            })
        ).subscribe((res => {
            if (isCommissionPayment) {
                dispatch({ type: ActionType.ProceedCommissionPaymentResponse, data: { result: res, paymentMethod: 'commission' } })
            }
            else {
                dispatch({ type: ActionType.ProceedOnlinePaymentResponse, data: res })
            }
        }))
    }
    const proceedOnlinePayment = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderAmount: number) => {
        dispatch({ type: ActionType.ProceedOnlinePaymentRequest })
        buyService.orderCheckout(0, orderAmount).pipe(
            switchMap(createOrderResult => {
                if (createOrderResult.Status && !createOrderResult.IsWalletPayment) {
                    return buyService.paymentDetails(i18n.language)
                } else {
                    return of({ ...createOrderResult })
                }
            })
        ).subscribe((res => {
            dispatch({ type: ActionType.ProceedOnlinePaymentResponse, data: res })
        }))
    }

    const proceedZeroPayment = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.ProceedZeroPaymentRequest })
        buyService.orderCheckout(0, 0).pipe(
            switchMap(createOrderResult => {
                if (createOrderResult.Status) {
                    return buyService.zeroPayment()
                } else {
                    return of({ ...createOrderResult })
                }
            })
        ).subscribe((res => {
            dispatch({ type: ActionType.ProceedZeroPaymentResponse, data: { result: res, paymentMethod: 'zero' } })
        }))
    }

    const proceedMixedPayment = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderAmount: number) => {
        const { selectedCountry, userData } = getState().user
        trackWalletDebit(userData.AXCode[0], selectedCountry as string)
        dispatch({ type: ActionType.ProceedOnlinePaymentRequest })
        buyService.orderCheckout(getState().buy.multiPaymentViewModel?.EligibleBalance, getState().buy.multiPaymentViewModel?.AmountPayable).pipe(
            switchMap(createOrderResult => {
                if (createOrderResult.Status && !createOrderResult.IsWalletPayment) {
                    return buyService.paymentDetails(i18n.language)
                } else {
                    return of({ ...createOrderResult })
                }
            })
        ).subscribe((res => {
            dispatch({ type: ActionType.ProceedOnlinePaymentResponse, data: res })
        }))
    }

    const offlinePayment = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderNumber: string, paymentType: string) => {
        dispatch({ type: ActionType.CompleteOfflinePaymentRequest })
        buyService.offlinePayment(orderNumber, paymentType).subscribe((res => {
            dispatch({ type: ActionType.CompleteOfflinePaymentResponse, data: { result: res, paymentMethod: paymentType } })
        }))
    }

    const proceedMBEPayment = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.ProceedMBEPaymentRequest })
        buyService.mbeOrder().subscribe(res => {
            dispatch({ type: ActionType.ProceedMBEPaymentResponse, data: { result: res, paymentMethod: 'MBE'} })
        })
    }

    const removeCards = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (adyenRemoveCardsRequest: any) => {
        return buyService.removeCards(adyenRemoveCardsRequest);
    }

    const clearProceedError = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.ClearProceedCommissionError })
    }

    const getOrderStatus = (orderNumber: string) => {
        return buyService.orderStatus(orderNumber).pipe(
            delay(STATUS_REQUEST_DELAY),
            repeat(),
            takeWhile(((res: any) => res.some((val: any) => val.Status === 'Pending')), true),
            takeUntil(timer(STATUS_REQUEST_TIMER))
        )
    }

    const getOrderComplete = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderNumber: string, paymentMode: string) => {
        dispatch({ type: ActionType.OrderStatusRequest, data: paymentMode })
        getOrderStatus(orderNumber).pipe(
            map(orderStatusRes => {
                return orderStatusRes
            })
        ).subscribe({
            next: (data) => {
                dispatch({ type: ActionType.OrderStatusResponse, data: data })
            },
            complete: () => {
                dispatch({ type: ActionType.OrderCompleteRequest })
                buyService.orderConfirmation(orderNumber, paymentMode).subscribe(data => {
                    dispatch({ type: ActionType.OrderCompleteResponse, data: data })
                })
            }
        })
    }

    const getMBEOrderStatus = (orderNumber: string) => {
        return buyService.mbeOrderStatus(orderNumber).pipe(
            delay(STATUS_REQUEST_DELAY),
            repeat(),
            takeWhile(((res: any) => res.some((val: any) => val.Status === 'Pending')), true),
            takeUntil(timer(STATUS_REQUEST_TIMER))
        )
    }

    const getMBEOrderComplete = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderNumber: string) => {
        getMBEOrderStatus(orderNumber).pipe(
            map(orderStatusRes => {
                return orderStatusRes
            })
        ).subscribe({
            next: (data) => {
                dispatch({ type: ActionType.OrderStatusResponse, data })
            },
            complete: () => {
                dispatch({ type: ActionType.OrderCompleteRequest })
                buyService.mbeOrderConfirmation(orderNumber).subscribe(data => {
                    dispatch({
                        type: ActionType.OrderCompleteResponse, data: {
                            ...data,
                            Order: {
                                ...data.Order,
                                OrderItemList: data.Order.OrderLines
                                    .map((item: any) => ({
                                        ...item,
                                        OrderNumber: data.Order.OrderNumber,
                                        Description: item.ProductName,
                                        Status: item.Status || data.Order.Status
                                    }))
                            }
                        }
                    })
                })
            }
        })
    }

    const cleanOrderCompleteSummary = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.OrderCompleteCleanSummary })
    }

    return {
        getCart,
        addToCart,
        updateCart,
        removeFromCart,
        reviewCart,
        updateDeliveryAddress,
        closeDeliveryAddress,
        openDeliveryAddress,
        proceedCommissionPayment,
        proceedOnlinePayment,
        clearProceedError,
        getOrderComplete,
        offlinePayment,
        cleanCartError,
        proceedZeroPayment,
        proceedMixedPayment,
        removeCards,
        proceedPayment,
        proceedMBEPayment,
        getMBEOrderComplete,
        cleanOrderCompleteSummary
    };
};

export default initActions;