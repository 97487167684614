import { IBuyAndPrintService } from "@src/services/BuyAndPrintService";
import { IAction, ActionType } from "../types";

const initReducer = function (topUpService:IBuyAndPrintService) {
    const INITIAL_STATE = {
      qtys: ({} as any),
      inStockQtys: ({} as any),
      inStockFetchInProgress: (undefined as any),
      inStockVoucherFetchInProgress: (undefined as any),
      inStockRequestResult: (undefined as any),
      inStockVoucherRequestResult: (undefined as any),
      multiEpinPrintResult: (undefined as any),
      multiDigitalpinPrintResult: (undefined as any),
      buyPrintInProgress: false,
      buyPrintVoucherInProgress: false,
      buyPrintRequestResult: (undefined as any),
      buyPrintVoucherRequestResult: (undefined as any),
      inStockBuyPrintInProgress: (undefined as any),
      inStockBuyPrintMiddleRes: (undefined as any),
      inStockBuyPrintVoucherMiddleRes: (undefined as any),
      inStockBuyPrintRequestResult: (undefined as any),
      buyPrintInProgressReprint: false,
      buyPrintVoucherInProgressReprint: false,
      inStockBuyPrintInProgressReprint: false,
      inStockBuyPrintVoucherInProgress: (undefined as any),
      inStockBuyPrintVoucherRequestResult: (undefined as any),
      inStockBuyPrintVoucherInProgressReprint: false,
      inStockRequestModel: (undefined as any)
    };

    let reducersByActions: any = {}
    
    reducersByActions[ActionType.setBuyPrintQuantity] =  (state: typeof INITIAL_STATE, actionData: any) => {
      const newQtys = {...state.qtys}
      if (actionData.qty <= 0) {
        delete newQtys[actionData.productId]
      } else {
        newQtys[actionData.productId] = {
          qty: actionData.qty, 
          name: actionData.name, 
          type: actionData.type, 
          price: actionData.price, 
          tax: actionData.tax,
          isSPV: actionData.isSPV,
          category: actionData.category
        }
      }
      return {
        ...state,
        qtys: newQtys
      }
    }
    
    reducersByActions[ActionType.setBuyPrintInStockQuantity] =  (state: typeof INITIAL_STATE, actionData: any) => {
      const newQtys = {...state.inStockQtys}
      if (actionData.qty <= 0) {
        delete newQtys[actionData.productId]
      } else {
        newQtys[actionData.productId] = {
          qty: actionData.qty, 
          name: actionData.name, 
          type: actionData.type, 
          price: actionData.price, 
          isSPV: actionData.isSPV,
          category: actionData.category
        }
      }
      return {
        ...state,
        inStockQtys: newQtys
      }
    }
    
    reducersByActions[ActionType.cleanQtys] =  (state: typeof INITIAL_STATE, actionData: any) => {
      
      return {
        ...state,
        inStockQtys: {},
        qtys: {}
      }
    }
    
    reducersByActions[ActionType.buyPrintInStockRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockFetchInProgress: actionData
      }
    }
    
    reducersByActions[ActionType.buyPrintInStockResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockRequestResult: actionData.res,
        inStockFetchInProgress: {...state.inStockFetchInProgress, ...actionData.correction}
      }
    }
    
    reducersByActions[ActionType.buyPrintInProgress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintInProgress: true
      }
    }

    reducersByActions[ActionType.buyPrintInProgressReprint] = (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintInProgressReprint: true,
      }
    }

    reducersByActions[ActionType.buyPrintVoucherInProgressReprint] = (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintVoucherInProgressReprint: true,
      }
    }
    
    reducersByActions[ActionType.buyPrintMiddleResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintMiddleRes: actionData,
        buyPrintInProgressReprint: false
      }
    }

    reducersByActions[ActionType.buyMultiEpinPrintResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        multiEpinPrintResult: actionData,
        buyPrintInProgress: false,
      }
    }

    reducersByActions[ActionType.buyMultiDigitalpinPrintResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        multiDigitalpinPrintResult: actionData,
        buyPrintInProgress: false
      }
    }
    
    reducersByActions[ActionType.buyPrintRestore] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintMiddleRes: actionData.inStockBuyPrintMiddleRes,
        buyPrintInProgress: true,
        inStockFetchInProgress: actionData.inStockFetchInProgress,
        inStockRequestResult: actionData.inStockRequestResult,
        qtys: actionData.qtys
      }
    }
    
    reducersByActions[ActionType.buyPrintResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintRequestResult: actionData,
        buyPrintInProgressReprint: false
      }
    }
    
    reducersByActions[ActionType.inStockBuyPrintInProgress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintInProgress: actionData
      }
    }

    reducersByActions[ActionType.inStockBuyPrintConfirm] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockRequestModel: actionData
      }
    }

    reducersByActions[ActionType.inStockBuyPrintCancel] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockRequestModel: undefined
      }
    }
    
    reducersByActions[ActionType.inStockBuyPrintResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintRequestResult: actionData,
        inStockBuyPrintInProgressReprint: false
      }
    }

    reducersByActions[ActionType.inStockBuyPrintInProgressReprint] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintInProgressReprint: true,
      }
    }
    
    reducersByActions[ActionType.finishBuyAndPrintTransaction] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockFetchInProgress: false,
        inStockRequestResult: undefined,
        buyPrintInProgress: false,
        buyPrintRequestResult: undefined,
        inStockBuyPrintMiddleRes: undefined,
        buyPrintInProgressReprint: false,
        multiEpinPrintResult: undefined
      }
    }
    
    reducersByActions[ActionType.finishInStockBuyAndPrintTransaction] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintInProgress: undefined,
        inStockBuyPrintRequestResult: undefined
      }
    }

    reducersByActions[ActionType.buyPrintError] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintRequestResult: actionData,
        inStockBuyPrintMiddleRes: actionData,
        buyPrintInProgressReprint: false,
        buyPrintInProgress: false,
        inStockRequestResult: undefined,
        inStockFetchInProgress: undefined
      }
    }
    
    reducersByActions[ActionType.Logout] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return INITIAL_STATE
    }

    reducersByActions[ActionType.inStockBuyPrintVoucherInProgress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintVoucherInProgress: actionData
      }
    }
    reducersByActions[ActionType.inStockBuyPrintVoucherResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintVoucherRequestResult: actionData,
        inStockBuyPrintVoucherInProgressReprint: false
      }
    }
    reducersByActions[ActionType.finishInStockBuyAndPrintVoucherTransaction] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintVoucherInProgress: undefined,
        inStockBuyPrintVoucherRequestResult: undefined
      }
    }
    reducersByActions[ActionType.inStockBuyPrintVoucherInProgressReprint] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintVoucherInProgressReprint: true,
      }
    }

    reducersByActions[ActionType.buyPrintVoucherInStockRequest] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockVoucherFetchInProgress: actionData
      }
    }
    reducersByActions[ActionType.buyPrintVoucherInStockResponse] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockVoucherRequestResult: actionData.res,
        inStockVoucherFetchInProgress: {...state.inStockVoucherFetchInProgress, ...actionData.correction}
      }
    }
    reducersByActions[ActionType.finishBuyAndPrintVoucherTransaction] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockVoucherFetchInProgress: false,
        inStockVoucherRequestResult: undefined,
        buyPrintVoucherInProgress: false,
        buyPrintVoucherRequestResult: undefined,
        inStockBuyPrintVoucherMiddleRes: undefined,
        buyPrintVoucherInProgressReprint: false,
        multiDigitalpinPrintResult: undefined,
      }
    }
    reducersByActions[ActionType.buyPrintVoucherInProgress] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintVoucherInProgress: true
      }
    }
    reducersByActions[ActionType.buyPrintVoucherError] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintVoucherRequestResult: actionData,
        inStockBuyPrintVoucherMiddleRes: actionData,
        buyPrintVoucherInProgressReprint: false,
        buyPrintVoucherInProgress: false,
        inStockVoucherRequestResult: undefined,
        inStockVoucherFetchInProgress: undefined
      }
    }
    reducersByActions[ActionType.buyPrintVoucherMiddleResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintVoucherMiddleRes: actionData,
        buyPrintVoucherInProgressReprint: false
      }
    }
    reducersByActions[ActionType.buyPrintVoucherRestore] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        inStockBuyPrintVoucherMiddleRes: actionData.inStockBuyPrintVoucherMiddleRes,
        buyPrintVoucherInProgress: true,
        inStockVoucherFetchInProgress: actionData.inStockVoucherFetchInProgress,
        inStockVoucherRequestResult: actionData.inStockVoucherRequestResult,
        qtys: actionData.qtys
      }
    }
    reducersByActions[ActionType.buyPrintVoucherResult] =  (state: typeof INITIAL_STATE, actionData: any) => {
      return {
        ...state,
        buyPrintVoucherRequestResult: actionData,
        buyPrintVoucherInProgressReprint: false
      }
    }

    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };
    
    return reducer;
  };
  
  export default initReducer;