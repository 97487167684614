import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from "../interceptor"

const BuyAndPrintService = (ajax: ajaxType) => {
    return {
        fetchInStockInfo: (request: any) => {
            return ajax.post(
                `${config.url.API_URL}Stock/AvailabilityCheck`, 
                request, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
            //return of ({"stockAvailability":[{"Country":"DE","ProductName":"EPINCOM_DATA_M.DE","DisplayName":"EPIN DATA M 14.99€","IsEpinBatchingEnabled":false,"Quantity":1,"Type":"BUNDLE","InSufficientStock":false,"OutOfStock":false,"Available":false,"AvailableQty":2}],"isStockAvailable":true})
        },
        fetchWalletInfo : (amount: number) => {
            return ajax.get(
                `${config.url.API_URL}Wallet/AvailabilityCheck/${amount}`, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
            //return of ({"OrderNumber":null,"Status":true,"RedirectToOnlinePayment":false,"CommissionMismatch":false,"CreditMismatch":false,"ErrorMessage":null,"CommissionAmount":0.0,"CreditAmount":0.0,"OnlineAmount":5.0,"RePrintEpins":null})
        },
        buyAndPrint: (request: any) => {
            return ajax.post(
                `${config.url.API_URL}Print/Buy`, 
                request,  
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
            //return of ({"OrderNumber":"WO589840","Status":true,"RedirectToOnlinePayment":false,"CommissionMismatch":false,"CreditMismatch":false,"ErrorMessage":null})
        },
        buyAndPrintPayment: (orderNumber: string, amountPayable: string, locale: string) => {
            return ajax.get(
                `${config.url.API_URL}Payment/Print/${orderNumber}/${amountPayable}/${locale}`).pipe(map(res => res.response))
            //return of ({"OrderNumber":"WO589840","Status":true,"RedirectToOnlinePayment":false,"CommissionMismatch":false,"CreditMismatch":false,"ErrorMessage":null})
        },
        buyAndPrintInstock: (request: any) => {
            return ajax.post(
                `${config.url.API_URL}Print/InStock`, 
                request, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
            // return of ({"MultipleEPinModelList":[{"SerialNumber":"1004000457","Datetime":"2021-09-29T11:33:24.6742807Z","DisplayName":"QS Test User","Address":"  CORMEILLES EN PARISIS 95240","Denomination":"05","Epin":"1004 0004 57","EpinId":null,"ItemName":"Product.EPIN05.COM.FR.DisplayName","ExpiryDate":null,"Reprint":null}],"RePrintEpins":[{"Id":null,"EpinId":"1004000457","BatchId":"640606751990000000","SerialNumber":"1004000457","Denomination":"05","Name":"EPIN05.COM.FR","ProductId":"EPIN05.COM.FR","ProductName":"E-recharge 5€ - Commission","Pin":"aevi51ek9qcxTKtDtvDHhvN4kwdLOKRZ2tg84KhLP0A=","Type":"Credit","IsPrinted":true,"PrintedDate":null,"AxCustomerCode":"C012248","AxCountryCode":"FR","AxOrderReference":"WO23598","IsConsumed":false,"AllocatedBy":"C012248","AllocatedTo":null,"DenominationWithSymbol":null,"HasError":false,"FirstPrintedDate":"2021-09-29T11:33:24.6117819Z","AllocatedDate":null,"SalesPrice":0.0,"CommissionRestricted":false,"ApplyType":0,"TopUpStatus":null,"PrintStatus":null,"DisplayName":"Product.EPIN05.COM.FR.DisplayName","CategoryId":"2","CategoryName":"Master Epins","CategoryHelpText":null,"IsOpen":true,"CategoryIsActive":true,"CategorySequence":2,"PrintBatchId":"e998b61d-9b4f-4b30-8759-b9f6e1f9497e","EpinType":null,"ExpiryDate":null,"IsDisplayByName":false,"IsDisplayByFaceValue":true,"IsRoundOffEnabled":false,"Reprint":true,"MSISDN":null,"ActivatedDate":null,"PaymentMode":[],"ProductCategoryType":"Epin","ConsumedErrorCode":null,"SelectedQuantity":0,"TransactionDoneBy":"","IsEpinLocked":false,"IsDirectTopUp":false}],"RemainingInstock":[{"ProductId":"EPIN05.COM.FR","Quantity":5,"Name":"E-recharge 5€ - Commission","Type":null}]})
        },
        buyAndPrintStockReprint: (request: any) => {
            return ajax.post(`${config.url.API_URL}Print/Stock/Reprint`,
                request, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },
        buyAndPrintVoucher: (request: any) => {
            return ajax.post(
                `${config.url.API_URL}Print/DigitalPin`, 
                request,  
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        }
    }
}

export type IBuyAndPrintService = ReturnType<typeof BuyAndPrintService>

export default BuyAndPrintService