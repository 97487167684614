import ReactGA from 'react-ga4'
import { History } from 'history'
import config from '../../env'
import initActions from './actions';
import initReducer from './reducer';

ReactGA.initialize([
  {
    trackingId: config.ga.TRACKING_ID,
    gaOptions: {
      siteSpeedSampleRate: config.ga.SITE_SPEED_SAMPLE_RATE
    }
  }
])

const configureAnalyticsModule = (history: History) => {
  const actions = initActions(history);
  const reducer = initReducer(ReactGA);

  return { actions, reducer };
};

export type AnalyticsReducer = ReturnType<typeof initReducer>
export type AnalyticsActions = ReturnType<typeof initActions>

export default configureAnalyticsModule;