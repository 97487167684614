import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';


export interface KpiReportState {
  mtdInProgress: boolean
  mtdData: any,
  months: any,
  loading: boolean,
  ga: any,
  revenueSummary: any,
  ntopupSummary: any,
  reportConfig: any,
  pbaSummary: any,
  baSummary: any,
}

const configureKpiReportModule = (services: IServices) => {
  const actions = initActions(services.kpiReportService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type KpiReportReducer = ReturnType<typeof initReducer>
export type KpiReportActions = ReturnType<typeof initActions>

export default configureKpiReportModule;