import { IStorageService } from "@src/services/StorageService";
import moment from 'moment';
import { IAction, ActionType } from "../types";

export enum InvoiceType {
    spv = 'SPV',
    mpv = 'MPV'
}

export interface SuperInvoiceFilter {
    dateFrom?: string;
    toDate?: string;
    type?: InvoiceType | string;
}

const INITIAL_STATE = {
    isFetchingHistory: false,
    invoiceTotal: 0,
    invoiceHistory: ([] as any),
    filters: {
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        toDate: moment().endOf('month').format('YYYY-MM-DD'),
        type: InvoiceType.spv
    } as SuperInvoiceFilter
}

export type SuperInvoiceState = typeof INITIAL_STATE

export default function initReducer() {   

    let reducersByActions: any = {}

    reducersByActions[ActionType.SuperInvoiceUpdateFilter] = (state: SuperInvoiceState, actionData: SuperInvoiceFilter): SuperInvoiceState => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.SuperInvoiceRequest] = (state: SuperInvoiceState): SuperInvoiceState => (
        {
            ...state,
            isFetchingHistory: true
        }
    )

    reducersByActions[ActionType.SuperInvoiceResponse] = (state: SuperInvoiceState, actionData: {InvoiceTotal: number, Invoices: []} | undefined): SuperInvoiceState => (
        {
            ...state,
            isFetchingHistory: false,
            invoiceHistory: actionData ? [...actionData.Invoices] : [],
            invoiceTotal: actionData ? actionData.InvoiceTotal : 0
        }
    )

    reducersByActions[ActionType.CleanSuperInvoice] = (state: SuperInvoiceState): SuperInvoiceState => (
        {
            ...state,
            invoiceHistory: [],
            filters: { ...INITIAL_STATE.filters }            
        }
    )

    reducersByActions[ActionType.Logout] = (state: SuperInvoiceState): SuperInvoiceState => {
        return INITIAL_STATE
    }

    const reducer = (state = INITIAL_STATE, action: IAction): SuperInvoiceState => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    }


    return reducer;
}