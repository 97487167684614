import { IItemService } from "@src/services/ItemService";
import { IAction, ActionType, MenuItem } from "../types";

const initReducer = function (userService:IItemService) {
    const INITIAL_STATE = {
      fetchInProgress: false,
      itemList: (undefined as any),
      noSubcategory: (undefined as any),
      offers: (undefined as any),
      qtys: (undefined as any),
      menu: (undefined as Map<string, MenuItem> | undefined),
      menuByUrl: (undefined as Map<string, MenuItem> | undefined),
      filteredItems: (undefined as any),
      filteredKeys: (undefined as any),
      inFilterDialog: (undefined as any),
      searchString: '',
      searchedItems: (undefined as any),
      minQtys: (undefined as any)
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.ItemsDataRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
        itemList: undefined,
        menu: undefined,
        noSubcategory: undefined
      };
    }
    reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
      return INITIAL_STATE
    }
    reducersByActions[ActionType.ItemsDataResponse] =  (state: any, actionData: any) => {
      if (actionData) {
        return {
          ...state,
          fetchInProgress: false,
          itemList: actionData.data,
          noSubcategory: actionData.noSubheader,
          qtys: actionData.qtys,
          menu: actionData.menu,
          offers: actionData.offers,
          filteredItems: actionData.filteredItems,
          searchedItems: actionData.searchedItems,
          menuByUrl: actionData.menuByUrl,
          minQtys: actionData.minQtys
        };
      } else {
        return {
          fetchInProgress: false,
          itemList: undefined,
          noSubcategory: undefined,
          qtys: undefined
        };
      }
    }

    reducersByActions[ActionType.FilterItems] =  (state: any, actionData: any) => {
      return {
        ...state,
        filteredItems: actionData.filteredItems,
        filteredKeys: actionData.filteredKeys
      }
    }

    reducersByActions[ActionType.InFilterDialog] =  (state: any, actionData: any) => {
      return {
        ...state,
        inFilterDialog: true
      };
    }

    reducersByActions[ActionType.CloseFilterDialog] =  (state: any, actionData: any) => {
      return {
        ...state,
        inFilterDialog: undefined
      };
    }

    reducersByActions[ActionType.ApplySearch] =  (state: any, actionData: any) => {
      return {
        ...state,
        searchedItems: actionData.searchedItems,
        searchString: actionData.searchString
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      // console.log("state: ", state)
      // console.log("action: ", action)
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;