import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";
import { IMigrationService } from "src/services/Migration";

const initActions = (MigrationService: IMigrationService) => {
  const getSessionID = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.MigrationGetSessionId });
    MigrationService.getSessionId().subscribe((data) => {
      dispatch({ type: ActionType.MigrationGetSessionIdResponse, data });
    });
  };

  const resetSessionId = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.MigrationResetSessionId });
  };

  return {
    getSessionID,
    resetSessionId
  };
};

export default initActions;
