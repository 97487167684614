import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureWalletHistoryModule = (services: IServices) => {
  const actions = initActions(services.walletService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type WalletHistoryReducer = ReturnType<typeof initReducer>
export type WalletHistoryActions = ReturnType<typeof initActions>

export default configureWalletHistoryModule;