import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from '../interceptor'

const PortInResubmissionService = (ajax: ajaxType) => {
    return {
        // getServiceId: () => {
        //     return ajax.get(
        //         `${config.url.API_URL}PostPayPortIn/GetSessionId`,
        //         { "Content-Type": 'text/plain; charset=utf-8' },
        //         "text"
        //     ).pipe(map(res => res.response))
        // },
        // getNetworkOperators: (serviceId: String) => {
        //     return ajax.get(`${config.url.API_URL}PostPayPortIn/NetworkOperators/${serviceId}/en-de`, {
        //         "Content-Type": "application/json"
        //     }).pipe(map(res => res.response))
        // }
        getContractDetails: (portInresubmitmssidn: string, serviceId: string | null, locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ContractDetails/${portInresubmitmssidn}/${serviceId}/${locale}`)
                .pipe(map(res => res.response))
        },

        submitResubmission: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/ResubmitPortInProcess`, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res.response))
        }
    }
}

export type IPortInResubmitService = ReturnType<typeof PortInResubmissionService>
export default PortInResubmissionService