import WalletService from './WalletService'
import TopUpService from './TopUpService'
import ForgottenPasswordService from './ForgottenPasswordService'
import ItemService from './ItemService'
import LocalizationService from './LocalizationService'
import StorageService from './StorageService'
import UserService from './UserService'
import TopUpHistoryService from './TopUpHistoryService'
import AssignBundleHistoryService from './AssignBundleHistoryService'
import BuyAndPrintService from './BuyAndPrintService'
import PrintHistoryService from './PrintHistoryService'
import BuyService from './BuyService'
import AlertsService from './AlertsService'
import Interceptor from './interceptor'
import CommissionService from './CommissionService'
import OnlinePaymentService from './OnlinePaymentService'
import TermsAndConditionsService from './TermsAndConditionsService'
import DashboardTilesService from './DashboardTilesService'
import MarketingPopupService from './MarketingPopupService'
import StandaloneTopUpService from './StandaloneTopUpService'
import MyOrdersService from './MyOrdersService'
import SuperInvoiceService from './SuperInvoiceService'
import TradeOffersService from './TradeOffersServices'
import InvoiceHistoryService from './InvoiceHistoryService'
import DownloadCentreService from './DownloadCentreService'
import PostPayService from './PostPayService'
import KpiReportService from './KpiReportService'
import PortInResubmissionService from './PortInResubmissionService'
import SimRegAssessmentService from './SimRegAssessmentService'
import FaqService from './FaqService'

import SimRegistrationService from './SimRegistration'
import PrepaidPortalService from './PrepaidPortal'
import EmployeeService from './EmployeeService'
import MigrationService from './Migration'
import DocumentRepositoryService from './DocumentRepositoryService'
const configureServices = () => {

  const { ajax, interceptorConnector } = Interceptor()

  const userService = UserService(ajax)
  const storageService = StorageService()
  const topUpService = TopUpService(ajax)
  const topUpHistoryService = TopUpHistoryService(ajax)
  const assignBundleHistoryService = AssignBundleHistoryService(ajax)
  const buyAndPrintService = BuyAndPrintService(ajax)
  const buyService = BuyService(ajax)
  const printHistoryService = PrintHistoryService(ajax)
  const walletService = WalletService(ajax)
  const commissionService = CommissionService(ajax)
  const itemService = ItemService(ajax)
  const localizationService = LocalizationService(ajax)
  const forgottenPasswordService = ForgottenPasswordService(ajax)
  const alertsService = AlertsService(ajax)
  const onlinePaymentService = OnlinePaymentService()
  const termsAndConditionsService = TermsAndConditionsService(ajax)
  const dashboardTilesService = DashboardTilesService(ajax)
  const marketingPopupService = MarketingPopupService(ajax)
  const standaloneTopUpService = StandaloneTopUpService(ajax)
  const myOrdersService = MyOrdersService(ajax)
  const superInvoiceService = SuperInvoiceService(ajax)
  const tradeOffersService = TradeOffersService(ajax)  
  const invoiceHistoryService = InvoiceHistoryService(ajax)
  const downloadCentreService = DownloadCentreService(ajax)
  const postPayService = PostPayService(ajax)
  const kpiReportService = KpiReportService(ajax)
  const portInResubmissionService = PortInResubmissionService(ajax)
  const simRegAssessmentService = SimRegAssessmentService(ajax)
  const faqService = FaqService(ajax)
  const employeeService = EmployeeService(ajax)
  const simRegistrationService = SimRegistrationService(ajax)
  const prepaidPortalService = PrepaidPortalService(ajax)
  const migrationService = MigrationService(ajax)
  const documentRepositoryService = DocumentRepositoryService(ajax)
  
  return { 
    userService, 
    topUpService, 
    topUpHistoryService, 
    assignBundleHistoryService,
    walletService, 
    commissionService,
    buyService,
    itemService, 
    printHistoryService,
    storageService, 
    localizationService, 
    forgottenPasswordService,
    buyAndPrintService,
    alertsService,
    interceptorConnector,
    onlinePaymentService,
    termsAndConditionsService,
    dashboardTilesService,
    marketingPopupService,
    standaloneTopUpService,
    myOrdersService,
    superInvoiceService,
    tradeOffersService,
    invoiceHistoryService,
    downloadCentreService,
    postPayService,
    kpiReportService,
    portInResubmissionService,
    faqService,
    simRegAssessmentService,
    simRegistrationService,
    prepaidPortalService,
    employeeService,
    migrationService,
    documentRepositoryService
  }
}

export type IServices = ReturnType<typeof configureServices>

export default configureServices