import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const SuperInvoiceService = (ajax: ajaxType) => {return{
    fetchSuperInvoice: (dateFrom: string | undefined, toDate: string | undefined, type: string | undefined) => {
        return ajax.get(`${config.url.API_URL}Order/Invoices?startdate=${dateFrom}&enddate=${toDate}&type=${type}`)
            .pipe(map(res => res.response))
    }
}}

export type ISuperInvoiceService = ReturnType<typeof SuperInvoiceService>

export default SuperInvoiceService