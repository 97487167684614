import { IAction, ActionType } from "../types";
import { TradeOffersState } from './index'

const initReducer = function () {
    const INITIAL_STATE: TradeOffersState = {
        fetchInProgress: false,
        myOffers: [],
    }

    let reducersByActions: any = {}

    reducersByActions[ActionType.TradeOffersRequest] = (state: TradeOffersState, actionData: any) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }

    reducersByActions[ActionType.TradeOffersResponse] = (state: TradeOffersState, actionData: any) => {
        return {
            ...state,
            myOffers: actionData,
            fetchInProgress: false
        }
    }

    reducersByActions[ActionType.CleanTradeOffers] = (state: TradeOffersState, actionData: any) => {
        return {
            ...state,
            myOffers: []
        }
    }


    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };


    return reducer;
}

export default initReducer