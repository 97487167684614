import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureWalletModule = (services: IServices) => {
  const actions = initActions(services.walletService);
  const reducer = initReducer(services.walletService, services.storageService);

  return { actions, reducer };
};

export type WalletReducer = ReturnType<typeof initReducer>
export type WalletActions = ReturnType<typeof initActions>

export default configureWalletModule;