export interface IAction {
    type: ActionType;
    data: any;
}

export enum LoadingStatus {
    notLoaded = 'not loaded',
    loading = 'loading',
    loaded = 'loaded'
}

export type MenuItem = {
    title: string,
    level: number,
    isEnabled: boolean,
    UserLevel: string,
    target: string,
    UserAction: string,
    url: string
}

export enum ActionType {
    LoginRequest = "@@user/login_request",
    LoginResponse = "@@user/login_response",
    LoginError = "@@user/login_Error",
    Logout = "@@user/logout",

    TermsAndConditionsResponse = "@@user/terms_and_conditions_response",
    TermsAndConditionsSendEmailRequest = "@@user/terms_and_conditions_send_request",
    TermsAndConditionsSendEmailResponse = "@@user/terms_and_conditions_send_email_response",
    AcceptTermsRequest = "@@user/terms_and_conditions_accept_request",
    AcceptTermsResponse = "@@user/terms_and_conditions_accept_response",

    MarketingPopupResponse = "@@user/marketing_popup_response",
    MarketingPopupSkip = "@@user/marketing_popup_skip",

    PostLoginResponse = "@@user/post_login_response",

    SetIdError = "@@user/id_error",
    SetPasswordError = "@@user/password_error",

    ResetPasswordRequest = "@@user/reset_password_request",
    ResetPasswordResponse = "@@user/reset_password_response",
    ResetPasswordError = "@@user/reset_password_error",
    CleanErorr = "@@user/clean_erorr",
    ChangePasswordRequest = "@@user/change_password_request",
    ChangePasswordResponse = "@@user/change_password_response",
    CountriesRequest = "@@user/countries_request",
    CountriesResponse = "@@user/countries_response",
    CountrySelect = "@@user/countriy_select",

    CountryConfigurationRequest = "@@user/country_configuration_request",
    CountryConfigurationResponse = "@@user/country_configuration_response",

    PaymentLogo = "@@user/payment_logo",

    UserProfileRequest = "@@user/user_profile_request",
    UserProfileResponse = "@@user/user_profile_response",
    UpdateUserProfileRequest = "@@user/update_user_profile_request",
    UpdateUserProfileRequestResponse = "@@user/update_user_profile_response",
    CleanUserProfile = "@@user/clean_user_profile",
    ForgottenPasswordDataRequest = "@@forgot_password/forgotten_password_request",
    ForgottenPasswordDataResponse = "@@forgot_password/forgotten_password_response",
    ForgottenCleanError = "@@forgot_password/clean_erorr",

    UpdatePasswordRequest = "@@forgot_password/update_password_request",
    UpdatePasswordResponse = "@@forgot_password/update_password_response",

    SetNewPasswordError = "@@forgot_password/new_password_error",
    SetRepeatNewPasswordError = "@@forgot_password/repeat_new_password_request_error",
    SetNewPasswordRequestError = "@@forgot_password/new_password_request_error",
    SetForgottenPasswordDataRequestError = "@@forgot_password/data_request_error",
    SetPasswordsShouldBeEqualError = "@@forgot_password/set_passwords_should_be_equal_error",
    SetPasswordFormatError = "@@forgot_password/set_passwords_format_error",

    MsisdnDataRequest = "@@topup/fetch_client_data_request",
    MsisdnDataResponse = "@@topup/fetch_client_data_response",
    MsisdnDataError = "@@topup/fetch_client_data_error",
    IccidData = "@@topup/fetch_iccid_data",
    DirectTopUpType = "@@topup/direct_topup_type",
    ApplyTopUpRequest = "@@topup/apply_topup_request",
    ApplyTopUpResponse = "@@topup/apply_topup_response",
    FinishTopUpTransaction = "@@topup/finish_transaction",
    TopUpCleanErorr = "@@topup/clean_erorr",
    CleanClientData = "@@topup/clean_client_data",
    ApplyVoucherStart = "@@topup/apply_voucher_start",
    ApplyVoucherRequest = "@@topup/apply_voucher_request",
    ApplyVoucherResponse = "@@topup/apply_voucher_response",
    FinishVoucherTransaction = "@@topup/finish_voucher_transaction",

    InConfirmationMsisdn = "@@topup/in_confirmation_msisdn",
    ConfirmMsisdn = "@@topup/confirm_msisdn",
    CancelConfirmationMsisdn = "@@topup/cancel_confirmation_msisdn",
    CloseAlertBundle = "@@topup/close_alert_bundle",
    InAlertBundle = "@@topup/in_alert_bundle",
    AssignBundleResponse = "@@topup/assign_bundle_response",
    AssignBundleRequest = "@@topup/assign_bundle_request",
    FinishAssignBundleTransaction = "@@topup/finish_assign_bundle_transaction",
    AssignBundleStart = "@@topup/assign_bundle_start",

    InFilterDialog = "@@topup/in_filter_dialog",
    ApplyFilter = "@@topup/apply_filter",
    CloseFilterDialog = "@@topup/close_filter",
    FilterItems = "@@topUp/filter_items",

    ApplySearch = "@@topUp/apply_search",

    InTopUpVoucher = "@@topUp/in_topup_voucher",
    CloseTopUpVoucher = "@@topUp/close_topup_voucher",
    TopUpVoucherNumberRequest = "@@topUp/voucher_number_request",
    TopUpVoucherNumberResponse = "@@topUp/voucher_number_response",
    TopUpVoucherNumberFinish = "@@topUp/voucher_number_finish",

    WalletDataRequest = "@@wallet/fetch_client_data_request",
    WalletDataResponse = "@@wallet/fetch_client_data_response",

    WalletCreditTilesRequest = "@@wallet/fetch_credit_tiles_request",
    WalletCreditTilesResponse = "@@wallet/fetch_credit_tiles_response",

    AddWalletDetailsRequest = "@@wallet/add_wallet_details_request",
    AddWalletDetailsResponse = "@@wallet/add_wallet_details_response",

    CreditCompleteRequest = "@@wallet/credit_complete_request",
    CreditCompleteResponse = "@@wallet/credit_complete_response",

    ItemsDataRequest = "@@topUp/fetch_top_up_data_request",
    ItemsDataResponse = "@@topUp/fetch_top_up_data_response",

    TopHistoryRequest = "@@topUpHistory/fetch_top_up_history_request",
    TopHistoryResponse = "@@topUpHistory/fetch_top_up_history_response",
    TopHistoryStartDownload = "@@topUpHistory/download_start_top_up_history",
    TopHistoryEndDownload = "@@topUpHistory/download_end_top_up_history",
    TopHistoryClean = "@@topUpHistory/clean_top_up_history",

    CommissionDataRequest = "@@commission/fetch_client_data_request",
    CommissionDataResponse = "@@commission/fetch_client_data_response",
    CommissionSetFilters = "@@commission/set_filters",
    CommissionSummaryRequest = "@@commission/fetch_commission_sum_request",
    CommissionSummaryResponse = "@@commission/fetch_commission_sum_response",

    BundleHistoryRequest = "@@bundleHistory/fetch_bundle_history_request",
    BundleHistoryResponse = "@@bundleHistory/fetch_bundle_history_response",
    BundleHistoryStartDownload = "@@bundleHistory/download_start_bundle_history",
    BundleHistoryEndDownload = "@@bundleHistory/download_end_bundle_history",
    BundleHistoryClean = "@@bundleHistory/clean_bundle_history",

    setBuyPrintQuantity = "@@buyAndPrint/set_quantity",
    setBuyPrintInStockQuantity = "@@buyAndPrint/set_instock-quantity",
    cleanQtys = "@@buyAndPrint/clean_qtys",
    buyPrintInStockRequest = "@@buyAndPrint/instock_request",
    buyPrintVoucherInStockRequest = "@@buyAndPrint/voucher_instock_request",
    buyPrintInStockResponse = "@@buyAndPrint/instock_response",
    buyPrintVoucherInStockResponse = "@@buyAndPrint/voucher_instock_response",
    buyPrintInProgress = "@@buyAndPrint/buy_in_progress",
    buyPrintVoucherInProgress = "@@buyAndPrint/buy_voucher_in_progress",
    buyPrintInProgressReprint = "@@buyAndPrint/buy_in_progress_reprint",
    buyPrintVoucherInProgressReprint = "@@buyAndPrint/buy_voucher_in_progress_reprint",
    buyPrintResult = "@@buyAndPrint/buy_result",
    buyPrintVoucherResult = "@@buyAndPrint/buy_voucher_result",
    buyPrintRestore = "@@buyAndPrint/buy_result_restore",
    buyPrintVoucherRestore = "@@buyAndPrint/buy_voucher_result_restore",
    buyPrintMiddleResult = "@@buyAndPrint/buy_middle_result",
    buyPrintVoucherMiddleResult = "@@buyAndPrint/buy_voucher_middle_result",
    buyMultiEpinPrintResult = "@@buyAndPrint/buy_multi_epin",
    buyMultiDigitalpinPrintResult = "@@buyAndPrint/buy_multi_digitalpin",
    buyPrintError = "@@buyAndPrint/buy_error",
    buyPrintVoucherError = "@@buyAndPrint/buy_voucher_error",
    inStockBuyPrintInProgress = "@@buyAndPrint/instock_buy_in_progress",
    inStockBuyPrintConfirm = "@@buyAndPrint/instock_buy_confirm",
    inStockBuyPrintCancel = "@@buyAndPrint/instock_buy_cancel",
    inStockBuyPrintInProgressReprint = "@@buyAndPrint/instock_buy_in_progress_reprint",
    inStockBuyPrintResult = "@@buyAndPrint/instock_buy_result",
    finishBuyAndPrintTransaction = "@@buyAndPrint/finish_buy_and_print_transaction",
    finishBuyAndPrintVoucherTransaction = "@@buyAndPrint/finish_buy_and_print_voucher_transaction",
    finishInStockBuyAndPrintTransaction = "@@buyAndPrint/finish_instock_buy_and_print_transaction",
    inStockBuyPrintVoucherInProgress = "@@buyAndPrint/instock_voucher_buy_in_progress",
    inStockBuyPrintVoucherResult = "@@buyAndPrint/instock_voucher_buy_result",
    inStockBuyPrintVoucherInProgressReprint = "@@buyAndPrint/instock_buy_voucher_in_progress_reprint",
    finishInStockBuyAndPrintVoucherTransaction = "@@buyAndPrint/finish_instock_buy_and_print_voucher_transaction",


    PrintHistoryRequest = "@@printHistory/fetch_print_history_request",
    PrintHistoryResponse = "@@printHistory/fetch_print_history_response",
    PrintHistoryPrintOutRequest = "@@printHistory/fetch_print_history_print_out_request",
    PrintHistoryPrintOutResponse = "@@printHistory/fetch_print_history_print_out_response",
    CleanPrintHistory = "@@printHistory/clean_print_history",
    CleanPrintData = "@@printHistory/clean_print_data",
    PrintHistoryReprintEpinRequest = "@@printHistory/reprint_epin_request",
    PrintHistoryReprintEpinRequestСompletion = "@@printHistory/reprint_epin_request_complition",
    AddRecordEpinsPrintHistory = "@@printHistory/add_record_epins_print_history",
    RemoveRecordEpinsPrintHistory = "@@printHistory/remove_record_epins_print_history",

    fetchCartRequest = "@@buy/fetch_cart_request",
    fetchCartResponse = "@@buy/fetch_cart_response",
    fetchCartError = "@@buy/fetch_cart_error",
    cleanCartError = "@@buy/clean_cart_error",
    fetchCartReviewRequest = "@@buy/fetch_cart_review_request",
    fetchCartReviewResponse = "@@buy/fetch_cart_review_response",


    AddCartRequest = "@@buy/add_cart_request",
    AddCartResponse = "@@buy/add_cart_response",
    AddCartError = "@@buy/add_cart_error",


    UpdateDeliveryAddress = "@@buy/update_delivery_address",
    UpdateDeliveryAddressResponse = "@@buy/update_delivery_address_response",
    OpenDeliveryAddress = "@@buy/open_delivery_address",
    CloseDeliveryAddress = "@@buy/close_delivery_address",

    ProceedCommissionPaymentRequest = "@@buy/proceed_commission_payment_request",
    ProceedCommissionPaymentResponse = "@@buy/proceed_commission_payment_response",
    ClearProceedCommissionError = "@@buy/clear_proceed_commission_error",
    ProceedZeroPaymentRequest = "@@buy/proceed_zero_payment_request",
    ProceedZeroPaymentResponse = "@@buy/proceed_zero_payment_response",
    ProceedOnlinePaymentRequest = "@@buy/proceed_online_payment_request",
    ProceedOnlinePaymentResponse = "@@buy/proceed_online_payment_response",
    CompleteOfflinePaymentRequest = "@@buy/complete_offlien_payment_request",
    CompleteOfflinePaymentResponse = "@@buy/complete_offlien_payment_response",
    ProceedMBEPaymentRequest = "@@buy/proceed_mbe_payment_request",
    ProceedMBEPaymentResponse = "@@buy/proceed_mbe_payment_response",

    OrderConfirmationRequest = "@@buy/order_confirmation_request",
    OrderConfirmationResponse = "@@buy/order_confirmation_response",
    OrderConfirmationError = "@@buy/order_confirmation_error",

    OrderStatusRequest = "@@buy/order_status_request",
    OrderStatusResponse = "@@buy/order_status_response",
    OrderStatusError = "@@buy/order_status_error",

    OrderCompleteRequest = "@@buy/order_complete_request",
    OrderCompleteResponse = "@@buy/order_complete_response",
    OrderCompleteError = "@@buy/order_complete_error",
    OrderCompleteCleanSummary = "@@buy/order_complete_clean_summary",

    setLanguageList = "@@localization/set_language_list",
    setLanguageListRequest = "@@localization/add_language_request",
    setLanguageListResponse = "@@localization/add_language_response",
    removeAllLanguages = "@@localization/fetch_top_up_data_response",
    setLanguage = "@@localization/set_language",

    AlertsDataRequest = "@@alerts/fetch_alerts_request",
    AlertsDataResponse = "@@alerts/fetch_alerts_response",

    FetchTilesRequest = "@@tiles/fetch_tiles_request",
    FetchTilesResponse = "@@tiles/fetch_tiles_response",


    StanaloneMsisdnDataRequest = "@@standaloneTopup/fetch_client_data_request",
    StanaloneMsisdnDataResponse = "@@standaloneTopup/fetch_client_data_response",
    StanaloneMsisdnDataError = "@@standaloneTopup/fetch_client_data_error",
    StanaloneTopUpCleanErorr = "@@standaloneTopup/clean_erorr",
    StanaloneApplyTopUpResponse = "@@standaloneTopup/apply_topup_response",
    StanaloneApplyTopUpRequest = "@@standaloneTopup/apply_topup_request",
    StanaloneApplyTopUpError = "@@standaloneTopup/apply_topup_error",
    StanaloneCleanClientData = "@@standaloneTopup/clean_client_data",
    StandaloneMsisdnDataError = "@@standaloneTopup/fetch_client_data_error",

    MyOrdersGetProductsResponse = "@@myorders/get_products_response",
    MyOrdersCleanData = "@@myorders/clean_data",
    MyOrdersSetFilters = "@@myorders/set_filters",
    MyOrdersGetOrdersRequest = "@@myorders/get_orders_request",
    MyOrdersGetOrdersResponse = "@@myorders/get_orders_response",
    MyOrdersDownloadMyOrdersStart = "@@myorders/download_myorders_start",
    MyOrdersDownloadMyOrdersEnd = "@@myorders/download_myorders_end",

    OrderSummaryRequest = "@@ordersummary/fetch_summary_request",
    OrderSummaryResponse = "@@ordersummary/fetch_summary_response",
    OrderSummaryClear = "@@ordersummary/fetch_summary_clear",

    GAPageview = "@@ga/page_view",

    WalletHistoryUpdateFilter = "@@wallethistory/update_filter",
    WalletSummaryRequest = "@@wallethistory/summary_request",
    WalletSummaryResponse = "@@wallethistory/summary_response",
    WalletHistoryRequest = "@@wallethistory/history_request",
    WalletHistoryResponse = "@@wallethistory/history_response",
    WalletHistoryStartDownload = "@@wallethistory/download_start_wallet_history",
    WalletHistoryEndDownload = "@@wallethistory/download_end_wallet_history",
    CleanWalletHistory = "@@wallethistory/clear_history",
    EmployeeWalletLock = "@@wallethistory/wallet_lock",

    SuperInvoiceUpdateFilter = "@@superinvoice/update_filter",
    SuperInvoiceRequest = "@@superinvoice/history_request",
    SuperInvoiceResponse = "@@superinvoice/history_response",
    CleanSuperInvoice = "@@superinvoice/clear_history",

    TradeOffersRequest = "@@tradeoffers/offer_request",
    TradeOffersResponse = "@@tradeoffers/offer_response",
    CleanTradeOffers = "@@tradeoffers/clean_offers",


    InvoiceHistoryUpdateFilter = "@@invoicehistory/update_filter",
    InvoiceHistoryRequest = "@@invoicehistory/history_request",
    InvoiceHistoryResponse = "@@invoicehistory/history_response",
    CleanInvoiceHistory = "@@invoicehistory/clear_history",

    DownloadCentreHistoryRequest = "@@downloadcentre/history_request",
    DownloadCentreHistoryResponse = "@@downloadcentre/history_response",
    CleanDownloadCentre = "@@downloadcentre/clear_downloads",

    PostPayRestorePortInRequest = "@@postpay/restore_request",
    PostPayProductRequest = "@@postpay/product_request",
    PostPayProductResponse = "@@postpay/product_response",
    PostPayBoltonsRequest = "@@postpay/boltons_request",
    PostPayBoltonsResponse = "@@postpay/boltons_response",
    PostPayDocumentsResponse = "@@postpay/document_response",
    PostPayPromotionsRequest = "@@postpay/promotions_request",
    PostPayPromotionsResponse = "@@postpay/promotions_response",
    PostPayLoadUpdate = "@@postpay/load_update",
    PostPaySetStep = "@@postpay/set_step",
    PostPayCartTotalRequest = "@@postpay/cart_total_request",
    PostPayCartTotalResponse = "@@postpay/cart_total_response",
    PostPayCustProfileCheckRequest = "@@postpay/cust_profile_check_request",
    PostPayCustProfileCheckResponse = "@@postpay/cust_profile_check_response",
    PostPayProductTypeUpdate = "@@postpay/product_type_update",
    PostPayCartClear = "@@postpay/clear_cart",
    FMVNONewLebaraSubmitRequest = "@@postpay/fmvno_new_lebara_submit_request",
    FMVNONewLebaraSubmitResponse = "@@postpay/fmvno_new_lebara_submit_response",
    CreateConsentRequest = "@@postpay/create_consent_request",
    CreateConsentResponse = "@@postpay/create_consent_response",
    ExistingLebaraSubmitRequest = "@@postpay/existing_lebara_submit_request",
    ExistingLebaraSubmitResponse = "@@postpay/existing_lebara_submit_response",
    PostPayValidateSubscriptionRequest = "@@postpay/validate_subscription_request",
    PostPayValidateSubscriptionResponse = "@@postpay/validate_subscription_response",
    PostPayDigitalSignRequest = "@@postpay/digital_sign_request",
    PostPayDigitalSignResponse = "@@postpay/digital_sign_response",
    PostPayEnvelopeStatusRequest = "@@postpay/envelope_status_request",
    PostPayEnvelopeStatusResponse = "@@postpay/envelope_status_response",
    PostPayNetworkOperatorsRequest = "@@postpay/network_operators_request",
    PostPayNetworkOperatorsResponse = "@@postpay/network_operators_response",
    PostPayNonPortInDatesRequest = "@@postpay/non_portin_dates_request",
    PostPayNonPortInDatesResponse = "@@postpay/non_portin_dates_response",
    PostpayCleanCustomerDetailsError = "@@postpay/clean_customer_details_error",
    ValidateMsisdCustomerRequest = "@@postpay/validate_msisdn_customer_request",
    ValidateMsisdCustomerResponse = "@@postpay/validate_msisdn_customer_response",
    PostPayGenerateOTAGRequest = "@@postpay/generate_otag_request",
    PostPayGenerateOTAGResponse = "@@postpay/generate_otag_response",
    PostPaySetRetentionNumber = "@@postpay/set_retention_number", 
    PostPayRetentionStatusRequest = "@@postpay/retention_status_request",
    PostPayRetentionStatusResponse = "@@postpay/retention_status_response",
    CleanPostPay = "@@postpay/clear_post_pay",
    LMVNONewLebaraSubmitRequest = "@@postpay/fmvno_new_lebara_submit_request",
    LMVNONewLebaraSubmitResponse = "@@postpay/fmvno_new_lebara_submit_response",
    PostPayConfigResponse = "@@postpay/config_response",

    KpiReportSummaryRequest = "@@kpireport/summary_request",
    KpiReportSummaryResponse = "@@kpireport/summary_response",
    KPIRequest = "@@kpireport/kpisummary_request",
    KpiGetMonths = "@@kpireport/months", 
    GaSummaryResponse = "@@kpireport/gasummary_response",
    RevenueSummaryResponse = "@@kpireport/revenuesummary_response",
    NtopupSummaryResponse = "@@kpireport/ntopupsummary_response",
    PBSummaryResponse = "@@kpireport/pbsummary_response",
    BASummaryResponse = "@@kpireport/basummary_response",
    ReportConfigResponse = "@@kpireport/reportconfig_response",

    ContractStatusUpdateFilter = "@@contractstatus/update_filter",
    ContractStatusGetListRequest = "@@contractstatus/get_list_request",
    ContractStatusGetListResponse = "@@contractstatus/get_list_response",
    ContractStatusCleanData = "@@contractstatus/clean_data",
    ContractStatusSendSMSRequest = "@@contractstatus/send_sms_request",
    ContractStatusSendSMSResponse = "@@contractstatus/send_sms_response",
    ContractStatusSendEmailRequest = "@@contractstatus/send_email_request",
    ContractStatusSendEmailResponse = "@@contractstatus/send_email_response",
    ContractStatusResendEmailRequest = "@@contractstatus/resend_email_request",
    ContractStatusResendEmailResponse = "@@contractstatus/resend_email_response",
    ContractStatusResignOnscreenRequest = "@@contractstatus/resign_onscreen_request",
    ContractStatusResignOnscreenResponse = "@@contractstatus/resign_onscreen_response",
    ContractStatusRetryRequest = "@@contractstatus/retry_request",
    ContractStatusRetryResponse = "@@contractstatus/retry_response",

    PortInResubmitServiceIdRequest = "@@portinresubmit/serviceid_request",
    PortInResubmitServiceIdResponse = "@@portinresubmit/serviceid_response",
    PortInResubmitNetworkOpRequest = "@@portinresubmit/networkops_request",
    PortInResubmitNetworkOpResponse = "@@portinresubmit/networkops_response",
    PortInResubmitContractDetailsRequest = "@@portinresubmit/contractdetails_request",
    PortInResubmitContractDetailsResponse = "@@portinresubmit/contractdetails_response",
    PortInResubmitContractDetailsReset = "@@portinresubmit/contractdetails_reset",
    PortInResubmitFormSubmitRequest = "@@portinresubmit/formresubmit_request",
    PortInResubmitFormSubmitResponse = "@@portinresubmit/formresubmit_response",

    SimRegAssessmentRequest = "@@simRegAssessment/request",
    SimRegAssessmentQuestionRequest = "@@simRegAssessment/question_request",
    SimRegAssessmentTDDownloadResponse = "@@simRegAssessment/TDDownload_response",
    SimRegAssessmentQuestionResponse = "@@simRegAssessment/questions_response",
    SimRegAssessmentSessionIdResponse = "@@simRegAssessment/sessionid_response",
    SimRegAssessmentResultResponse = "@@simRegAssessment/result_response",
    SimRegAssessmentlogResponse = "@@simRegAssessment/log_response",
    SimRegAssessmentFlagResponse = "@@simRegAssessment/flag_response",
    CleanQuestionnaire = "@@simRegAssessment/clean_questionnaire",

    FaqRequest = "@@faq/faq-request",
    FaqResponse = "@@faq/faq-response",

    SigaSimRegGetDocumentRequest = "@@sigasimreg/getDoc_request",
    SigaSimRegGetDocumentResponse = "@@sigasimreg/getDoc_response",
    SigaSimRegValidatePukRequest = "@@sigasimreg/validatepuk_request",
    SigaSimRegValidatePukResponse = "@@sigasimreg/validatepuk_response",
    SigaSimRegFormSubmitRequest = "@@sigasimreg/formsubmit_request",
    SigaSimRegFormSubmitResponse = "@@sigasimreg/formsubmit_response",
    SigaSimRegResetValidPuk = "@@sigasimreg/validpuk_reset",
    SigaSimRegResetRegister = "@@sigasimreg/register_reset",

    EmployeesGetAllEmployeeRequest = "@@employees/getAllEmp_request",
    EmployeesGetAllEmployeeResponse = "@@employees/getAllEmp_response",
    EmployeesGetEmployeeDetailRequest = "@@employees/getEmpDetail_request",
    EmployeesGetEmployeeDetailResponse = "@@employees/getEmpDetail_response",
    EmployeeChangeStatusRequest = "@@employees/changeStatus_request",
    EmployeeChangeStatusResponse = "@@employees/changeStatus_response",
    EmployeeCreateRequest = "@@employees/create_request",
    EmployeeCreateResponse = "@@employees/create_response",
    EmployeeUpdateRequest = "@@employees/update_request",
    EmployeeUpdateResponse = "@@employees/update_response",
    EmployeeGetPermissionRequest = "@@employees/getPermission_request",
    EmployeeGetPermissionResponse = "@@employees/getPermission_response",
    EmployeeUpdatePermissionRequest = "@@employees/updatePermission_request",
    EmployeeUpdatePermissionResponse = "@@employees/updatePermission_response",
    EmployeeChangeStatusReset = "@@employees/changeStatus_reset",
    EmployeeSuccessCreateDetails = "@@employees/create_success",

    MigrationGetSessionId = "@@migration/getSessionId",
    MigrationGetSessionIdResponse = "@@migration/getSessionId_response",
    MigrationResetSessionId = "@@migration/resetSessionId",
}
