import { IAlertsService } from "@src/services/AlertsService";
import { IAction, ActionType } from "../types";

const initReducer = function (alertsService:IAlertsService) {

    const INITIAL_STATE = {
      fetchInProgress: false,
      alertsData: (undefined as any)
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.AlertsDataRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
      };
    }

    reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
      return INITIAL_STATE
    }

    reducersByActions[ActionType.AlertsDataResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        alertsData: actionData
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;