import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';


export interface TradeOffersState {
    fetchInProgress: boolean
    myOffers: any
}

const configureTradeOffersModule = (services: IServices) => {
  const actions = initActions(services.tradeOffersService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type TradeOffersReducer = ReturnType<typeof initReducer>
export type TradeOffersActions = ReturnType<typeof initActions>

export default configureTradeOffersModule;