import { ITopUpService } from "@src/services/TopUpService";
import { IAction, ActionType } from "../types";

const initReducer = function (topUpService:ITopUpService) {
    const INITIAL_STATE = {
      fetchInProgress: false,
      clientData: (undefined as any),
      oldClientData: (undefined as any),
      number: '',
      oldNumber: '',
      clientDataError: '',
      topUpType:'MSISDN',
      iccidNumber:'',
      iccidPretext:'',

      inProgressTopUp: (undefined as any),
      inProgressTopUpProductName: '',
      topUpResult: (undefined as any),
      inProgressTopUpTypeProduct: (undefined as number|undefined), 

      inProgressPinApply: (undefined as any),
      applyVoucherResult: (undefined as any),

      inProgressBundleAssign: (undefined as any),
      bundleAssignItem: (undefined as any),
      inMsisdnConfirmation: (undefined as any),
      inAlertBundle: (undefined as any),
      assignBundleResult: (undefined as any),

      inFilterDialog: (undefined as any),

      inTopUpVoucher: (undefined as any),
      topUpVoucherNumberInProgress: (undefined as any),
      topUpVoucherNumberResult: (undefined as any)
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.MsisdnDataRequest] =  (state: any, actionData: any) => {
      // if (actionData.number === state.number) {
      //   return state
      // }
      return {
        ...state,
        fetchInProgress: true,
        oldClientData: state.clientData,
        clientData: (undefined as any),
        clientDataError: '',
        number: actionData.number
      };
    }
    reducersByActions[ActionType.MsisdnDataResponse] =  (state: any, actionData: any) => {
      if (actionData.number !== actionData.localeCode + state.number) {
        return state
      }
      if (actionData) {
        return {
          ...state,
          fetchInProgress: false,
          clientData: actionData,
          clientDataError: '',
        };
      } else {
        return {
          ...state,
          fetchInProgress: false,
          clientData: undefined,
          clientDataError: '',
        };
      }
    }

    reducersByActions[ActionType.IccidData] =  (state: any, actionData: any) => {
      return {
        ...state,
        iccidNumber: actionData.number,
        iccidPretext: actionData.pretext,
      };
    }

    reducersByActions[ActionType.DirectTopUpType] =  (state: any, actionData: any) => {
      return {
        ...state,
        topUpType: actionData.type,
        number:'',
        iccidNumber:'',
        iccidPretext:''
      };
    }

    reducersByActions[ActionType.MsisdnDataError] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        clientDataError: actionData.error,
        clientData: undefined
      };
    }
    reducersByActions[ActionType.ApplyTopUpRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        inProgressTopUp: actionData.body,
        inProgressTopUpProductName: actionData.inProgressTopUpName,
        inProgressTopUpTypeProduct: actionData.productType
      };
    }
    reducersByActions[ActionType.ApplyTopUpResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        topUpResult: actionData
      };
    }
    reducersByActions[ActionType.FinishTopUpTransaction] =  (state: any, actionData: any) => {
      return {
        ...state,
        inProgressTopUp: undefined,
        topUpResult: undefined,
        inProgressTopUpTypeProduct: undefined,
        inProgressTopUpProductName: ''
      };
    }

    reducersByActions[ActionType.TopUpCleanErorr] = (state: any, actionData: any) => {
      return {
        ...state,
        clientDataError: ''
      }
    }

    reducersByActions[ActionType.CleanClientData] = (state: any, actionData: any) => {
      return {
        ...state,
        clientData: undefined,
        number: '',
        iccidNumber: ''
      }
    }

    reducersByActions[ActionType.ApplyVoucherStart] =  (state: any, actionData: any) => {
      return {
        ...state,
        inProgressPinApply: actionData.data,
        inProgressTopUpProductName: actionData.inProgressTopUpName
      };
    }
    reducersByActions[ActionType.ApplyVoucherResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        applyVoucherResult: actionData
      };
    }
    reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
      return INITIAL_STATE
    }
    reducersByActions[ActionType.FinishVoucherTransaction] =  (state: any, actionData: any) => {
      return {
        ...state,
        inProgressPinApply: undefined,
        applyVoucherResult: undefined,
        inProgressTopUpProductName: ''
      };
    }

    reducersByActions[ActionType.AssignBundleStart] =  (state: any, actionData: any) => {
      return {
        ...state,
        bundleAssignItem: actionData
      };
    }

    reducersByActions[ActionType.InConfirmationMsisdn] =  (state: any, actionData: any) => {
      return {
        ...state,
        inMsisdnConfirmation: true      
      };
    }

    reducersByActions[ActionType.CancelConfirmationMsisdn] =  (state: any, actionData: any) => {
      return {
        ...state,
        inMsisdnConfirmation: undefined,
        bundleAssignItem : undefined
      };
    }

    reducersByActions[ActionType.InAlertBundle] =  (state: any, actionData: any) => {
      return {
        ...state,
        inMsisdnConfirmation: undefined,
        inAlertBundle: true
      };
    }

    reducersByActions[ActionType.CloseAlertBundle] =  (state: any, actionData: any) => {
      return {
        ...state,
        inAlertBundle: undefined,
        bundleAssignItem: undefined
      };
    }

    reducersByActions[ActionType.AssignBundleResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        assignBundleResult: actionData
      };
    }

    reducersByActions[ActionType.AssignBundleRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        inAlertBundle: undefined,
        inMsisdnConfirmation: undefined,
        inProgressBundleAssign: true
      };
    }

    reducersByActions[ActionType.FinishAssignBundleTransaction] =  (state: any, actionData: any) => {
      return {
        ...state,
        inProgressBundleAssign: undefined,
        assignBundleResult: undefined
      };
    } 

    reducersByActions[ActionType.InTopUpVoucher] =  (state: any, actionData: any) => {
      return {
        ...state,
        inTopUpVoucher: true,
        topUpVoucherNumberResult: undefined
      };
    } 

    reducersByActions[ActionType.CloseTopUpVoucher] =  (state: any, actionData: any) => {
      return {
        ...state,
        inTopUpVoucher: undefined
      };
    }

    reducersByActions[ActionType.TopUpVoucherNumberRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        inTopUpVoucher: undefined,
        topUpVoucherNumberInProgress: true
      };
    }

    reducersByActions[ActionType.TopUpVoucherNumberResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        topUpVoucherNumberInProgress: undefined,
        topUpVoucherNumberResult: actionData,
        clientData: actionData?.Status ? (undefined as any) : state.clientData,
        number: actionData?.Status ? '' : state.number,
        oldNumber: actionData?.Status ? state.number : state.oldNumber,
      };
    }

    reducersByActions[ActionType.TopUpVoucherNumberFinish] =  (state: any, actionData: any) => {
      return {
        ...state,
        topUpVoucherNumberResult: undefined
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      // console.log("state: ", state)
      // console.log("action: ", action)
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;