import { IDashboardTilesService } from "@src/services/DashboardTilesService";
import { IAction, ActionType } from "../types";

const initReducer = function (dashboardTilesService:IDashboardTilesService) {

    const INITIAL_STATE = {
      fetchInProgress: false,
      tilesData: (undefined as any)
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.FetchTilesRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
        tilesData: undefined
      };
    }

    reducersByActions[ActionType.FetchTilesResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        tilesData: actionData
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;