import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureTilesModule = (services: IServices) => {
  const actions = initActions(services.dashboardTilesService, services.storageService);
  const reducer = initReducer(services.dashboardTilesService);

  return { actions, reducer };
};

export type DashboardTilesReducer = ReturnType<typeof initReducer>
export type DashboardTilesActions = ReturnType<typeof initActions>

export default configureTilesModule;