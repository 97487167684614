import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const TermsAndConditionsService = (ajax: ajaxType) => {return{
    fetchTermsAndConditions: (locale: string) => {
        return ajax.get(`${config.url.API_URL}Dashboard/TermsAndConditions/${locale}`).pipe(map(res => res.response))
    },
    acceptTermsAndConditions: (locale: string) => {
        return ajax.post(`${config.url.API_URL}Dashboard/TermsAndConditions/Accept/${locale}`).pipe(map(res => res.response))
    },
    downloadTerms: (locale: string) => {
        return ajax.download(`${config.url.API_URL}Dashboard/TermsAndConditions/Download/${locale}`).pipe(map(res => res.response))
    },
    sendEmail: (locale: string) => {
        return ajax.post(`${config.url.API_URL}Dashboard/TermsAndConditions/Email/${locale}`).pipe(map(res => res.response))
    },
    accept: (locale: string) => {
        return ajax.post(`${config.url.API_URL}Dashboard/TermsAndConditions/Accept/${locale}`).pipe(map(res => res.response))
    }
}}

export type ITermsAndConditionsService = ReturnType<typeof TermsAndConditionsService>

export default TermsAndConditionsService