import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureItemModule = (services: IServices) => {
  const actions = initActions(services.itemService, services.storageService);
  const reducer = initReducer(services.itemService);

  return { actions, reducer };
};

export type ItemReducer = ReturnType<typeof initReducer>
export type ItemActions = ReturnType<typeof initActions>

export default configureItemModule;