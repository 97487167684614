import { IStandaloneTopUpService } from "@src/services/StandaloneTopUpService";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";

const initActions = function (standaloneTopUpService: IStandaloneTopUpService) {

  const setMsisdn = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (number: string) => {
    dispatch({ type: ActionType.StanaloneMsisdnDataRequest, data: { number: number } })
    fetchMsisdnInfo(dispatch, 
      getState().user.countryConfiguration.InternationalDialingCode + number, 
      getState().user.countryConfiguration.InternationalDialingCode, 
      getState().user.selectedCountry
    )
  }

  const fetchMsisdnInfo = (dispatch: AppDispatch, number: string, localeCode: string, locale: string) => {
    standaloneTopUpService.validateMsisdn(number, locale)
      .subscribe(data => {
        if (!data) {
          dispatch({
            type: ActionType.StanaloneMsisdnDataError, data: {
              error: 'topup-validnumber',
              number: number
            }
          })
        } else {
          dispatch({  type: ActionType.StanaloneMsisdnDataResponse, data })
        }
      }
      );
  };

  const msisdnValidationError = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (errorCode: string, number: string) => {
    dispatch({
      type: ActionType.StandaloneMsisdnDataError, data: {
        error: errorCode,
        number: number
      }
    })
  }

  const applyTopup = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (pin: string) => {
    const data = {
      MSISDN: getState().standaloneTopUp.number,
      PIN: pin,
      Type: getState().standaloneTopUp.topUpType,
      Country: getState().user.selectedCountry,
      CompanyId: `${getState().user.selectedCountry.toLowerCase()}01`
    }
    dispatch({
      type: ActionType.StanaloneApplyTopUpRequest, data
    })
    standaloneTopUpService.applyVoucher(data).subscribe(res => {
      if(res && res.Status) {
        dispatch({
          type: ActionType.StanaloneApplyTopUpResponse,
          data: res
        })
      } else {
        dispatch({
          type: ActionType.StanaloneApplyTopUpError,
          data: {
            status: 'failed', res
          }
        })
      }       
    })
  }

  const cleanError = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.StanaloneTopUpCleanErorr })
  }

  const cleanClientData = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.StanaloneCleanClientData })
  }

  return {
    setMsisdn,
    cleanError,
    msisdnValidationError,
    cleanClientData,
    applyTopup
  };
};

export default initActions;