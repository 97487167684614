import { dispatch } from "rxjs/internal/observable/pairs";
import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";
import { IKpiReportService } from "src/services/KpiReportService";


const initActions = (KpiReportServices: IKpiReportService) => {

    const getMtdData = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.KpiReportSummaryRequest})
        KpiReportServices.fetchMtdData().subscribe(res=> {
            dispatch({ type: ActionType.KpiReportSummaryResponse, data: res })
        })
    }

    const getReportConfigData = (dispatch: AppDispatch) => () => {
        KpiReportServices.fetchReportConfig().subscribe(res => {
            dispatch({type: ActionType.ReportConfigResponse, data: res})
        })
    }

    const getMonths = (dispatch: AppDispatch) => (kpiName: string) => {
        dispatch({type: ActionType.KPIRequest})
        KpiReportServices.fetchMonths(kpiName).subscribe(res=> {
            dispatch({ type: ActionType.KpiGetMonths, data: res })
        })
    }
    const getGaData = (dispatch: AppDispatch) => (filters: any) => {
        dispatch({type: ActionType.KPIRequest})
        KpiReportServices.getKPIData('ga/Summary',filters)
        .subscribe(res=> {
            dispatch({ type: ActionType.GaSummaryResponse, data: res })
        })
    }
    const getRevenueData = (dispatch: AppDispatch) => (filters: any) => {
        dispatch({type: ActionType.KPIRequest})
        KpiReportServices.getKPIData('revenueshare/Summary',filters)
        .subscribe(res=> {
            dispatch({ type: ActionType.RevenueSummaryResponse, data: res })
        })
    }
    const getNtopupData = (dispatch: AppDispatch) => (date: any) => {
        dispatch({type: ActionType.KPIRequest})
        KpiReportServices.getNtopupSummary(date)
        .subscribe(res=> {
            dispatch({ type: ActionType.NtopupSummaryResponse, data: res })
        })
    }
    const getPBData = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.KPIRequest})
        KpiReportServices.getPBSummary()
        .subscribe(res=> {
            dispatch({ type: ActionType.PBSummaryResponse, data: res })
        })
    }
    const getBAData = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.KPIRequest})
        KpiReportServices.getBASummary()
        .subscribe(res=> {
            dispatch({ type: ActionType.BASummaryResponse, data: res })
        })
    }

    const generalGetRequest =  (dispatch: AppDispatch) => (url: string) => {
        return KpiReportServices.generalGetRequest(url)
    }

    
    // const cleanKpiReport = (dispatch: AppDispatch) => () => {
    //     dispatch({type: ActionType.CleanKpiReport})
    // }

    return {
        getMtdData,
        getMonths,
        getGaData,
        getRevenueData,
        getNtopupData,
        getReportConfigData,
        getPBData,
        getBAData,
        generalGetRequest,
        // cleanKpiReport
    }
}

export default initActions