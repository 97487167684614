import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureSuperInvoiceModule = (services: IServices) => {
  const actions = initActions(services.superInvoiceService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type SuperInvoiceReducer = ReturnType<typeof initReducer>
export type SuperInvoiceActions = ReturnType<typeof initActions>

export default configureSuperInvoiceModule;