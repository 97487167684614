import { IAction, ActionType } from "../types";
import { DownloadCentreState } from './index'

const initReducer = function () {
    const INITIAL_STATE: DownloadCentreState = {
        fetchInProgress: false,
        downloadHistory: [],
    }

    let reducersByActions: any = {}

    reducersByActions[ActionType.DownloadCentreHistoryRequest] = (state: DownloadCentreState) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }

    reducersByActions[ActionType.DownloadCentreHistoryResponse] = (state: DownloadCentreState, actionData: any) => {
        return {
            ...state,
            downloadHistory: actionData,
            fetchInProgress: false
        }
    }

    reducersByActions[ActionType.CleanDownloadCentre] = (state: DownloadCentreState, actionData: any) => {
        return {
            ...state,
            downloadHistory: []
        }
    }


    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };


    return reducer;
}

export default initReducer