import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const CommissionService = (ajax: ajaxType) => {return{
    
    fetchCommissionInfo: (filters: any) => ajax.get(
        `${config.url.API_URL}commission/history/${filters?.status}/${filters?.dateFrom}/${filters?.toDate}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),

    fetchCommissionSummary: () => {
        return ajax.get(`${config.url.API_URL}commission/summary`).pipe(map(res => res.response))
    },
}}

export type ICommissionService = ReturnType<typeof CommissionService>

export default CommissionService