import { IStorageService } from "@src/services/StorageService";
import { IWalletService } from "@src/services/WalletService";
import { IAction, ActionType } from "../types";

const initReducer = function (walletService: IWalletService, storageService: IStorageService) {

  const adyenReloadData = storageService.get('adyenReloadData');
  const selectedDenominationValue = adyenReloadData ? JSON.parse(adyenReloadData) : '';

  const INITIAL_STATE = {
    fetchInProgress: false,
    walletData: (undefined as any),
    fullWalletData: (undefined as any),
    oldWalletData: (undefined as any),
    fetchCreditTilesInProgress: false,
    creditTilesData: (undefined as any),
    fetchCreditDetailsInProgress: false,
    creditDetails: (undefined as any),
    selectedDenomination: selectedDenominationValue?.isReloadAdyen ? selectedDenominationValue?.adyenValue : (undefined as any),
    fetchCreditCompleteInProgress: (undefined as any),
    creditCompleteData: (undefined as any),
    employeeWalletDetails: {
      employeeWalletLocked: false as boolean,
      WalletId: "",
      RequestAccepted: false,
      WalletInUse: null,
      WalletReleaseTime: null
    },
  };

  let reducersByActions: any = {}
  reducersByActions[ActionType.WalletDataRequest] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchInProgress: true,
    };
  }
  reducersByActions[ActionType.Logout] = (state: any, actionData: any) => {
    return INITIAL_STATE
  }
  reducersByActions[ActionType.WalletDataResponse] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchInProgress: false,
      oldWalletData: state.walletData,
      walletData: actionData.data,
      fullWalletData: actionData.walletData,
      employeeWalletDetails: {
        ...state.employeeWalletDetails, 
        WalletInUse: actionData.walletData?.WalletInUse,
        WalletReleaseTime: actionData.walletData?.WalletReleaseTime
      }
    };
  }

  reducersByActions[ActionType.WalletCreditTilesRequest] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchCreditTilesInProgress: true,
    };
  }
  reducersByActions[ActionType.WalletCreditTilesResponse] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchCreditTilesInProgress: false,
      creditTilesData: actionData
    };
  }

  reducersByActions[ActionType.AddWalletDetailsRequest] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchCreditDetailsInProgress: true,
      selectedDenomination: actionData
    };
  }
  reducersByActions[ActionType.AddWalletDetailsResponse] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchCreditDetailsInProgress: false,
      creditDetails: actionData
    };
  }

  reducersByActions[ActionType.CreditCompleteRequest] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchCreditCompleteInProgress: true,
      creditCompleteData: undefined
    };
  }
  reducersByActions[ActionType.CreditCompleteResponse] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchCreditCompleteInProgress: false,
      creditCompleteData: actionData
    };
  }
  reducersByActions[ActionType.EmployeeWalletLock] = (state: any, actionData: any) => {
    return {
      ...state,
      fetchInProgress: false,
      employeeWalletDetails: actionData
    };
  }

  const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
    return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
  };


  return reducer;
};

export default initReducer;