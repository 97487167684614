import { map } from 'rxjs/operators'
import { ajaxType } from '../interceptor'


const PrepaidPortalService = (ajax: ajaxType) => {
    return {
        generalGetRequest: (url: string) => {
            return ajax.get(url)
                .pipe(map(res => res?.response))
        },
        generalPostRequest: (url: string, payload: any) => {
            return ajax.post(url, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res?.response))
        },
        generalPutRequest: (url: string, payload: any) => {
            return ajax.put(url, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res?.response))
        }
    }
}

export type IPrepaidPortalService = ReturnType<typeof PrepaidPortalService>
export default PrepaidPortalService