import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from "../interceptor"

const ItemService = (ajax: ajaxType) => {
    return {
        fetchItemInfo: (lng: string) => {
            return ajax.get(`${config.url.API_URL}ProductCatalogue/Products/${lng}`).pipe(map(res => res.response))
        },

        fetchMenuInfo: () => {
            return ajax.get(`${config.url.API_URL}Menu`).pipe(map(res => res.response))
        }
    }
}

export type IItemService = ReturnType<typeof ItemService>

export default ItemService