import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const InvoiceHistoryService = (ajax: ajaxType) => ({
    fetchInvoices: (filters: any) => ajax.get(
        `${config.url.API_URL}Order/InvoiceHistory?${Object.keys(filters)
            // .filter(el => filters[el])
            .map((key)=>key+"="+encodeURIComponent(filters[key]))
            .join('&')}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),
})

export type IInvoiceHistoryService = ReturnType<typeof InvoiceHistoryService>

export default InvoiceHistoryService