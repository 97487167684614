import { IDashboardTilesService } from "src/services/DashboardTilesService";
import { IStorageService } from "src/services/StorageService";
import { map } from "rxjs/operators";
import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";


const initActions = function (dahboardTilesService: IDashboardTilesService, storageService: IStorageService) {

  const fetchTilesInfo = (dispatch: AppDispatch) => () => {


    dispatch({type: ActionType.FetchTilesRequest})
    dahboardTilesService.fetchTilesInfo()
      .pipe(map((data: any) => {
        if(data && data.Tiles){
          return data.Tiles.map((val: any) => {
            return {
              kpi: val.KPI,
              done: val.Done,
              activityRateTargetPercentage: val.ActivityRateTargetPercentage,
              achieved: val.Achieved,
              target: val.Target,
              month: val.Month,
              averageTopupValue: val.AverageTopupValue,
              bundleRatio: val.BundleRatio
            }
          })
        }else{
          return null
        }
      }))
      .subscribe(data => {
        dispatch({type: ActionType.FetchTilesResponse, data: data})
      });
    };
    
    return { fetchTilesInfo }; 
  };
  
  export default initActions;