import { IAction, ActionType } from "../types";
import { SimRegAssessmentState } from './index'

const initReducer = function () {
    const INITIAL_STATE: SimRegAssessmentState = {
        tdFile: '',
        loading: false,
        questionLoading: false,
        questions: [],
        sessionId: '',
        result: {},
        logAnswers: {},
        otherFlags: {},
    }

    let reducersByActions: any = {}

    reducersByActions[ActionType.SimRegAssessmentRequest] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            loading: true
        }
    }
    reducersByActions[ActionType.SimRegAssessmentQuestionRequest] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            questionLoading: true
        }
    }
    reducersByActions[ActionType.SimRegAssessmentTDDownloadResponse] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            tdFile: actionData
        }
    }

    reducersByActions[ActionType.SimRegAssessmentQuestionResponse] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            loading: false,
            questions: actionData
        }
    }

    reducersByActions[ActionType.SimRegAssessmentSessionIdResponse] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            questionLoading: false,
            sessionId: actionData
        }
    }
    reducersByActions[ActionType.SimRegAssessmentResultResponse] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            loading: false,
            result: actionData
        }
    }
    reducersByActions[ActionType.SimRegAssessmentlogResponse] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            logAnswers: !actionData?.IsError ? {
                ...state?.logAnswers,
                [actionData?.questionId]: [...actionData?.answers]
            } : { ...actionData?.logAnswers }
        }
    }
    reducersByActions[ActionType.SimRegAssessmentFlagResponse] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            loading: false,
           otherFlags: actionData
        }
    }

    reducersByActions[ActionType.CleanQuestionnaire] = (state: SimRegAssessmentState, actionData: any) => {
        return {
            ...state,
            questions: [],
            sessionId: '',
            result: {},
            logAnswers: {}

        }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };


    return reducer;
}

export default initReducer