import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { History } from 'history'

const configureUserModule = (services: IServices, history: History) => {
  const actions = initActions(services.userService, services.storageService, services.termsAndConditionsService, services.marketingPopupService, history);
  const reducer = initReducer(services.userService, services.storageService);

  return { actions, reducer };
};

export type UserReducer = ReturnType<typeof initReducer>
export type UserActions = ReturnType<typeof initActions>

export default configureUserModule;