import { History } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './rootReducer'
import { Reducers } from '.'

const configureStore = (serviceReducers: Reducers, history: History<unknown>) => {
  const store = createStore(
    createRootReducer(history, serviceReducers), // root reducer with router state
    {},
    compose(
      applyMiddleware(
        routerMiddleware(history)
      ),
    ),
  )

  return store
}

export type StoreType = ReturnType<typeof configureStore>
let store: StoreType
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default configureStore