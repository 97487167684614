const OnlinePaymentService = () => {
    return {
        goToPayment: (link: string, params: any) => {
            const readyToPassParams = {
                "merchantReference": params.MerchantReference,
                "paymentAmount": params.PaymentAmount,
                "currencyCode": params.CurrencyCode,
                "shipBeforeDate": params.ShipBeforeDate,
                "skinCode": params.SkinCode,
                "merchantAccount": params.MerchantAccount,
                "sessionValidity": params.SessionValidity,
                "shopperLocale": params.ShopperLocale,
                "countryCode": params.CountryCode,
                "resURL": params.ReturnUrl,
                "shopperReference": params.ShopperReference,
                "allowedMethods": params.AllowedMethods,
                "blockedMethods": params.BlockedMethods,

                            /*Billing address*/
                "billingAddress.street": params.BillingAddress?.Street,
                "billingAddress.houseNumberOrName": params.BillingAddress?.Name,
                "billingAddress.city": params.BillingAddress?.City,
                "billingAddress.postalCode": params.BillingAddress?.ZipCode,
                "billingAddress.stateOrProvince": params.BillingAddress?.State,
                "billingAddress.country": params.BillingAddress?.Country,

                            /*Delivery address*/
                "deliveryAddress.street": params.ShipingAddress?.Street,
                "deliveryAddress.houseNumberOrName": params.ShipingAddress?.Name,
                "deliveryAddress.city": params.ShipingAddress?.City,
                "deliveryAddress.postalCode": params.ShipingAddress?.ZipCode,
                "deliveryAddress.stateOrProvince": params.ShipingAddress?.State,
                "deliveryAddress.country": params.ShipingAddress?.Country,

                            /*Signatures*/
                "billingAddressSig": params.BillingAddressSignature,
                "deliveryAddressSig": params.DeliveryAddressSignature,
                "merchantSig": params.MerchantSignature,
                "shopperSig": params.ShopperSignature,
                "shopperEmail": params.ShopperEmail,
                "recurringContract": params.RecurringContract,
                            /*shopper info*/
                "shopper.firstName": params.ShopperFirstName,
                "shopper.lastName": params.ShopperLastName

            }

            const form = document.createElement('form');
            form.style.visibility = 'hidden';
            form.method = 'POST';
            form.action = link;
            Object.entries(readyToPassParams).forEach(([key, value]) => {
                if (value) {
                    const input = document.createElement('input')
                    input.name = key
                    input.value = (value as any)
                    form.appendChild(input)
                }
            })
            document.body.appendChild(form)
            form.submit(); 
        }
    }
}

export type IOnlinePaymentService = ReturnType<typeof OnlinePaymentService>

export default OnlinePaymentService