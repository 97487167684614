import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from "../interceptor"

const BuyService = (ajax: ajaxType) => {
    return {
        getCart: () => {
            return ajax.get(
                `${config.url.API_URL}Cart`,
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },
        addToCart: (productId: string, productTotalQty: number, locale: string) => {
            return ajax.post(
                `${config.url.API_URL}Cart/Add`, 
                {
                    "ProductId": productId,
                    "Quantity": productTotalQty,
                    "Locale": locale
                  }, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
                //return of ({"Status":false,"CartDetails":{ quantity: 2 },"ErrorMessage":null})            
        },
        removeFromCart: (productId: string, locale: string) => {
            return ajax.put(
                `${config.url.API_URL}Cart/Remove`, 
                {
                    "ProductId": productId,
                    "Locale": locale
                  }, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
                //return of ({"Status":false,"CartDetails":{ quantity: 2 },"ErrorMessage":null})            
        },
        updateCart: (productId: string, productTotalQty: number, locale: string) => {
            return ajax.put(
                `${config.url.API_URL}Cart/Update`, 
                {
                    "ProductId": productId,
                    "Quantity": productTotalQty,
                    "Locale": locale
                  }, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
                //return of ({"Status":false,"CartDetails":{ quantity: 2 },"ErrorMessage":null})            
        },

        updateDelivery: (data: any) => {
            return ajax.put(
                `${config.url.API_URL}Cart/Update/address`, 
                data, 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        createOrder: (orderAmount: number) => {
            return ajax.post(
                `${config.url.API_URL}Cart/Order/${orderAmount}`,                 
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        commisionPayment: (orderNumber: string) => {
            return ajax.post(
                `${config.url.API_URL}Payment/Commission/${orderNumber}`,
                {},
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
            
        },

        orderConfirmation: (orderNumber: string, paymentMode: string) => {
            return ajax.get(
                `${config.url.API_URL}Order/Confirmation/${orderNumber}/${paymentMode}`,
                {"Content-Type": "application/json"}).pipe(map(res => res.response))  
                   
        },

        orderCheckout: (walletAmount: number, nonWalletAmount: number) => {
            return ajax.post(
                `${config.url.API_URL}Cart/Checkout`,  
                {
                    "WalletAmount": walletAmount,
                    "NonWalletAmount": nonWalletAmount
                },               
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        orderStatus: (orderNumber: string) => {
            return ajax.get(
                `${config.url.API_URL}Order/Status/${orderNumber}`,
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        paymentDetails: (locale: string) => {
            return ajax.get(
                `${config.url.API_URL}Payment/Buy/${locale}`,
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        offlinePayment: (orderNumber: string, paymentType: string) => {
            return ajax.post(
                `${config.url.API_URL}Payment/Offline/${orderNumber}/${paymentType}`,
                {},
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        zeroPayment: () => {
            return ajax.post(
                `${config.url.API_URL}Payment/Zero?paymentType=NotApplicable`,
                {},
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        removeCards: (adyenRemoveCardsRequest : any) => {
            return ajax.post(
                `${config.url.API_URL}Payment/RemoveCardDetails?adyenSessionResponse=${adyenRemoveCardsRequest.adyenSessionResponse}&cardNumber=${adyenRemoveCardsRequest.cardNumber}`,
                {},
                {"Content-Type": "application/json"}).pipe(map(res => res.response))
        },

        cartReview: () => 
            ajax.get(
                `${config.url.API_URL}Cart/Review`,
                {"Content-Type": "application/json"}).pipe(map(res => res.response)
            ),

        mbeOrder: () => 
            ajax.post(
                `${config.url.API_URL}Payment/MBEOrder`,
                {},
                {"Content-Type": "application/json"}).pipe(map(res => res.response)
            ),
        mbeOrderStatus: (orderNumber: string) =>
            ajax.get(
                `${config.url.API_URL}Order/MBEStatus/${orderNumber}`).pipe(map(res => res.response)
            ),
        mbeOrderConfirmation: (orderNumber: string) =>
            ajax.get(
                `${config.url.API_URL}Order/MBEConfirmation/${orderNumber}`).pipe(map(res => res.response)
            )
    }
}

export type IBuyService = ReturnType<typeof BuyService>

export default BuyService