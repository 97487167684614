import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";
import { ITradeOffersService } from "@src/services/TradeOffersServices";


const initActions = (tradeOffersServices: ITradeOffersService) => {

    const getTradeOffers = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.TradeOffersRequest})
        tradeOffersServices.fetchTradeOffers().subscribe(res=> {
            dispatch({ type: ActionType.TradeOffersResponse, data: [
                ...res
            ] })
        })
    }

    const cleanTradeOffers = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.CleanTradeOffers})
    }

    return {
        getTradeOffers,
        cleanTradeOffers
    }
}

export default initActions