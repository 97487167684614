import { IStorageService } from "@src/services/StorageService";
import { IUserService } from "@src/services/UserService";
import { IAction, ActionType } from "../types";

const initReducer = function (userService:IUserService, storageService: IStorageService) {

  const userData = storageService.get('userData');
  const countryConfiguration = storageService.get('countryConfiguration');
  const selectedCountry = storageService.get('selectedCountry');
  const token = storageService.get('userToken')
  const termsAndConditions = storageService.get('termsAndConditions')
  const marketingPopup = storageService.get('marketingPopup')
  const paymentLogo = storageService.get('paymentLogo')


  const INITIAL_STATE = {
    loginInProgress: false,
    userData: userData?JSON.parse(userData):undefined,
    token: token?token:'',
    loginError: (undefined as any),
    idError: false,
    passwordError: (undefined as any),
    loginScore: (undefined as number | undefined),
    grecaptchaKey: (undefined as string | undefined),
    lowScoreReason: (undefined as string | undefined),

    countriesInProgress: false,
    countriesList: (undefined as any),
    userProfileInProgress: false,
    userProfileList: (undefined as any),
    selectedCountry: selectedCountry,
    countryConfiguration: countryConfiguration?JSON.parse(countryConfiguration):undefined,
    countryConfigurationInProgress: false,
    
    resetPasswordInProgress: false,
    updateProfileInProgress: false,
    resetPasswordResult: (undefined as any),
    resetPasswordError: (undefined as any),

    changePasswordInProgress: false,
    changePasswordResult: (undefined as any),
    termsAndConditionsData: termsAndConditions?JSON.parse(termsAndConditions):undefined,
    termsAndConditionsEmailSending: false,
    termsAndConditionsEmailSendingResult: (undefined as any),
    updateUserProfile: (undefined as any),
    marketingPopupData: marketingPopup?JSON.parse(marketingPopup):undefined,
    paymentLogos: paymentLogo?JSON.parse(paymentLogo):undefined,
  };

  let reducersByActions: any = {}
  
  reducersByActions[ActionType.LoginRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      loginInProgress: true
    };
  }
  reducersByActions[ActionType.LoginResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      // loginInProgress: false,
      userData: actionData.data,
      token: actionData.token,
      loginScore: actionData.loginScore,
      lowScoreReason: actionData.lowScoreReason
    };
  }
  reducersByActions[ActionType.SetIdError] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      idError: true
    };
  }
  reducersByActions[ActionType.SetPasswordError] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      passwordError: true
    };
  }
  reducersByActions[ActionType.ResetPasswordRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      resetPasswordInProgress: true
    };
  }
  reducersByActions[ActionType.ResetPasswordResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      resetPasswordInProgress: false,
      resetPasswordResult: actionData
    };
  }
  reducersByActions[ActionType.ResetPasswordError] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      resetPasswordInProgress: false,
      resetPasswordError: actionData
    };
  }
  reducersByActions[ActionType.ChangePasswordRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      changePasswordInProgress: true
    };
  }
  reducersByActions[ActionType.ChangePasswordResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      changePasswordInProgress: false,
      changePasswordResult: actionData
    };
  }
  reducersByActions[ActionType.LoginError] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      loginInProgress: false,
      loginError: actionData.message,
      loginScore: actionData.loginScore,
      lowScoreReason: actionData.lowScoreReason
    };
  }
  reducersByActions[ActionType.Logout] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      userData: undefined,
      token: undefined,
      selectedCountry: undefined,
      countryConfiguration: undefined,
      termsAndConditionsData: undefined,
      marketingPopupData: undefined,
      paymentLogos: undefined,
      userProfileList: undefined,
      loginError: undefined,
      loginScore: undefined,
      lowScoreReason: undefined
    };
  }
  reducersByActions[ActionType.UpdateUserProfileRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      updateProfileInProgress: true
    };
  }
  reducersByActions[ActionType.UpdateUserProfileRequestResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      updateProfileInProgress: false,
      userProfileList: actionData
    };
  }

  reducersByActions[ActionType.CountriesRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      countriesInProgress: true
    };
  }
  reducersByActions[ActionType.CountriesResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      countriesInProgress: false,
      countriesList: actionData.list,
      grecaptchaKey: actionData.key
    };
  }
  reducersByActions[ActionType.UserProfileRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      userProfileInProgress: true
    };
  }
  reducersByActions[ActionType.UserProfileResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      userProfileInProgress: false,
      userProfileList: actionData
    };
  }
  reducersByActions[ActionType.CountrySelect] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      selectedCountry: actionData.country
    };
  }
  reducersByActions[ActionType.CountryConfigurationRequest] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      countryConfigurationInProgress: true
    };
  }
  reducersByActions[ActionType.CountryConfigurationResponse] =  (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      countryConfigurationInProgress: false,
      countryConfiguration: actionData
    };
  }
  reducersByActions[ActionType.CleanErorr] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      resetPasswordError: undefined,
      loginError: false,
      loginScore: undefined,
      lowScoreReason: undefined
    }
  };
  reducersByActions[ActionType.CleanUserProfile] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      changePasswordResult: undefined
    }
  };

  reducersByActions[ActionType.TermsAndConditionsResponse] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      loginInProgress: false,
      termsAndConditionsData: actionData
    }
  };

  reducersByActions[ActionType.TermsAndConditionsSendEmailRequest] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      termsAndConditionsEmailSending: true
    }
  };

  reducersByActions[ActionType.TermsAndConditionsSendEmailResponse] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      termsAndConditionsEmailSending: false,
      termsAndConditionsEmailSendingResult: actionData
    }
  };

  reducersByActions[ActionType.AcceptTermsRequest] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      acceptTermsInProgress: true
    }
  };

  reducersByActions[ActionType.AcceptTermsResponse] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      acceptTermsInProgress: false,
      termsAndConditionsData: actionData
    }
  };

  reducersByActions[ActionType.PaymentLogo] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      paymentLogos: actionData
    }
  };
  
  reducersByActions[ActionType.PostLoginResponse] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      loginInProgress: false,
      termsAndConditionsData: actionData.termsResponse,
      marketingPopupData: actionData.marketingResponse
    }
  };

  reducersByActions[ActionType.MarketingPopupSkip] = (state: any, actionData: any): typeof INITIAL_STATE => {
    return {
      ...state,
      marketingPopupData: actionData
    }
  };

  const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
    // console.log("state: ", state)
    // console.log("action: ", action)
    return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
  };

  
  return reducer;
};

export default initReducer;