import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { IPostPayService } from "@src/services/PostPayService";
import { forkJoin, Observable, of } from "rxjs";
import { PortInTypes, PostPayload, PostpaySteps, SignatureTypes, SimProductType } from ".";
import { IStorageService } from "@src/services/StorageService";
import { map } from "rxjs/internal/operators/map";

const initActions = (PostPayServices: IPostPayService, storageService: IStorageService) => {

    const getProducts = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        const { productType } = getState().postPay
        dispatch({ type: ActionType.PostPayProductRequest })
        if (productType) {
            PostPayServices.fetchProducts(productType, i18n.language).subscribe((res: any[]) => {
                dispatch({ type: ActionType.PostPayProductResponse, data: res })
            })
        }
    }

    const getBoltons = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.PostPayBoltonsRequest })
        PostPayServices.fetchBoltons().subscribe(res => {
            !!res && dispatch({
                type: ActionType.PostPayBoltonsResponse, data: [
                    ...res
                ]
            })
        })
    }

    const getDocuments = (dispatch: AppDispatch) => () => {
        PostPayServices.fetchDocuments().subscribe(res => {
            !!res && dispatch({
                type: ActionType.PostPayDocumentsResponse, data: [
                    ...res
                ]
            })
        })
    }

    const getPromotions = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.PostPayPromotionsRequest })
        PostPayServices.fetchPromotions().subscribe(res => {
            !!res && dispatch({
                type: ActionType.PostPayPromotionsResponse, data: [...res]
            })
        })
    }

    const fetchPostPayConfig = (dispatch: AppDispatch) => () => {
        PostPayServices.getPostPaidConfig().subscribe(config => {
            dispatch({ type: ActionType.PostPayConfigResponse, data: {...config} })
        })
    }

    const setStep = (dispatch: AppDispatch) => (step: number) => {
        dispatch({ type: ActionType.PostPaySetStep, data: step })
    }

    const updatePostPayLoad = (dispatch: AppDispatch) => (data: Partial<PostPayload>) => {
        dispatch({ type: ActionType.PostPayLoadUpdate, data: { ...data } })
    }

    const getCartTotal = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.PostPayCartTotalRequest })
        if (getState().postPay.postPayload.SessionId) {
            PostPayServices.getCartTotal(getState().postPay.postPayload)
                .subscribe(res => {
                    const { isPromotionApplicable, discountedActivationFee, activationFee, appliedPromotion } = getState().postPay
                    dispatch({
                        type: ActionType.PostPayCartTotalResponse,
                        data: {
                            CartTotal: res.CartTotal,
                            activationFee,
                            isPromotionApplicable,
                            discountedActivationFee,
                            appliedPromotion
                        }
                    })
                })
        } else {
            PostPayServices.getSessionId()
                .subscribe(SessionId => {
                    dispatch({ type: ActionType.PostPayLoadUpdate, data: { SessionId } })
                    const { postPayload, assignedOTAG } = getState().postPay
                    const observers = [
                        PostPayServices.getCartTotal(postPayload)
                    ]
                    if (
                        (postPayload.PortInType === PortInTypes.existingLebara && !!assignedOTAG) ||
                        postPayload.PortInType !== PortInTypes.existingLebara
                    ) {
                        observers.push(
                            PostPayServices.promotionEvaluation(postPayload)
                        )
                    }
                    forkJoin(observers).subscribe(data => {
                        dispatch({
                            type: ActionType.PostPayCartTotalResponse,
                            data: {
                                CartTotal: data[0]?.CartTotal,
                                activationFee: data[0]?.ActivationFee,
                                isPromotionApplicable: data[1]?.isActivationFeeDiscounted,
                                discountedActivationFee: data[1]?.finalActivationFee,
                                appliedPromotion: data[1]?.response
                            }
                        })
                        dispatch({
                            type: ActionType.PostPayLoadUpdate,
                            data: { ActivationFee: data[0]?.ActivationFee, epcPromotionId: data[1]?.response ? data[1]?.response.promotionId : undefined }
                        })
                    })
                })
        }
    }

    const cleanPostPay = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanPostPay })
    }

    const clearPostpayCart = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.PostPayCartClear })
    }

    const updateProductType = (dispatch: AppDispatch) => (postPayType: SimProductType | undefined) => {
        dispatch({ type: ActionType.PostPayProductTypeUpdate, data: postPayType })
    }

    const customerProfileCheck = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        const { countryConfiguration } = getState().user
        dispatch({ type: ActionType.PostPayCustProfileCheckRequest })
        PostPayServices.customerProfileCheck(postPayload).subscribe(res => {
            if (!res.CustomerProfileCheck) {
                dispatch({ type: ActionType.PostPayCustProfileCheckResponse, data: 'postpay-customervalidationfailed' })
            } else if (!res.CreditCheck) {
                dispatch({ type: ActionType.PostPayCustProfileCheckResponse, data: 'postpay-creditvalidationfailed' })
            } else {
                dispatch({ type: ActionType.PostPayCustProfileCheckResponse, data: '' })
                const targetStep = countryConfiguration?.CountryRegionId === 'NL' ? PostpaySteps.Payments : PostpaySteps.SimDetails
                setStep(dispatch)(targetStep)
            }
        })
    }

    const validateMsisdnCustomer = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { postPayload, CartTotal } = getState().postPay
        dispatch({ type: ActionType.ValidateMsisdCustomerRequest })
        dispatch({ type: ActionType.PostPayGenerateOTAGRequest })
        PostPayServices.customerVerification(postPayload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.PostPayLoadUpdate, data: { CrmId: res.Message } })
                dispatch({
                    type: ActionType.ValidateMsisdCustomerResponse, data: {
                        isCustomerProfileValid: true,
                        msisdnError: ''
                    }
                })
                dispatch({ type: ActionType.PostPayGenerateOTAGResponse, data: 'Success' })
                // getCartTotal(dispatch, getState)()
                dispatch({ type: ActionType.PostPayCartTotalRequest })
                PostPayServices.promotionEvaluation(postPayload).subscribe(data => {
                    dispatch({
                        type: ActionType.PostPayCartTotalResponse,
                        data: {
                            CartTotal,
                            activationFee: postPayload.ActivationFee,
                            isPromotionApplicable: data?.isActivationFeeDiscounted,
                            discountedActivationFee: data?.finalActivationFee,
                            appliedPromotion: data?.response
                        }
                    })
                    dispatch({
                        type: ActionType.PostPayLoadUpdate,
                        data: { epcPromotionId: data?.response ? data?.response.promotionId : undefined }
                    })
                })

            } else {
                dispatch({
                    type: ActionType.ValidateMsisdCustomerResponse, data: {
                        msisdnError: res.Message
                    }
                })
                dispatch({ type: ActionType.PostPayGenerateOTAGResponse, data: '' })
            }
        })
    }

    const generateOTAG = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.PostPayGenerateOTAGRequest })
        PostPayServices.generateOTAG(postPayload).subscribe(res => {
            if (res) {
                dispatch({ type: ActionType.PostPayGenerateOTAGResponse, data: res })
            } else {
                dispatch({ type: ActionType.PostPayGenerateOTAGResponse, data: { error: true } })
            }
        })
    }

    const resetOTAG = (dispatch: AppDispatch) => () =>
        dispatch({ type: ActionType.PostPayGenerateOTAGResponse, data: '' })

    const validateOTAG = (dispatch: AppDispatch, getState: () => RootState) => (otp: string, onComplete: (arg: any) => void) => {
        const postPayload = getState().postPay.postPayload
        const { countryConfiguration } = getState().user
        PostPayServices.validateOTAG(
            postPayload.SessionId as string,
            `${countryConfiguration.InternationalDialingCode}${postPayload.MSISDN?.startsWith('0') ? postPayload.MSISDN.substring(1) : postPayload.MSISDN}`,
            otp
        ).subscribe(res => {
            onComplete(res.Message)
        })
    }

    const getConsentError = (ErrorMessage: string) => {
        switch (ErrorMessage) {
            case 'GeneralError':
                return 'postpay-consentvalidationerror'
            case 'EmailValidationError':
                return 'postpay-consentemailnerror'
            case 'IbanValidationError':
                return 'postpay-consentibanerror'
            case 'ProductValidationError':
                return 'postpay-consentproducterror'
            case 'RetailerValidationError':
                return 'postpay-consentretailererror'
            case 'MsisdnActiveUsed':
                return 'postpay-consentmsisdnerror'
            default:
                return ErrorMessage
        }
    }

    const submitFMVNONewLebaraNumberDetails = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.FMVNONewLebaraSubmitRequest })
        PostPayServices.validateSubscriptionV2(postPayload).subscribe(res => {
            if (res) {
                PostPayServices.getFreeMSISDNFromPoolManager(postPayload.SessionId as any).subscribe(res => {
                    if (res) {
                        dispatch({ type: ActionType.PostPayLoadUpdate, data: { FreeMSISDN: res } })
                        PostPayServices.createConsent(getState().postPay.postPayload).subscribe(res => {
                            if (res.ResponseCode === 200) {
                                dispatch({ type: ActionType.FMVNONewLebaraSubmitResponse, data: res })
                                setStep(dispatch)(PostpaySteps.Consent)
                            }
                            else {
                                let error = getConsentError(res.ErrorMessage)

                                dispatch({
                                    type: ActionType.FMVNONewLebaraSubmitResponse, data: {
                                        consentError: error
                                    }
                                })
                                setStep(dispatch)(PostpaySteps.Consent)
                            }
                        })
                    } else {
                        dispatch({ type: ActionType.FMVNONewLebaraSubmitResponse, data: { error: 'postpay-fmvnofreemsisdnvalidationerror' } })
                    }
                })
            } else {
                dispatch({ type: ActionType.FMVNONewLebaraSubmitResponse, data: { error: 'postpay-pukcodeiccidvalidationfailed' } })
            }
        })
    }

    const submitLMVNONewLebaraNumberDetails = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.LMVNONewLebaraSubmitRequest })
        PostPayServices.validateSubscription(postPayload).subscribe(res => {
            if (res) {
                PostPayServices.createConsent(getState().postPay.postPayload).subscribe(res => {
                    if (res.ResponseCode === 200) {
                        dispatch({ type: ActionType.LMVNONewLebaraSubmitResponse, data: res })
                        setStep(dispatch)(PostpaySteps.Consent)
                    }
                    else {
                        let error = getConsentError(res.ErrorMessage)

                        dispatch({
                            type: ActionType.LMVNONewLebaraSubmitResponse, data: {
                                consentError: error
                            }
                        })
                        setStep(dispatch)(PostpaySteps.Consent)
                    }
                })
            } else {
                dispatch({ type: ActionType.LMVNONewLebaraSubmitResponse, data: { error: 'postpay-msisdnvalidationfailed' } })
            }
        })
    }

    const validateSubscriptionV2 = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.PostPayValidateSubscriptionRequest })
        PostPayServices.validateSubscriptionV2(postPayload).subscribe(res => {
            res
                ? dispatch({ type: ActionType.PostPayValidateSubscriptionResponse, data: { isCapturePortInSuccess: true } })
                : dispatch({
                    type: ActionType.PostPayValidateSubscriptionResponse, data: {
                        isCapturePortInSuccess: false,
                        numberDetailsErrorMessage: 'postpay-msisdnvalidationfailedmessage'
                    }
                })
        })
    }

    const validateSubscription = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.PostPayValidateSubscriptionRequest })
        PostPayServices.validateSubscription(postPayload).subscribe(res => {
            res
                ? dispatch({ type: ActionType.PostPayValidateSubscriptionResponse, data: { isCapturePortInSuccess: true } })
                : dispatch({
                    type: ActionType.PostPayValidateSubscriptionResponse, data: {
                        isCapturePortInSuccess: false,
                        numberDetailsErrorMessage: 'postpay-msisdnvalidationfailedmessage'
                    }
                })
        })
    }

    const CreateConsent = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.CreateConsentRequest })
        PostPayServices.createConsent(postPayload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.CreateConsentResponse, data: res })
                // dispatch({ type: ActionType.FMVNONonLebaraSubmitResponse, data: res })
                setStep(dispatch)(PostpaySteps.Consent)
            }
            else {
                let error = getConsentError(res.ErrorMessage)

                dispatch({
                    type: ActionType.CreateConsentResponse, data: {
                        consentError: error
                    }
                })
                setStep(dispatch)(PostpaySteps.Consent)
            }
        })
    }

    const submitExistingLebaraNumberDetails = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.ExistingLebaraSubmitRequest })
        PostPayServices.validateEligibility(postPayload).subscribe(res => {
            if (res.eligible) {
                PostPayServices.createConsent(postPayload).subscribe(res => {
                    if (res.ResponseCode === 200) {
                        dispatch({ type: ActionType.ExistingLebaraSubmitResponse, data: res })
                        setStep(dispatch)(PostpaySteps.Consent)
                    }
                    else {
                        let error = getConsentError(res.ErrorMessage)

                        dispatch({
                            type: ActionType.ExistingLebaraSubmitResponse, data: {
                                consentError: error
                            }
                        })
                        setStep(dispatch)(PostpaySteps.Consent)
                    }
                })
            } else if (res.fmvnoMigrationRequired) {
                dispatch({ type: ActionType.ExistingLebaraSubmitResponse, data: { error: 'postpay-producteligibilityfmvnoneeded' } })
            } else {
                dispatch({ type: ActionType.ExistingLebaraSubmitResponse, data: { error: 'postpay-producteligibilityfailed' } })
            }
        })
    }

    const digitalSigning = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.PostPayDigitalSignRequest })
        PostPayServices.digitalSigning(postPayload).subscribe(res => {
            if (res.ResponseCode === 200) {
                if (postPayload.SignatureType === SignatureTypes.email) {
                    dispatch({ type: ActionType.PostPayDigitalSignResponse, data: { EnvelopeId: res.EnvelopeId } })
                    setStep(dispatch)(PostpaySteps.MigrationStatus)
                } else {
                    storageService.set('postpayportinstate', JSON.stringify({
                        postPayload: { ...getState().postPay.postPayload, EnvelopeId: res.EnvelopeId },
                        step: PostpaySteps.MigrationStatus,
                        CartTotal: getState().postPay.CartTotal,
                        productType: getState().postPay.productType,
                    }))
                    window.location.replace(res.RedirectionURL)
                }
            } else {

            }
        })
    }

    const checkEnvelopeStatus = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        const { SignatureType } = postPayload
        if (SignatureType === SignatureTypes.email) {
            dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { postPayload: { ...postPayload, EnvelopeStatus: 'Sent' } } })
        } else if (new URLSearchParams(window.location.search).get('event') === 'decline') {
            dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { error: 'Declined' } })
        } else {
            let retryCount = 6
            dispatch({ type: ActionType.PostPayEnvelopeStatusRequest })
            getOnscreenEnvelopeStatus(retryCount)
        }
        function getOnscreenEnvelopeStatus(retry: number) {
            if (retry > 0) {
                PostPayServices.getEnvelopeStatus(postPayload.EnvelopeId as string).subscribe({
                    next: res => {
                        if (res.EnvelopeStatus === 'Completed') {
                            if (res.Status === 'Success') {
                                dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { postPayload: { ...res } } })
                            } else if (res.Status === 'Failed') {
                                dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { error: 'Success' } })
                            } else if (res.Status === 'Pending') {
                                dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { error: 'BackendInternalError' } })
                            } else {
                                setTimeout(() => getOnscreenEnvelopeStatus(retry - 1), 30000)
                            }
                        } else if (res.EnvelopeStatus === 'Declined') {
                            dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { error: 'Declined' } })
                        } else {
                            setTimeout(() => getOnscreenEnvelopeStatus(retry - 1), 30000)
                        }
                    },
                    error: () => { dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { error: 'InternalError' } }) }
                })
            } else {
                dispatch({ type: ActionType.PostPayEnvelopeStatusResponse, data: { error: 'BackendInternalError' } })
            }
        }
    }

    const getNetworkOperators = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.PostPayNetworkOperatorsRequest })
        PostPayServices.fetchOperators(postPayload.SessionId as string, postPayload.Locale as string).subscribe(res => {
            dispatch({ type: ActionType.PostPayNetworkOperatorsResponse, data: res || [] })
        })
    }

    const getNonPortInDates = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload
        dispatch({ type: ActionType.PostPayNonPortInDatesRequest })
        PostPayServices.fetchNonPortInDates(postPayload.SessionId as string, postPayload.Locale as string).subscribe(res => {
            dispatch({ type: ActionType.PostPayNonPortInDatesResponse, data: res || [] })
        })
    }

    const cleanProfileCheckError = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.PostpayCleanCustomerDetailsError })
    }

    const restorePortInRequest = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.PostPayRestorePortInRequest })
    }

    const getPostpayHistory = (dispatch: AppDispatch) => (locale: string, onSuccess: () => void) => {
        PostPayServices.postpayHistory(locale).subscribe(res => {
            onSuccess()
            if (res.ResponseCode === 200) {
                window.open(res.Message, '_blank')
            }
        })
    }

    const downloadContractSummary = (dispatch: AppDispatch, getState: () => RootState) => (onComplete: () => void | undefined) => {
        const { countryConfiguration } = getState().user
        const postPayload = getState().postPay.postPayload

        const { MSISDN, NewMSISDN, NonLebaraNumber, FreeMSISDN, PortInType, PostPayType } = postPayload

        let msisdn: string | undefined | null

        if (PortInType === PortInTypes.existingLebara) {
            msisdn = `${countryConfiguration.InternationalDialingCode}${MSISDN?.startsWith('0') ? MSISDN.substring(1) : MSISDN}`
        } else if (PostPayType === SimProductType.lmvno && PortInType === PortInTypes.newLebara) {
            msisdn = NewMSISDN
        } else if (PortInType === PortInTypes.nonLebara) {
            msisdn = `${countryConfiguration.InternationalDialingCode}${NonLebaraNumber?.startsWith('0') ? NonLebaraNumber.substring(1) : NonLebaraNumber}`
        } else if (PostPayType === SimProductType.fmvno && PortInType === PortInTypes.newLebara) {
            msisdn = FreeMSISDN
        }

        PostPayServices.downloadContractSummary(
            postPayload.SessionId as string,
            msisdn as string
        ).pipe(map(data => {
            const link = document.createElement("a");
            link.target = '_blank';
            link.download = `LebaraContractSummary.pdf`;
            link.href = window.URL.createObjectURL(data);
            document.body.appendChild(link);
            link.click();
            // Cleanup the DOM
            document.body.removeChild(link);
        }))
            .subscribe((res: any) => {
                !!onComplete && onComplete()
            })
    }

    const setRetentionNumber = (dispatch: AppDispatch) => (number: number) => {
        dispatch({ type: ActionType.PostPaySetRetentionNumber, data: number })

    }

    const checkRetentionElegibility = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (msisdn: number) => {
        const sessionId = getState()?.postPay?.postPayload?.SessionId;

        if (sessionId) {
            dispatch({ type: ActionType.PostPayRetentionStatusRequest })

            PostPayServices.checkRetentionElegibility(sessionId, msisdn, 'en-nl').subscribe(res => {
                let eligiblityResponse;
                if (res.statusCode === 200) {
                    eligiblityResponse = {
                        status: true,
                        message: res.message,
                        retentionEligibilityResponse: res.retentionEligibilityResponse
                    }
                } else {
                    eligiblityResponse = {
                        status: false,
                        message: res.message,
                        retentionEligibilityResponse: res.retentionEligibilityResponse
                    }
                }
                dispatch({ type: ActionType.PostPayRetentionStatusResponse, data: eligiblityResponse })
            })
        }
    }

    const validateRetention = (dispatch: ActionType, getState: () => RootState) => () => {
        const postPayload = getState().postPay.postPayload;
        PostPayServices.validateRetention(postPayload).subscribe(data => {
            console.log(data);
        })
    }

    const clearPromotions = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { CartTotal, activationFee } = getState().postPay
        dispatch({
            type: ActionType.PostPayCartTotalResponse,
            data: {
                CartTotal,
                activationFee,
                isPromotionApplicable: false,
                discountedActivationFee: undefined,
                appliedPromotion: undefined
            }
        })
        dispatch({
            type: ActionType.PostPayLoadUpdate,
            data: { epcPromotionId: undefined }
        })
    }    

    return {
        getProducts,
        getBoltons,
        cleanPostPay,
        getDocuments,
        fetchPostPayConfig,
        setStep,
        updatePostPayLoad,
        getCartTotal,
        updateProductType,
        customerProfileCheck,
        clearPostpayCart,
        submitFMVNONewLebaraNumberDetails,
        validateSubscriptionV2,
        validateSubscription,
        CreateConsent,
        validateMsisdnCustomer,
        generateOTAG,
        resetOTAG,
        validateOTAG,
        submitExistingLebaraNumberDetails,
        digitalSigning,
        checkEnvelopeStatus,
        getNetworkOperators,
        cleanProfileCheckError,
        restorePortInRequest,
        getNonPortInDates,
        getPostpayHistory,
        downloadContractSummary,
        submitLMVNONewLebaraNumberDetails,
        setRetentionNumber,
        getPromotions,
        checkRetentionElegibility,
        validateRetention,
        clearPromotions
    }
}

export default initActions