import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';


export interface FaqState {
    fetchInProgress: boolean
    faq: any
}

const configureFaqModule = (services: IServices) => {
  const actions = initActions(services.faqService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type FaqReducer = ReturnType<typeof initReducer>
export type FaqActions = ReturnType<typeof initActions>

export default configureFaqModule;