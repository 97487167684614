import { IServices } from "@src/services";
import initActions from "./actions";
import initReducer from "./reducer";

export interface PortInResubmitState {
    serviceId: string | null,
    loading: false,
    resubmissionState: any,
    contractDetails: {
        AxCode: string | null,
        City: string | null
        CountryCode: string | null,
        CrmId: string | null
        DOB: string | null,
        Email: string | null,
        FirstName: string | null,
        HouseNo: string | null,
        LastName: string | null,
        Locale: string | null | null,
        PostCode: string | null,
        SessionId: string | null,
        Status: string | null,
        StreetName: string | null,
        donorOperator: string | null,
        isOnContract: Boolean | null,
        newIccid: string | null,
        orderId: string | null,
        portingDate: string | null,
        requestedMsisdn: string | null,
        requstedDate: string | null,
        retailerId: string | null,
        status: string | null,
        temporaryMsisdn: string | null
    }
}

const configurePortInResubmitModule = (services: IServices) => {
    const actions = initActions(services.portInResubmissionService, services.postPayService)
    const reducer = initReducer()

    return { actions, reducer };
}

export type PortInResubmitReducer = ReturnType<typeof initReducer>
export type PortInResubmitActions = ReturnType<typeof initActions>

export default configurePortInResubmitModule;