import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const KpiReportService = (ajax: ajaxType) => {
    return {
        fetchMtdData: () => {
            return ajax.get(`${config.url.API_URL}KpiReport/MTD`)
                .pipe(map(res => res.response))
        },
        fetchReportConfig: () => {
            return ajax.get(`${config.url.API_URL}KpiReport/Config`)
            .pipe(map(res => res.response))
        },

        fetchMonths: (kpi: string) => {
            return ajax.get(`${config.url.API_URL}KpiReport/Month/${kpi}`)
                .pipe(map(res => res.response))
        },
        getKPIData: (kpi: string, payload: any) => {
            const { reportName, year, month, isself, subaccount } = payload;
            const subAcc = subaccount ? `?subaccount=${subaccount}`: ''
            return ajax.get(`${config.url.API_URL}KpiReport/${kpi}/${reportName}/${year}/${month}/${isself}${subAcc}`)
                .pipe(map(res => res.response))
        },
        getNtopupSummary: (month: string) => {
            return ajax.get(`${config.url.API_URL}KpiReport/ntopup/Summary/${month}`)
                .pipe(map(res => res.response))
        },
        getPBSummary: () => {
            return ajax.get(`${config.url.API_URL}KpiReport/PBASummary`)
            .pipe(map(res => res.response))
        },
        getBASummary: () => {
            return ajax.get(`${config.url.API_URL}KpiReport/BASummary`)
            .pipe(map(res => res.response))
        },
        generalGetRequest: (url: string) => {
            return ajax.get(url)
            .pipe(map(res => res.response))
        },


    }
}

export type IKpiReportService = ReturnType<typeof KpiReportService>

export default KpiReportService;