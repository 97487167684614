import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const MyOrdersService = (ajax: ajaxType) => ({
    fetchOrders: (filters: any) => ajax.get(
        `${config.url.API_URL}Order/LineItems?${Object.keys(filters)
            // .filter(el => filters[el])
            .map((key)=>key+"="+encodeURIComponent(filters[key]))
            .join('&')}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),

    getAllProducts: () => ajax.get(
        `${config.url.API_URL}Order/Products`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),

    downloadMyOrders: (filters: any) => ajax.get(
        `${config.url.API_URL}Order/DownloadLineItem?${Object.keys(filters)
            // .filter(el => filters[el])
            .map((key)=>key+"="+encodeURIComponent(filters[key]))
            .join('&')}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),

    getSummary: (orderNumber: string) => ajax.get(
        `${config.url.API_URL}Order/Summary/${orderNumber}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),

    fetchMBEOrders: (filters: any) => ajax.get(
        `${config.url.API_URL}Order/MBEHistory?${Object.keys(filters)
            // .filter(el => filters[el])
            .map((key)=>key+"="+encodeURIComponent(filters[key]))
            .join('&')}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),
    mbeOrderSummary: (orderNumber: string) =>
        ajax.get(
            `${config.url.API_URL}Order/MBEConfirmation/${orderNumber}`).pipe(map(res => res.response)
        )
})

export type IMyOrdersService = ReturnType<typeof MyOrdersService>

export default MyOrdersService