import { IItemService } from "@src/services/ItemService";
import { IStorageService } from "@src/services/StorageService";
import { i18n } from "i18next";
import moment from "moment";
import { forkJoin, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType, MenuItem } from "../types";

const productByType: any = {
    0: "SIMS",
    1: "Voucher",
    2: "EPIN",
    3: "Bundles",
    5: "MBE"
}

const isValidUrl = (urlString: string) => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}

const validateUrlProtocol = (url: string) => {
    try {
        const descUrl = new URL(url)
        if (descUrl.protocol !== window.location.protocol) {
            return descUrl.href.replace(descUrl.protocol, window.location.protocol)
        } else {
            return descUrl.href
        }
    } catch (e: any) {
        return
    }
}

const convertItem = function (item: any) {
    return {
        id: item.ProductId,
        name: item.DisplayName ? item.DisplayName : item.ProductName,
        denomination: item.Denomination,
        isDisplayByName: item.IsDisplayByName,
        isDisplayByFaceValue: item.IsDisplayByFaceValue,
        isRoundOffEnabled: item.IsRoundOffEnabled,
        isLebaraOne: item.IsLebaraOne,
        isBolton: item.IsBolton,
        isSPV: item.IsSPV,
        price: item.SalesPrice,
        tax: item.TaxValue,
        pic: item.Picture,
        type: item.ProductCategoryType,
        desc: item.ProductHelpText,
        POSDesc: item.ProductDescription,
        descURL: validateUrlProtocol(item.ProductDescriptionURL) || (isValidUrl(item.ProductHelpText) ? item.ProductHelpText : null),
        serialNumber: item.SerialNumber,
        pin: item.PIN,
        qty: item.InStockCount,
        maxQty: item.ProductCategoryType === 'MBE' ? (item.AXMaxQty || 1000) : item.MaxOQ,
        multiplier: item.Multiplier,
        typeProduct: item.ProductType,
        commissionRestricted: item.CommissionRestricted,
        minQty: item.AXMinQty,
        offers: item.Offers && convertOffers(item),
        productVATText: item.ProductVATText,
        minOQ: item.ProductCategoryType === 'MBE' ? (item.AXMinQty || 1) : item.MinOQ,
        ProdDescUrl: validateUrlProtocol(item.ProdDescFileName),
        ContractSummaryUrl: validateUrlProtocol(item.ContractFileName),
        basePrice: item.BasePrice,
        sequence: item.Sequence,
        isInStock: item.isInStock || item.isOptinBundle
    }
}

const convertBundleItem = function (item: any) {
  return {
        id: item.ProductId,
        name: item.DisplayName || item.MarketingId,
        price: item.Cost,
        denomination: undefined,
        isDisplayByName: undefined,
        isDisplayByFaceValue: undefined,
        isRoundOffEnabled: undefined,
        pic: item.Picture,
        type: "",
        desc: "",
        alertNote: item.AlertNote,
        descURL: item.ProductDescriptionUrl,
        serialNumber: undefined,
        pin: undefined,
        bundleId: item.BundleId,
        typeProduct: undefined,
        maxQty: 0,
        multiplier: 0,
        commissionRestricted: false,
        minQty: undefined,
        productVATText: null,
        minOQ: undefined,
        ProdDescUrl: validateUrlProtocol(item.ProdDescFileName),
        ContractSummaryUrl: validateUrlProtocol(item.ContractFileName),
        sequence: item.Sequence,
        keyword: item.Keyword
    }
}

const convertOffers = (item: any) => {
    return item.Offers && item.Offers.map((offer: any) => {
        return {
            id: offer.BaseItemId,
            qty: offer.BaseQuantity,
            description: offer.Description,
            price: offer.FreeBaseItem ? 0 : item.SalesPrice,
            name: item.DisplayName ? item.DisplayName : item.ProductName,
            helperText: offer.HelpText,
            productType: item.ProductType
        }
    })
}

const sortByCategory = function (isOptinBundles: boolean, list: any[], convertItem: (item: any) => any, type: string, walletCommission: number): { name: string, items: any[], isOpen: boolean, CategoryIsActive: boolean, categorySequence: number, categoryHelpText: string }[] {
    let res: { name: string, type: string, items: any[], isOpen: boolean, CategoryIsActive: boolean, categorySequence: number, categoryHelpText: string }[] = []
    list.forEach((item) => {
        if (!res[item.CategoryId]) {
            res[item.CategoryId] = { name: item.CategoryName, type: type, items: [], isOpen: item.IsOpen, CategoryIsActive: isOptinBundles ? item.IsActive : item.CategoryIsActive, categorySequence: item.CategorySequence, categoryHelpText: item.CategoryHelpText ? item.CategoryHelpText : '' }
        }
        let convertedItem = convertItem(item)
        if (!convertedItem.commissionRestricted || convertedItem.price < walletCommission) {
            res[item.CategoryId].items.push(convertedItem)
        }
    })
    return res
}

const sortByProductType = function (list: any[], convertItem: (item: any) => any, type: string, walletCommission: number): { name: string, items: any[] }[] {
    let res: { name: string, type: string, items: any[] }[] = []
    list.forEach((item) => {
        if (!res[item.ProductType]) {
            res[item.ProductType] = { name: productByType[item.ProductType], type: type, items: [] }
        }
        let convertedItem = convertItem(item)
        if (!convertedItem.commissionRestricted || convertedItem.price < walletCommission) {
            res[item.ProductType].items.push(convertedItem)
        }
    })
    return res
}

const convertWithNoSorting = function (list: any[], convertItem: (item: any) => any): any[] {
    return list.map((item) => convertItem(item))
}

const getQtys = (list: any[]): any => {
    const InStockQtys: any = {}
    list.forEach(element => {
        InStockQtys[element.ProductId] = element.InStockCount
    });
    return InStockQtys
}

const getOffers = (itemList: any, buyProducts: any[] | undefined) => {
    let offersArray: any[] = [];
    Object.entries(itemList).map((item: [string, any]) => {
        return item[1].map((it: any) => {
            if (it.offers && it.offers.length) {
                offersArray = [...offersArray, ...it.offers]
            }
            return it
        })
    })

    buyProducts && buyProducts.map((item: any) => {
        return item.items.map((it: any) => {
            if (it.offers && it.offers.length) {
                offersArray = [...offersArray, ...it.offers]
            }
            return it
        })
    })

    return offersArray

}

const getMinQtyMap = (list: any[]) => {
    const res: any = {}
    list.forEach((val: any) => {
        const minQty = val.AXMinQty
        if (minQty !== undefined) {
            res[val.ProductId] = minQty
        }
    })
    return res
}

const initActions = function (ItemService: IItemService, storageService: IStorageService) {

    function checkForCacheAndLoad<TArguments extends any[]>(cacheName: string, timeDelay: number, force: boolean, loader: (...args: TArguments) => Observable<any>, ...args: TArguments): Observable<any> {
        const cache = storageService.get(cacheName)
        if (cache && (!JSON.parse(cache).time || moment(JSON.parse(cache).time).isAfter(moment())) && !force) {
            return of(JSON.parse(cache).data)
        }
        else {
            return loader(...args).pipe(tap(res => {
                if (res) {
                    storageService.set(cacheName, JSON.stringify({ data: res, time: timeDelay ? moment().add(timeDelay, 'minute').toISOString() : undefined }))
                }
            }))
        }
    }
    const fetchMenuInfo = (state: RootState): Observable<{ byUserAction: Map<string, MenuItem>, byMenuUrl: Map<string, MenuItem> }> => {
        if (state.items.menu && state.items.menuByUrl) {
            return of({ byUserAction: state.items.menu, byMenuUrl: state.items.menuByUrl })
        } else {
            return checkForCacheAndLoad('menuInfo', 0, false, ItemService.fetchMenuInfo).pipe(map((data: any[]) => {
                let res = new Map<string, MenuItem>()
                let res1 = new Map<string, MenuItem>()
                data.forEach((item => {
                    res.set(item.UserAction, {
                        title: item.Title,
                        level: item.Level,
                        isEnabled: item.IsEnabled,
                        UserLevel: item.UserLevel,
                        target: item.AnchorTarget,
                        UserAction: item.UserAction,
                        url: item.MenuUrl
                    })
                    res1.set(item.MenuUrl.toLowerCase(), {
                        title: item.Title,
                        level: item.Level,
                        isEnabled: item.IsEnabled,
                        UserLevel: item.UserLevel,
                        target: item.AnchorTarget,
                        UserAction: item.UserAction,
                        url: item.MenuUrl
                    })
                }))
                return { byUserAction: res, byMenuUrl: res1 }
            }))
        }
    }

    const fetchItemInfo = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (forceRefresh: boolean = false) => {
        dispatch({ type: ActionType.ItemsDataRequest })
        if (!i18n.language) return;
        forkJoin([checkForCacheAndLoad(`itemList_${i18n.language}`, 5, forceRefresh, ItemService.fetchItemInfo, i18n.language), fetchMenuInfo(getState())])
            .pipe(map((commonData: [any, { byUserAction: Map<string, { title: string, level: number, isEnabled: boolean, UserLevel: string }>, byMenuUrl: Map<string, { title: string, level: number, isEnabled: boolean }> }]) => {
                const data = commonData[0]
                const menu = commonData[1].byUserAction
                const menuByUrl = commonData[1].byMenuUrl
                let qty = undefined
                let buyProducts: any = []
                let res: any = {}
                let key: string
                let walletCommission = getState().wallet.walletData ? getState().wallet.walletData.commission : 0
                let minQtyMap: any = {}
                for (key in data) {
                    if (data[key] != null) {
                        const sortedData = data[key].sort((a: any, b: any) => {
                            return a.Sequence - b.Sequence
                        })
                        if (key === "BuyProducts") {
                            buyProducts = sortByProductType(data[key], convertItem, "BuyOnly", walletCommission)
                        } else if (key === "InStockProducts") {
                            const stockKeyArr = sortedData?.map((item: any) => { return { ...item, isInStock: true } })
                            res[key] = convertWithNoSorting(stockKeyArr, convertItem)
                            qty = getQtys(sortedData)
                        } else if (key === "OptinBundles") {
                            const stockKeyArr = sortedData?.map((item: any) => { return { ...item, isOptinBundle: true } })
                            res[key] = sortByCategory(true, stockKeyArr, convertBundleItem, "Assign", walletCommission)
                        } else if (key === "PrintEpins") {
                            res[key] = sortByCategory(false, data[key], convertItem, "Buy", walletCommission)
                        } else {
                            res[key] = sortByCategory(false, data[key], convertItem, "TopUp", walletCommission)
                        }
                        minQtyMap = { ...minQtyMap, ...getMinQtyMap(data[key]) }
                    }
                }

                const directtopup = menu.get('directtopup')
                const printepin = menu.get('dashboardprintepin')
                const buyproductsMenu = menu.get('buyproducts')
                if (!directtopup || directtopup.level < getState().user.userData.Level || !directtopup.isEnabled) {
                    if (!getState().user.userData.permissions.includes('directtopup')) {
                        delete res.OptinBundles
                    }
                    delete res.TopupProducts
                }
                if (!printepin || printepin.level < getState().user.userData.Level || !printepin.isEnabled) {
                    if (!getState().user.userData.permissions.includes('dashboardprintepin')) {
                        delete res.PrintEpins
                    }
                }
                if (!getState().user.countryConfiguration.TopUpUI.AssignBundle) {
                    delete res.OptinBundles
                }
                if (!buyproductsMenu || !buyproductsMenu.isEnabled) {
                    buyProducts.length = 0
                }

                if (getState().user.userData.permissions?.includes('digitalpin') && res.TopupProducts) {
                    res.PrintTopupProducts = res.TopupProducts.map((item: any) => ({ ...item, type: 'Buy' }))
                }

                if (res.InStockProducts && res.InStockProducts.length) {
                    if (directtopup && directtopup.isEnabled) {
                        if (!res.TopupProducts) {
                            res.TopupProducts = []
                        }
                        res.TopupProducts = [
                            {
                                name: 'In Stock Products',
                                type: "Apply",
                                isOpen: false,
                                CategoryIsActive: true,
                                items: res.InStockProducts
                            }
                        ].concat(res.TopupProducts)
                    }
                    if (getState().user.userData.permissions?.includes('digitalpin')) {
                        res.PrintTopupProducts = [
                            {
                                name: 'In Stock Products',
                                type: "BuyInStock",
                                isOpen: false,
                                CategoryIsActive: true,
                                items: res.InStockProducts.map(((item: any) => ({ ...item, productVATText: null, category: 'Topup' })))
                            }
                        ].concat(res.PrintTopupProducts || [])
                    }
                    if (printepin && printepin.isEnabled) {
                        if (!res.PrintEpins) {
                            res.PrintEpins = []
                        }
                        res.PrintEpins = [
                            {
                                name: 'In Stock Products',
                                type: "BuyInStock",
                                isOpen: false,
                                CategoryIsActive: true,
                                items: res.InStockProducts
                            }
                        ].concat(res.PrintEpins)
                    }
                }
                delete res.InStockProducts

                if (res.PrintTopupProducts) {
                    res.PrintTopupProducts.forEach((itemRow: any) => {
                        itemRow.items = itemRow.items.map(((item: any) => ({ ...item, productVATText: null, category: 'Topup' })))
                    })
                }

                const filteredItems = getFilteredItems(getState().items.filteredKeys, { itemList: res, noSubcategory: buyProducts });
                const searchedItems = getSearchedItems(getState().items.searchString, { itemList: res, noSubcategory: buyProducts });
                const offers = getOffers(res, buyProducts)

                return { res, qty, buyProducts, menu, menuByUrl, filteredItems, searchedItems, offers, minQtyMap }
            }))
            .subscribe(data => {
                dispatch({ type: ActionType.ItemsDataResponse, data: { data: data.res, noSubheader: data.buyProducts, qtys: data.qty, menu: data.menu, menuByUrl: data.menuByUrl, filteredItems: data.filteredItems, searchedItems: data.searchedItems, offers: data.offers, minQtys: data.minQtyMap } })
            })
    };

    const inFilterDialog = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.InFilterDialog });
    }

    const closeFilterDialog = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.CloseFilterDialog });
    }

    const getFilteredItems = (list: string[], items: any) => {

        if (list === undefined) return undefined
        const itemsList = items.itemList;
        const noSubcategory = items.noSubcategory;
        const filteredItems: any = { itemList: {}, noSubcategory: [] };
        list.map((it) => {
            if (it in itemsList) {
                filteredItems.itemList[it] = itemsList[it]
            } else {
                const elems = noSubcategory.filter((el: any) => el.name.toLowerCase() === it.toLowerCase())
                if (elems.length === 1) filteredItems.noSubcategory.push(elems[0])
            }
            return it
        })
        return filteredItems
    }

    const getSearchedItems = (searchedString: string, items: any) => {

        if (searchedString === undefined) return undefined;
        const itemList = items.itemList
        const noSubcategory = items.noSubcategory
        const searchedItems = { itemList: { ...itemList }, noSubcategory: [...noSubcategory] }
        Object.keys(searchedItems.itemList).map((category: any) => {
            searchedItems.itemList[category] = searchedItems.itemList[category].map((subcategory: any) => {
                const newSubcategory = { ...subcategory }
                newSubcategory.items = subcategory?.items?.filter((item: any) => {
                    return item?.name?.toLowerCase().includes(searchedString?.toLowerCase())
                })
                return newSubcategory
            })
            return null;
        })

        noSubcategory.forEach((it: any, index: number) => {
            const newNoSubItem = { ...it }
            const sItems = it.items.filter((el: any) => {
                return el.name.toLowerCase().includes(searchedString.toLowerCase())
            })
            newNoSubItem.items = sItems;
            searchedItems.noSubcategory[index] = newNoSubItem
        })

        return searchedItems

    }

    const applyFilterItems = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (list: string[]) => {
        const items = getState().items;
        const filteredItems = getFilteredItems(list, items)
        dispatch({ type: ActionType.FilterItems, data: { filteredItems: filteredItems, filteredKeys: list } });
    }



    const applySearch = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (str: string) => {
        const searchedItems = getSearchedItems(str, getState().items)
        dispatch({ type: ActionType.ApplySearch, data: { searchString: str, searchedItems: searchedItems } });
    }

    return { fetchItemInfo, applyFilterItems, inFilterDialog, closeFilterDialog, applySearch };
};

export default initActions;