import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

export interface MyCommissionSetFilters {
status?: 'All'
dateFrom?: Date | null
toDate?: Date| null
}

export interface MyCommissionState {
  fetchInProgress: boolean,
  commissionData: any,
  filters: MyCommissionSetFilters,
  commissionsummaryData: any,
}

const configureCommissionModule = (services: IServices) => {
  const actions = initActions(services.commissionService);
  const reducer = initReducer(services.commissionService, services.storageService);

  return { actions, reducer };
};

export type CommissionReducer = ReturnType<typeof initReducer>
export type CommissionActions = ReturnType<typeof initActions>

export default configureCommissionModule;