import { dispatch } from "rxjs/internal/observable/pairs";
import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";
import { IPortInResubmitService } from "src/services/PortInResubmissionService";
import { IPostPayService } from "src/services/PostPayService";
import { i18n } from "i18next";

const initActions = (PortInResubmitService: IPortInResubmitService, PostPayServices: IPostPayService) => {

    const getServiceId = (dispatch: AppDispatch, i18n: i18n) => () => {
        PostPayServices.getSessionId()
            .subscribe(SessionId => {
                dispatch({ type: ActionType.PostPayNetworkOperatorsRequest })
                PostPayServices.fetchOperators(SessionId as string, 'en-de').subscribe(res => {
                    dispatch({ type: ActionType.PostPayNetworkOperatorsResponse, data: res || [] })
                })
                dispatch({ type: ActionType.PostPayLoadUpdate, data: { SessionId } })
                dispatch({ type: ActionType.PostPayNonPortInDatesRequest })
                PostPayServices.fetchNonPortInDates(SessionId as string, 'en-de').subscribe(res => {
                    dispatch({ type: ActionType.PostPayNonPortInDatesRequest, data: res || [] })
                })
            })

    }

    const getContractDetailsAction = (dispatch: AppDispatch) => (msisdn: string, serviceId: string | null) => {
        dispatch({ type: ActionType.PortInResubmitContractDetailsRequest })
        PortInResubmitService.getContractDetails(msisdn, serviceId, 'en-de').subscribe(res => {
            dispatch({ type: ActionType.PortInResubmitContractDetailsResponse, data: res || {} })
        })
    }

    const submitResubmissionForm = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.PortInResubmitFormSubmitRequest })
        PortInResubmitService.submitResubmission(payload).subscribe(res => {
            dispatch({ type: ActionType.PortInResubmitFormSubmitResponse, data: res || {} })
        })
    }

    const clearUserDetails = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.PortInResubmitContractDetailsReset })
    }

    return {
        getServiceId,
        getContractDetailsAction,
        submitResubmissionForm,
        clearUserDetails
    }
}

export default initActions