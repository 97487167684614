import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const AlertsService = (ajax: ajaxType) => {return{
    fetchAlerts: () => {
        return ajax.get(`${config.url.API_URL}Dashboard/Alerts`).pipe(map(res => res.response))
    }
}}

export type IAlertsService = ReturnType<typeof AlertsService>

export default AlertsService