import { dispatch } from "rxjs/internal/observable/pairs";
import { AppDispatch } from "../configureStore";
import { ActionType } from "../types";
import { IEmployeeService } from "@src/services/EmployeeService";

const initActions = (EmployeeService: IEmployeeService) => {

    const getEmployees = (dispatch: AppDispatch) => (status: string) => {
        dispatch({ type: ActionType.EmployeesGetAllEmployeeRequest })
        EmployeeService.fetchEmployees(status).subscribe(res => {
            dispatch({ type: ActionType.EmployeesGetAllEmployeeResponse, data: res || [] })
        })
    }
    const getEmployeeDetail = (dispatch: AppDispatch) => (userName: string) => {
        dispatch({ type: ActionType.EmployeesGetEmployeeDetailRequest })
        EmployeeService.fetchEmployeeDetails(userName).subscribe(res => {
            dispatch({ type: ActionType.EmployeesGetEmployeeDetailResponse, data: res || [] })
        })
    }

    const changeEmployeeStatus = (dispatch: AppDispatch) => (userName: string, action: string) => {
        dispatch({ type: ActionType.EmployeeChangeStatusRequest })
        EmployeeService.changeStatusEmployee(userName, action).subscribe(res => {
            dispatch({ type: ActionType.EmployeeChangeStatusResponse, data: res || [] })
        })
    }

    const createEmployee = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.EmployeeCreateRequest })
        EmployeeService.createEmployee(payload).subscribe(res => {
            dispatch({ type: ActionType.EmployeeCreateResponse, data: res || [] })
        })
    }

    const updateEmployee = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.EmployeeUpdateRequest })
        EmployeeService.updateEmployee(payload).subscribe(res => {
            dispatch({ type: ActionType.EmployeeUpdateResponse, data: res || [] })
        })
    }

    const getPermission = (dispatch: AppDispatch) => (userName: string) => {
        dispatch({ type: ActionType.EmployeeGetPermissionRequest })
        EmployeeService.fetchPermissions(userName).subscribe(res => {
            dispatch({ type: ActionType.EmployeeGetPermissionResponse, data: res || [] })
        })
    }

    const updateEmployeePermission = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.EmployeeUpdatePermissionRequest })
        EmployeeService.updatePermissions(payload).subscribe(res => {
            dispatch({ type: ActionType.EmployeeUpdatePermissionResponse, data: res || [] })
        })
    }

    const getTerms = (dispatch: AppDispatch) => () => {
        EmployeeService.getTermsAndConditions().subscribe(res => {
            window.open(`${res}`, "_blank")
        })
    }

    const resetEmployeeStatus = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.EmployeeChangeStatusReset })
    }

    const setCreateEmployeeDetails = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.EmployeeSuccessCreateDetails, data: payload })
    }

    return {
        getEmployees,
        getEmployeeDetail,
        changeEmployeeStatus,
        createEmployee,
        updateEmployee,
        getPermission,
        updateEmployeePermission,
        getTerms,
        resetEmployeeStatus,
        setCreateEmployeeDetails
    }
}

export default initActions