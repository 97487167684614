import { IAction, ActionType } from "../types";
import { FaqState } from './index'

const initReducer = function () {
    const INITIAL_STATE: FaqState = {
        fetchInProgress: false,
        faq: [],
    }

    let reducersByActions: any = {}

    reducersByActions[ActionType.FaqRequest] = (state: FaqState) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }
    reducersByActions[ActionType.FaqResponse] = (state: FaqState, actionData: any) => {
        return {
            ...state,
            fetchInProgress: false,
            faq: actionData
        }
    }



    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };


    return reducer;
}

export default initReducer