import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const TradeOffersService = (ajax: ajaxType) => {return{
    fetchTradeOffers: () => {
        return ajax.get(`${config.url.API_URL}Offers/TradeOffer`)
            .pipe(map(res => res.response))
    }
}}

export type ITradeOffersService = ReturnType<typeof TradeOffersService>

export default TradeOffersService