import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const FaqService = (ajax: ajaxType) => {
    return {
        fetchFaq: () => {
            return ajax.get(`${config.url.API_URL}FAQ/Get`)
                .pipe(map(res => res.response));
        },
    }
}

export type IFaqService = ReturnType<typeof FaqService>

export default FaqService