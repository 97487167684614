import { ITopUpHistoryService } from "@src/services/TopUpHistoryService";
import moment from "moment";
import { IAction, ActionType } from "../types";

const initReducer = function (userService:ITopUpHistoryService) {
    const INITIAL_STATE = {
      fetchInProgress: false,
      history: (undefined as any),
      isDownloading: false,
      filters: {
        from: moment().subtract(7, 'days').toDate().toISOString(),
        to:moment().toDate().toISOString()
      }
    };

    let reducersByActions: any = {}
    reducersByActions[ActionType.TopHistoryRequest] =  (state: any, actionData: any) => {
      return {
        fetchInProgress: true,
        history: undefined,
        filters: actionData
      };
    }
    reducersByActions[ActionType.TopHistoryResponse] =  (state: any, actionData: any) => {
      return {
        fetchInProgress: false,
        history: actionData.data,
      };
    }
    reducersByActions[ActionType.TopHistoryStartDownload] = (state: any, actionData: any) => {
      return {
        ...state,
        isDownloading: true
      }
    }
    reducersByActions[ActionType.TopHistoryEndDownload] = (state: any, actionData: any) => {
      return {
        ...state,
        isDownloading: false
      }
    }
    reducersByActions[ActionType.TopHistoryClean] = (state: any, actionData: any) => {
      return INITIAL_STATE
    }

    reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
      return INITIAL_STATE
    }
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      // console.log("state: ", state)
      // console.log("action: ", action)
      return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
    };

    
    return reducer;
  };
  
  export default initReducer;