import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const EmployeeService = (ajax: ajaxType) => {
    return {
        fetchEmployees: (status: string) => {
            return ajax.get(`${config.url.API_URL}Employee/Details/${status}`)
                .pipe(map(res => res.response))
        },
        fetchEmployeeDetails: (userName: string) => {
            return ajax.get(`${config.url.API_URL}Employee/Get/${userName}`)
                .pipe(map(res => res.response))
        },
        changeStatusEmployee: (userName: string, action: string) => {
            return ajax.post(`${config.url.API_URL}Employee/${action}/${userName}`)
                .pipe(map(res => { return { status: res.status, userName } }))
        },
        createEmployee: (payload: any) => {
            return ajax.post(`${config.url.API_URL}Employee/Create`, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res.response))
        },
        updateEmployee: (payload: any) => {
            return ajax.put(`${config.url.API_URL}Employee/Update`, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res.response))
        },
        fetchPermissions: (userName: string) => {
            return ajax.get(`${config.url.API_URL}Employee/Permissions/${userName}`)
                .pipe(map(res => res.response))
        },
        updatePermissions: (payload: any) => {
            return ajax.put(`${config.url.API_URL}Employee/UpdatePermissions`, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res.response))
        },
        getTermsAndConditions: () => {
            return ajax.get(`${config.url.API_URL}Employee/TD`)
                .pipe(map(res => res.response))
        }
    }
}

export type IEmployeeService = ReturnType<typeof EmployeeService>
export default EmployeeService