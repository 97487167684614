import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureInvoiceHistoryModule = (services: IServices) => {
  const actions = initActions(services.invoiceHistoryService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type InvoiceHistoryReducer = ReturnType<typeof initReducer>
export type InvoiceHistoryActions = ReturnType<typeof initActions>

export default configureInvoiceHistoryModule;