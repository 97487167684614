import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from "../interceptor"

const DashboardTilesService = (ajax: ajaxType) => {
    return {
        fetchTilesInfo: () => {
            return ajax.get(`${config.url.API_URL}Dashboard/Tiles`).pipe(map(res => res.response))
        }
    }
}

export type IDashboardTilesService = ReturnType<typeof DashboardTilesService>

export default DashboardTilesService