import { ILocalizationService } from "@src/services/LocalizationService";
import { IStorageService } from "@src/services/StorageService";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType, LoadingStatus } from "../types";
import { i18n } from "i18next";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import moment from 'moment';

const defaultMonths = 'January,February,March,April,May,June,July,August,September,October,November,December'

const initActions = function (localizationService: ILocalizationService, i18n: any, storageService: IStorageService) {
  
  function checkForCacheAndLoad <TArguments extends any[]> (cacheName: string, loader: (...args: TArguments) => Observable<any>,  ...args: TArguments): Observable<any> {
    const cache = storageService.get(cacheName)
    if (cache) {
      return of (JSON.parse(cache))
    }
    else {
      return loader(...args).pipe(tap(res => {
        if (res) {
          storageService.set(cacheName, JSON.stringify(res))
        }
      }))
    }
  }

  const setLanguageList = (dispatch: AppDispatch) => (lngList: string[]) => {
    dispatch({
      type: ActionType.setLanguageList, 
      data: { lngList: lngList }
    })
  };

  const notifyLngChange = () => (lng: string) => {
    localizationService.notifyLngChange(lng).subscribe(()=>{
      const months = (i18n.getResource(lng, 'translations', 'months-pack') || defaultMonths).split(',')
      moment.updateLocale('en', { months })
    })
  }

  const loadLanguage = (dispatch: AppDispatch, getState: ()=>RootState, i18n: i18n) => (lng: string) => {
    const currentLngInfo = getState().localization.lngList[lng]
    if (!currentLngInfo || currentLngInfo.loading !== LoadingStatus.notLoaded) {
      return;
    }
    dispatch({
      type: ActionType.setLanguageListRequest, 
      data: { lng }
    })
    checkForCacheAndLoad(`locale_${lng}`, localizationService.getLanguage, lng)
    .subscribe(res => {
      let newRes: any = {}
      for(const key in res) {
        if(res[key] != null) {
          newRes[key] = res[key]; 
        } else {
          newRes[key] = 'null'
        }
      }
      i18n.addResourceBundle(lng,'translations', newRes, true, true )
      dispatch({
        type: ActionType.setLanguageListResponse, 
        data: { lng }
      })
    })
  }
  const cleanLanguageList = (dispatch: AppDispatch) => () => {
    dispatch({
      type: ActionType.setLanguageList, 
      data: { lngList: { 'en-GB': {name: "English", shortName: 'ENG', loading: true} } }
    })
  }

  const setLanguage = (dispatch: AppDispatch) => (lng: string) => {
    dispatch({
      type: ActionType.setLanguage, 
      data: lng
    })
  }
  
  return { loadLanguage, setLanguageList, cleanLanguageList, notifyLngChange, setLanguage }; 
};
  
  export default initActions;