import { AppDispatch, RootState } from "../configureStore"
import { ActionType } from "../types"
import { i18n } from "i18next"
import { History } from 'history'


const initActions = function (history: History) {

    const pageview = (dispatch: AppDispatch) => (pathname: string, search: string | undefined) => {
        dispatch({ type: ActionType.GAPageview, data: { pathname, search, history } })
    }

    return { pageview };
};

export default initActions;