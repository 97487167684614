import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from '../interceptor'

const ForgottenPasswordService = (ajax: ajaxType) => {return {
    updatePassword: ( data: any ) => {
        return ajax.post(`${config.url.API_URL}account/UpdatePassword`, data,
        {"Content-Type": "application/json"}).pipe(map(res => res.response))
        //return from(new Promise((res) => setTimeout(()=> res("error-code"), 1000)))
    },

    getPasswordChangeInfo: ( token:string ) => {
        return ajax.get(`${config.url.API_URL}account/passwordchange/${token}`).pipe(map(res => res.response))
    }
}}

export type IForgottenPasswordService = ReturnType<typeof ForgottenPasswordService>

export default ForgottenPasswordService