import { IAction, ActionType } from "../types";
import { KpiReportState } from './index'

const initReducer = function () {
    const INITIAL_STATE: KpiReportState = {
        mtdInProgress: false,
        mtdData: [],
        months: {},
        loading: false,
        ga: {
            inProgress: false
        },
        revenueSummary: {},
        ntopupSummary: {},
        reportConfig: {},
        pbaSummary: {},
        baSummary: {}
    }

    let reducersByActions: any = {}

    reducersByActions[ActionType.KpiReportSummaryRequest] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            mtdInProgress: true
        }
    }
    reducersByActions[ActionType.KpiReportSummaryResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            mtdData: actionData,
            mtdInProgress: false
        }
    }
    reducersByActions[ActionType.KPIRequest] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: true,
        }
    }
    reducersByActions[ActionType.GaSummaryResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: false,
            ga: {
                ...state?.ga,
                data: actionData
            }
        }
    }
    reducersByActions[ActionType.RevenueSummaryResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: false,
            revenueSummary: actionData
        }
    }
    reducersByActions[ActionType.NtopupSummaryResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: false,
            ntopupSummary: actionData
        }
    }
    reducersByActions[ActionType.PBSummaryResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: false,
            pbaSummary: actionData
        }
    }
    reducersByActions[ActionType.BASummaryResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: false,
            baSummary: actionData
        }
    }
    reducersByActions[ActionType.KpiGetMonths] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            loading: false,
            months: actionData
        }
    }
    reducersByActions[ActionType.ReportConfigResponse] = (state: KpiReportState, actionData: any) => {
        return {
            ...state,
            reportConfig: actionData
        }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };


    return reducer;
}

export default initReducer