import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureStandaloneTopUpModule = (services: IServices) => {
  const actions = initActions(services.standaloneTopUpService);
  const reducer = initReducer(services.standaloneTopUpService);

  return { actions, reducer };
};

export type StandaloneTopUpReducer = ReturnType<typeof initReducer>
export type StandaloneTopUpActions = ReturnType<typeof initActions>

export default configureStandaloneTopUpModule;