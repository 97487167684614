import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configurePrintHistoryModule = (services: IServices) => {
  const actions = initActions(services.printHistoryService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type PrintHistoryReducer = ReturnType<typeof initReducer>
export type PrintHistoryActions = ReturnType<typeof initActions>

export default configurePrintHistoryModule;