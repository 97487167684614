import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const TopUpService = (ajax: ajaxType) => {return {
    fetchMsisdnBalance: (msisdn: string, locale: string) => {
        return ajax.get(`${config.url.API_URL}Transaction/Balance/${msisdn}/${locale}`).pipe(map(res => res.response))
    },
    applyTopUp: (locale: string, body: any) => {
        return ajax.post(`${config.url.API_URL}Transaction/DirectTopup/${locale}`, 
            body,
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    },
    applyIccidTopUp: (locale: string, body: any) => {
        return ajax.post(`${config.url.API_URL}Transaction/ICCIDDirectTopup/${locale}`, 
            body,
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    },
    applyVoucher: (request: any) => {
        return ajax.post(`${config.url.API_URL}Transaction/ApplyVoucherPin`, request,
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    },
    getTransactionData: (transactionId: string, msisdn: any) => {
        return ajax.get(`${config.url.API_URL}Transaction/GetTopupStatusByMsisdn/${transactionId}/${msisdn}`, 
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    },
    getTopupStatus: (transactionId: string) => {
        return ajax.get(`${config.url.API_URL}Transaction/GetTopupStatus/${transactionId}`, 
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    },
    assignBundle: (request: any) => {
        return ajax.post(`${config.url.API_URL}Transaction/AssignBundle`, request,
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response ? res.response : null))
    },
    topUpVoucherNumber: (voucherNumber: number, msisdn: string, locale: string) => {
        return ajax.post(`${config.url.API_URL}Transaction/VoucherTopup`, 
            {
                'MSISDN': msisdn,
                'VoucherPIN': voucherNumber,
                'Locale': locale
            },
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    },
}}

export type ITopUpService = ReturnType<typeof TopUpService>

export default TopUpService