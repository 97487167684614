import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureContractStatusModule = (services: IServices) => {
    const actions = initActions(services.postPayService, services.storageService);
    const reducer = initReducer();
  
    return { actions, reducer };
  };
  
  export type ContractStatusReducer = ReturnType<typeof initReducer>
  export type ContractStatusActions = ReturnType<typeof initActions>
  
  export default configureContractStatusModule;