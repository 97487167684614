import { IStorageService } from "@src/services/StorageService";
import { IAction, ActionType, LoadingStatus } from "../types";

const compareSets = (arr1: string[], arr2: string[]): boolean => {

  if (arr1.length !== arr2.length) {
    return false
  }
  const _arr1 = arr1.concat().sort();
  const _arr2 = arr2.concat().sort();

  for (let i = 0; i < _arr1.length; i++) {
    if (_arr1[i] !== _arr2[i]) {
        return false;
     }
  }

  return true;
}

const checkLoading: (lngList: any)=>Boolean = (lngList: any) => {
  for (const lng in lngList) {
    if (lngList[lng].loading === LoadingStatus.loading) {
      return true
    }
  }
  return false
}

const initReducer = function (i18n:any, storageService: IStorageService) {

  const INITIAL_STATE = {
    lngList: ({} as any),
    lngLoading: true,
    language: undefined
  }

  let reducersByActions: any = {}
  reducersByActions[ActionType.setLanguageList] =  (state: any, actionData: any) => {
    if (compareSets(Object.keys(state.lngList), Object.keys(actionData.lngList))) {
      return state;
    }
    Object.keys(actionData.lngList).forEach((lng: string) => {
      actionData.lngList[lng].loading = LoadingStatus.notLoaded;
    });
    return {
      ...state,
      lngList: actionData.lngList,
      lngLoading: true
    };
  }
  
  reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
    return INITIAL_STATE
  }

  reducersByActions[ActionType.setLanguageListRequest] =  (state: any, actionData: any) => {
    if (state.lngList[actionData.lng] === undefined) {
      return state
    }
    const newList = { ...state.lngList }
    newList[actionData.lng].loading = LoadingStatus.loading
    return {
      ...state,
      lngList: newList,
      lngLoading: checkLoading(newList)
    };
  }

  reducersByActions[ActionType.setLanguageListResponse] =  (state: any, actionData: any) => {
    if (state.lngList[actionData.lng] === undefined) {
      return state
    }
    const newList = { ...state.lngList }
    newList[actionData.lng].loading = LoadingStatus.loaded
    return {
      ...state,
      lngList: newList,
      lngLoading: checkLoading(newList)
    };
  }

  reducersByActions[ActionType.setLanguage] =  (state: any, actionData: any) => {
    return {
      ...state,
      language: actionData
    };
  }


  
  const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
    // console.log("state: ", state)
    // console.log("action: ", action)
    return reducersByActions[action.type]!==undefined?reducersByActions[action.type](state, action.data):state;
  };

  
  return reducer;
};

export default initReducer;