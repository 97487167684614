import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

export enum NumberType {
    orderNumber,
    serialNumber,
    batchNumber
}

const PrintHistoryService = (ajax: ajaxType) => {return {
    
    fetchHistoryByDay: (range: number) => {
        return ajax.get(`${config.url.API_URL}Print/history/Day/${range}`, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    fetchHistoryByDate: (startDate: string, endDate: string) => {
        return ajax.get(`${config.url.API_URL}Print/history/Date/${startDate}/${endDate}`, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    fetchHistoryBySerial: (serialnumber: string) => {
        return ajax.get(`${config.url.API_URL}Print/history/SerialNumber/${serialnumber}`, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    fetchPrintOutInfo: (type: NumberType, number: string, isPrint: boolean = true, Locale: string = 'en-GB') => {
        return ajax.post(`${config.url.API_URL}Print/Popup`,
            {
                IsOrderNumberPrint: (type === NumberType.orderNumber),
                IsSerialNumberPrint: (type === NumberType.serialNumber),
                IsBatchNumberPrint: (type === NumberType.batchNumber),
                Number: number,
                isPrint: isPrint,
                Locale
            }, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    activationStatus: (request: any) => {
        return ajax.post(
            `${config.url.API_URL}Print/history/Activation/status`,
            request,
            {"Content-Type": "application/json"}).pipe(map(res => res.response))
    },
    updateOrderStatus: (orderNumber: string) => {
        return ajax.put(
            `${config.url.API_URL}Print/Update/status/orderNumber?orderNumber=${orderNumber}`,
            {},
            {"Content-Type": "application/json"}).pipe(map(res => res.response))
    }
}}

export type IPrintHistoryService = ReturnType<typeof PrintHistoryService>

export default PrintHistoryService