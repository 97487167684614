import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { IPostPayService } from "@src/services/PostPayService";
import { ContractStatusFilter } from "./reducer";
import { IStorageService } from "@src/services/StorageService";
import { PostpaySteps } from "../postPay";

const initActions = (PostPayServices: IPostPayService, storageService: IStorageService) => {

    const updateFilters = (dispatch: AppDispatch) => (filters: ContractStatusFilter) => {
        dispatch({ type: ActionType.ContractStatusUpdateFilter, data: filters })
    }

    const getContractStatusList = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { fromDate, toDate } = getState().contractStatus.filters
        dispatch({ type: ActionType.ContractStatusGetListRequest })
        PostPayServices.fetchContractStatusList(fromDate as string, toDate as string)
            .subscribe(res => {
                dispatch({ type: ActionType.ContractStatusGetListResponse, data: res })
            })
    }

    const cleanContractStatusData = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.ContractStatusCleanData })
    }

    const sendContractLinkSMS = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusSendSMSRequest })
        PostPayServices.smsContractLink(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.ContractStatusSendSMSResponse, data: { isSuccess: true } })
            } else {
                dispatch({ type: ActionType.ContractStatusSendSMSResponse, data: { isSuccess: false } })
            }
        })
    }

    const sendContractLinkEmail = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusSendEmailRequest })
        PostPayServices.emailContractSummary(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.ContractStatusSendEmailResponse, data: { isSuccess: true } })
            } else {
                dispatch({ type: ActionType.ContractStatusSendEmailResponse, data: { isSuccess: false } })
            }
        })
    }

    const resendContractMail = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusResendEmailRequest })
        PostPayServices.resendEmail(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.ContractStatusResendEmailResponse, data: { isSuccess: true } })
            } else {
                dispatch({ type: ActionType.ContractStatusResendEmailResponse, data: { isSuccess: false } })
            }
        })
    }

    const resignOnscreen = (dispatch: AppDispatch, getState: () => RootState) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusResignOnscreenRequest })
        PostPayServices.resignOnscreen(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                storageService.set('postpayportinstate', JSON.stringify({
                    postPayload: { ...getState().postPay.postPayload, EnvelopeId: payload.EnvelopeId },
                    step: PostpaySteps.MigrationStatus,
                    CartTotal: getState().postPay.CartTotal,
                    productType: getState().postPay.productType,
                }))
                window.location.replace(res.RedirectionURL)
            } else {
                dispatch({ type: ActionType.ContractStatusResignOnscreenResponse, data: { isSuccess: false } })
            }
        })
    }

    const retryContractStatus = (dispatch: AppDispatch, getState: () => RootState) => (envelopId: string) => {
        dispatch({ type: ActionType.ContractStatusRetryRequest })
        PostPayServices.retryContract(envelopId).subscribe(res => {
            dispatch({ type: ActionType.ContractStatusRetryResponse, data: { isSuccess: res} })
            res && setTimeout(() => getContractStatusList(dispatch, getState)(), 1500)
        })
    }

    return {
        updateFilters,
        getContractStatusList,
        cleanContractStatusData,
        sendContractLinkSMS,
        sendContractLinkEmail,
        resendContractMail,
        resignOnscreen,
        retryContractStatus
    }
}

export default initActions