import { AppDispatch } from "../configureStore"
import { IPrepaidPortalService } from "src/services/PrepaidPortal"
import { IPostPayService } from "src/services/PostPayService";
import { ActionType } from "../types"
const initActions = (PrepaidPortalService: IPrepaidPortalService, PostPayServices: IPostPayService) => {

    const getServiceId = (dispatch: AppDispatch) => () => {
        PostPayServices.getSessionId()
            .subscribe((SessionId: any) => {
                dispatch({ type: ActionType.PostPayNetworkOperatorsRequest })
                PostPayServices.fetchOperators(SessionId as string, 'en-de').subscribe((res: any) => {
                    dispatch({ type: ActionType.PostPayNetworkOperatorsResponse, data: res || [] })
                })
                dispatch({ type: ActionType.PostPayLoadUpdate, data: { SessionId } })
            })
    }

    const generalGetRequest = (dispatch: AppDispatch) => (url: string) => {
        return PrepaidPortalService.generalGetRequest(url)
    }

    const generalPostRequest = (dispatch: AppDispatch) => (url: string, payload: any) => {
        return PrepaidPortalService.generalPostRequest(url, payload)
    }

    const generalPutRequest = (dispatch: AppDispatch) => (url: string, payload: any) => {
        return PrepaidPortalService.generalPutRequest(url, payload)
    }

    return {
        generalGetRequest,
        generalPostRequest,
        generalPutRequest,
        getServiceId
    }
}

export default initActions