import { ITopUpHistoryService } from "@src/services/TopUpHistoryService";
import { map } from "rxjs/operators";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "../types";
import { i18n } from "i18next";

const initActions = function (ItemService: ITopUpHistoryService) {
    
    const fetchHistory = (dispatch: AppDispatch) => (filters: any) => {
        dispatch({type: ActionType.TopHistoryRequest, data: filters})
        ItemService.fetchHistory(filters)
            .pipe(map(data => {
                console.log("TopupHistorymap",data);
                return data
            }))
            .subscribe(data => {
                console.log("TopupHistory",data);
                dispatch({type: ActionType.TopHistoryResponse, data: {data}})
            })
    };

    const downloadHistory = (dispatch: AppDispatch, getState: ()=>RootState, i18n: i18n) => (filters: any) => {
        dispatch({type: ActionType.TopHistoryStartDownload})
        ItemService.downloadHistory(filters, i18n.language)
            .pipe(map(data => {
                const typeFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                window.location.href = `data:${typeFile};base64,${data}`;
            }))
            .subscribe(() => {
                dispatch({type: ActionType.TopHistoryEndDownload})
            })
    };

    const cleanTopUpHistory = (dispatch: AppDispatch, getState: ()=>RootState, i18n: i18n) => () => {
        dispatch({type: ActionType.TopHistoryClean})
    };
    
    return { fetchHistory, downloadHistory, cleanTopUpHistory }; 
  };
  
  export default initActions;