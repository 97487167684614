import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

export enum SimProductType {
  fmvno="FMVNO",
  lmvno="LMVNO"
}

export enum PortInTypes {
  newLebara = 'NewLebara',
  existingLebara = 'ExistingLebara',
  nonLebara = 'NonLebara',
  retention = 'Retention'
}

export enum SignatureTypes {
  email = 'emailSign',
  onscreen = 'sign'
}

export enum PostpaySteps {
  Home = 1,
  Documents,
  CustProfile,
  Payments,
  SimDetails,
  Consent,
  MigrationStatus
}

export interface IPostPayConfig {
  CreditCheckEnabled: boolean
  ActivationFee: number
  IsCustomerProfileEnabled: boolean
  MsisdnLength: number
  IsPromotionApplicable: boolean
  IsActivateProfileEnabled: boolean
  IsActivateProductEnabled: boolean
  IBANLength: number
}

export interface PostPayload {
  AxCode: string | null,
  CountryCode: string | null,
  ClientIP: string | null,
  CompanyId: string | null,
  PortInType: PortInTypes | null,
  CardHolderName: string | null,
  Product: any,
  FirstName: string | null,
  LastName: string | null,
  SessionId: string | null,
  DOB: string | null,
  Email: string | null,
  Title: string | null,
  PostCode: string | null,
  HouseNo: string | null,
  IsOnContract: boolean,
  Locale: string | null,
  CurrencyCode: string | null,
  City: string | null,
  AddressCountry: string | null,
  StreetName: string | null,
  IBAN: string | null,
  NewMSISDN: string | null,
  NonLebaraNumber: string | null,
  CrmId: string | null,
  MSISDN: string | null,
  ICCID: string | null,
  ActivationFee: number | null,
  Operator: string | null,
  ContractUrl: string | null,
  ActivationType: string | null,
  ActivationDate: string | null,
  IsLebaraSubPreference: boolean,
  IsAffiliatePrefernce: boolean,
  ActivateProfileStatus: boolean,
  CreateCustomerStatus: boolean,
  AddSubscriptionStatus: boolean,
  SubmitOrderStatus: boolean,
  Status: string | null,
  ErrorDescription: string | null,
  OrderNumber: string | null,
  TransactionId: string | null,
  UpdateCustomerStatus: boolean,
  PUKCode: string | null,
  PostPayType: string | null,
  FreeMSISDN: string | null,
  Boltons: any[],
  SignatureType: SignatureTypes | null,
  EnvelopeId: string | null,
  EnvelopeStatus: string | null,
  DisplayName: string | null,
  SignerClientId: string | null,
  IsPortInSuccess: false,
  CreatedDate: string | null,
  IsEnvelopePurged: boolean,
  DocumentName: string | null,
  epcPromotionId: undefined | string
}
interface retentionStatus {
  loading: boolean,
  status:  boolean | null,
  message: string,
  retentionEligibilityResponse: object | null,
}
export interface PostPayState {
  productsFetchInProgress: boolean
  boltonsFetchInProgress: boolean
  isProfileCheckInProgress: boolean
  isSubmittingPostPay: string | null
  isDigitalSigining: boolean
  isCheckingEnvelopeStatus: boolean
  isFetchingNetworkOperators: boolean
  isFetchingNonPortInDates: boolean
  isCapturePortInSuccess: boolean
  envelopeStatusChecked: boolean
  isValidatingMsisdnCustomer: boolean
  isCustomerProfileValid: boolean
  isGeneratingOtag: boolean
  isOTAGGenerateFailed: boolean
  isFetchingPromotions: boolean
  isPromotionApplicable: boolean
  profileCheckFailMessage: string
  numberDetailsErrorMessage: string
  consentError: string
  envelopeStatusError: string
  msisdnValidationError: string
  assignedOTAG: string
  // capturePortInError: string
  products: any
  boltons: any
  networkOperators: any[],
  nonPortInDates: any[],
  promotionsList: any[],
  documentList: any
  postPayload: PostPayload
  step: PostpaySteps
  CartTotal: number
  cartTotalReqInProgress: boolean
  productType: SimProductType | undefined
  contractUrl: string | undefined
  discountedActivationFee: number | undefined
  activationFee: number | undefined
  appliedPromotion: any
  retentionNumber: number | string | undefined
  retentionStatus: retentionStatus
  postpayConfig: IPostPayConfig | null
}

const configurePostPayModule = (services: IServices) => {
  const actions = initActions(services.postPayService, services.storageService);
  const reducer = initReducer(services.storageService);

  return { actions, reducer };
};

export type PostPayReducer = ReturnType<typeof initReducer>
export type PostPayActions = ReturnType<typeof initActions>

export default configurePostPayModule;