import { AppDispatch, RootState } from "../configureStore";
import { map } from "rxjs/internal/operators/map";
import { i18n } from "i18next";
import { ActionType } from "../types";
import { IInvoiceHistoryService } from 'src/services/InvoiceHistoryService'
import { InvoiceHistoryFilter } from './reducer'
import moment from 'moment'

const initActions = (invoiceHistoryService: IInvoiceHistoryService) => {
    const setInvoiceHistoryFilter = (dispatch: AppDispatch) => (filters: InvoiceHistoryFilter) => {
        dispatch({ type: ActionType.InvoiceHistoryUpdateFilter, data: filters })
    }

    const fetchInvoiceHistory = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.InvoiceHistoryRequest })
        const { filters } = getState().invoiceHistory
        invoiceHistoryService.fetchInvoices({
            ...filters,
            fromDate: moment(filters.fromDate).format('MM-YYYY'),
            toDate: moment(filters.toDate).format('MM-YYYY')
        })
            .subscribe(res => {
                console.log(res)
                dispatch({ type: ActionType.InvoiceHistoryResponse, data: res })
            })
    }

    const cleanInvoiceHistory = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanInvoiceHistory })
    }

    return {
        setInvoiceHistoryFilter, fetchInvoiceHistory, cleanInvoiceHistory
    }
}

export default initActions