import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from '../interceptor'

const LocalizationService = (ajax: ajaxType) => {return {

    getLanguage: (lngCode: string) => {
        return ajax.get(
            `${config.url.API_URL}utility/localization/${lngCode}`, 
            {'Content-Type':'application/json'}
        ).pipe(map(res => res.response))
    },

    notifyLngChange: (lngCode: string) => {
        return ajax.post(
            `${config.url.API_URL.substring(0, config.url.API_URL.length-4)}old/Account/SetLanguage?locale=${lngCode.toLowerCase()}`, 
            {'Content-Type':'application/json'}
        ).pipe(map(res => res.response))
    }
}}

export type ILocalizationService = ReturnType<typeof LocalizationService>

export default LocalizationService